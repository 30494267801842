export const JSONtemplate = {
	personnel: {
		employee_compensation: {
			compensation_split: {
				full_time: {
					spend: 164000000,
					percentage_infrastructure: "0.6",
					percentage_fixed: "0.8",
				},
				part_time: {
					spend: 10000,
					percentage_infrastructure: "0.6",
					percentage_fixed: "0.1",
				},
				overtime: {
					spend: 40000,
					percentage_infrastructure: "0.6",
					percentage_fixed: "0.0",
				},
				bonus: {
					spend: 3500000,
					percentage_infrastructure: "0.6",
					percentage_fixed: "0.1",
				}
			},
			compensation_ranges: {
				// this is all just tech
				less_than_100k: {
					headcount: 50,
					average_compensation: 76000,
				},
				between_100k_and_200k: {
					headcount: 100,
					average_compensation: 175000,
				},
				between_200k_and_300k: {
					headcount: 200,
					average_compensation: 276000,
				},
				between_300k_and_400k: {
					headcount:100,
					average_compensation: 375000,
				},
				greater_than_or_equal_to_400k: {
					headcount: 50,
					average_compensation: 1000000,
				},
			},
			management_compensation: 40000000,
		},
		employee_benefits: {
			medical: {
				spend: 33900000,
				percentage_fixed: "0.5",
			},
			dental: {
				spend: 4000000,
				percentage_fixed: "0.5",
			},
			vision: {
				spend: 4000000,
				percentage_fixed: "0.5",
			},
			other: {
				spend: 0,
				percentage_fixed: "0.4100",
			},
		},
		employee_headcount: {
			headcount_total: 1000,	// firm headcount total (including tech)
			headcount_tech_developers: 200,
			headcount_tech_infrastructure: 300,
			headcount_management: 100,	// Also included in hc total, maybe split to tech mgmt hc, non-tech mgmt hc
										// keep split out of headcount_tech_total
			total_tech_developer_personnel_costs: 125730000,
			total_tech_infrastructure_personnel_costs: 83820000,
		},
		consultant_headcount: {
			// Make sure we have consultant total
			onshore_consultants: {
				headcount_total: 50,
				total_cost: 8250000,

				// offshore_consultants
				// headcount_tech_total: 50,
				// total_tech_developer_personnel_costs: 8250000,
				// headcount_tech_developers: 50,
				// headcount_total: 5,
				// headcount_management: 5,
				// total_tech_infrastructure_personnel_costs: 0,
				// headcount_tech_infrastructure: 0,
			},
			offshore_consultants: {
				headcount_total: 26,
				total_cost: 858000,

				// % onshore to offshore spend, % onshore offshore hc, averages
			}
		},
		miscellaneous: {
			annual_attrition_rate_voluntary: "0.1800",
			annual_attrition_rate_involuntary: "0.1000",
			employee_tech_percent_engineers: "0.60",
		},
	},
	nonpersonnel: {
		// Should all just be infra
		equipment_rental_and_leases: {
			desktops_and_laptops: {
				spend: 1000000,
				//percentage_infrastructure: "0.6",		// delete, assumed infr
				percentage_fixed: "0.5",
			},
			direct_access_storage_devices: {
				spend: 3400000,
				//percentage_infrastructure: "0.2000",		// delete, assumed infr
				percentage_fixed: "0.8000",
			},
			storage: {
				spend: 2000000,
				//percentage_infrastructure: "0.2000",		// delete, assumed infr
				percentage_fixed: "0.8000",
			},
			network: {
				spend: 0,
				//percentage_infrastructure: "0.5300",		// delete, assumed infr
				percentage_fixed: "0.4700",
			},
			mainframe: {
				spend: 5000000,
				//percentage_infrastructure: "0.1300",		// delete, assumed infr
				percentage_fixed: "0.8000",
			},
			telecom: {
				spend: "60",
				//percentage_infrastructure: "0.6300",		// delete, assumed infr
				percentage_fixed: "0.6100",
			},
		},
		// Should all just be infra
		equipment_maintenance: {
			desktops_and_laptops: {
				spend: 900000,
				//percentage_infrastructure: "0.6",	// delete, assumed infr
				percentage_fixed: "0.5000",
			},
			direct_access_storage_devices: {
				spend: 2500000,
				//percentage_infrastructure: "0.6",	// delete, assumed infr
				percentage_fixed: "0.8000",
			},
			storage: {
				spend: 1000000,
				//percentage_infrastructure: "0.6",	// delete, assumed infr
				percentage_fixed: "0.8000",
			},
			network: {
				spend: 0,
				//percentage_infrastructure: "0.6",	// delete, assumed infr
				percentage_fixed: "0.4700",
			},
			mainframe: {
				spend: 3200000,
				//percentage_infrastructure: "0.6",	// delete, assumed infr
				percentage_fixed: "0.8",
			},
			telecom: {
				spend: "0",
				//percentage_infrastructure: "0.6",	// delete, assumed infr
				percentage_fixed: "0.6100",
			},
		},
		software_expenses: {
			spend: 1600000,
			//percentage_infrastructure: "0.6", // delete, assumed dev
			percentage_fixed: "0.75",
		},
		software_maintenance: {
			spend: 6000000,
			//percentage_infrastructure: "0.6",	// delete, assumed infr
			percentage_fixed: "0.5",
		},
		telecom: {
			network: "6417000",
			calling_hardware: "2500000",
			conference_calling: "2500000",
			percentage_fixed: "0.7",
		},
		facilities_occupancy: {
			building_services: 5000000,
			facilities_equipment_rental_depreciation: 5000000,
			utilities: 5000000,
			average_square_foot_offshore: 200000,
			average_square_foot_onshore: 200000,
			onshore_rent: 38669000,	// should include building services and utilities
			offshore_rent: 7000000,	// should include building services and utilities	
				// offshore_rent + onshore_rent should be around 45mil, deal with this later
			percentage_infrastructure: "0.6",
			percentage_fixed: "0.8000",
		},
		professional_services: {
			vendor_costs_hw: 11000000,
			vendor_costs_sw: 11000000,
			vendor_costs_other: 6000000,
			vendor_count_hw: 45,
			vendor_count_sw: 45,
			vendor_count_other: 10,
			average_daily_onshore_consultant_cost: 750,
			average_daily_offshore_consultant_cost: 150,
			subcontractor: 1000000,
			ratio_onshore_offshore: "1",
			percentage_fixed: "0.2000",
			percentage_infrastructure: "0.6",
		},
		travel_and_entertainment: {
			spend: 7600000,
			//percentage_infrastructure: ".6",	// delete, assumed infr
			percentage_fixed: "0.0000",
		},
		legal: {
			spend: 750000,
			//percentage_infrastructure: "0.6", // delete, assumed infr
			percentage_fixed: "1",
		},
		insurance: {
			spend: 1500000,
			//percentage_infrastructure: "0.6",	 // delete, assumed infr
			percentage_fixed: "1",
		},
		miscellaneous: {
			software_amortization: 26640000,
			equipment_depreciation: 15200000,
		},
		uncategorized_expenses: {
			spend: 2000000,
			//percentage_infrastructure: "0.6", // delete, assumed infr
			percentage_fixed: "0.500",
		},
	},
};
