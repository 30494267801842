import React, { useRef, useEffect, useState } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";
import jwtDecode from "jwt-decode";
import AuthRoute from "./utils/AuthRoute";
import { ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import generalTheme from "./utils/theme";
import { useAuth0 } from "@auth0/auth0-react";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Pages
import Home from "./Pages/HomePage/Home";
import Dashboard from "./Pages/Dashboard/Dashboard";
import FormPage from "./Pages/FormPage/FormPage";
import FAQ from "./Pages/FAQ/FAQ";
import Comparison from "./Pages/Comparison/Comparison";
import Admin from "./Pages/Admin/Admin";
import Registration from "./Pages/Registration/Registration";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

// Elite configuration management system
//axios.defaults.baseURL = "http://localhost/api";	// Dev
axios.defaults.baseURL = "https://techfiniq.com/api";	// Prod

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

const App = () => {
	const childRef = useRef();
	let location = useLocation();

	const { isAuthenticated: authenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
	const [userToken, setUserToken] = useState(undefined)
	
	
	useEffect(() => {
		if (authenticated) {
			getAccessTokenSilently({
				scope: "read:current_user",
				email: user.email
			}).then(res => {
				setUserToken(res)

				const token = jwtDecode(res)
				const userRoles = token['https://techfiniq.com//roles'] ?? []
				const isAdmin = userRoles.includes('Admin')
				localStorage.setItem("isAdmin", isAdmin);
			}).catch(e => {
				console.error(`Got auth error `, e)
			})
		}
	}, [getAccessTokenSilently, user?.sub, authenticated])

	if (userToken) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
	}

	useEffect(() => {
		const page = location.pathname;
		document.body.classList.add("is-loaded");
		childRef.current.init();
		trackPage(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<ThemeProvider theme={generalTheme}>
			<ScrollReveal
				ref={childRef}
				children={() => (
					<Switch>
						<AppRoute exact path="/" component={Home} layout={LayoutDefault} authenticated={authenticated} />
						<AppRoute exact path="/register" component={Registration} />
						<AppRoute exact path="/faq" component={FAQ} layout={LayoutDefault} authenticated={authenticated} />
						<AuthRoute exact path="/admin" component={Admin} authenticated={authenticated || isLoading !== false} />
						<AuthRoute exact path="/datasets" component={Dashboard} authenticated={authenticated || isLoading !== false} />
						<AuthRoute exact path="/datasets/comparison" component={Comparison} authenticated={authenticated || isLoading !== false} />
						<AuthRoute exact path="/datasets/:dataset" component={Dashboard} authenticated={authenticated || isLoading !== false} />
						<AuthRoute exact path="/form" component={FormPage} authenticated={authenticated || isLoading !== false} />
					</Switch>
				)}
			/>
		</ThemeProvider>
	);
};

export default App;
