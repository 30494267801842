import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import generalTheme from '../../utils/theme';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ref,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Leadership',
    paragraph: 'With over 60 years of combined experience across multiple industry sectors and various size organizations, we enable today’s technology leaders to understand financial IT information and drivers of their cost base. Our approach generates valuable insight into investment inefficiencies and model scenarios for optimal investment impact.'
  };

  const mannyTestimonial = {
    name: 'Manny Malkan',
    body: (
      <>
        <p className="text-sm mb-0" style={{paddingTop:"none"}}>
          Manny is a C-suite executive and has over 30 years of experience driving organizational strategy. 
          He has held various leadership roles in global multinational organizations, private equity and smaller enterprises across 
          multiple industries. He has also served as a board member. Functional expertise includes M&A, business transformation, development 
          of go to market strategy, Operations and Finance.
        </p>
        <p className="text-sm mb-0" style={{paddingTop:"8px"}}>
          Manny has worked extensively with CIO’s and technology teams in developing and implementing global strategic initiatives for various organizations 
          including Simon & Schuster, CBS, Siemens Business Services, Lehman Brothers and Barclays Capital. 
        </p>  
        <p className="text-sm mb-0" style={{paddingTop:"8px"}}>
          Manny's most recent role was as President and CFO/COO of Ness Digital Engineering Inc. where he led the organization to consistent profitable annual growth.
        </p>  
      </>
    )
  };
  const edTestimonial = {
    name: 'Ed Corneiro',
    body: (
      <>
        <p className="text-sm mb-0" style={{paddingTop:"none"}}>
          Ed has 30+ years of Technology Finance experience having served as Global CFO of Technology for 
          firms such as Accenture, Merrill Lynch, Credit Suisse, UBS and Morgan Stanley. He has deep expertise 
          in Technology Finance where he has partnered with Global CIO’s to implement strategic initiatives, provide 
          insightful analytical finance guidance on trends, lowered expenses and developed world class finance teams.
        </p>
        <p className="text-sm mb-0" style={{paddingTop:"8px"}}>
          In addition, Ed has held officer level positions leading Costing & Pricing at First Data and was the CFO of 
          TDWaterhouse where he successfully lead the due diligence and sale to Ameritrade.
        </p>
  
        <p className="text-sm mb-0" style={{paddingTop:"8px"}}>
          Most recently Ed was President of Accent, a Division of Intrado owned by Private Equity firm Apollo Global Management,
          where he developed and implemented firmwide strategy, operations, due diligence, sale and post sale integration.
        </p>
      </>
    )
  };
  
  const mannysMonth = (new Date().getMonth())%2 === 1
  const testimonials = [mannyTestimonial, edTestimonial]
  const [firstTestimonial, secondTestimonial] = mannysMonth ? testimonials : testimonials.reverse()

  return (
    <section
      {...props}
      className={outerClasses}
      ref={ref}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner" >
                <div className="testimonial-item-content" >
                  {firstTestimonial.body}
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">{firstTestimonial.name}</span>
                  <span className="text-color-low"> - </span>
                  <span className="testimonial-item-link">
                    <a href="#0" style={{ color: generalTheme.palette.common.red }}>Co-Founder and Co-President</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  {secondTestimonial.body}
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">{secondTestimonial.name}</span>
                  <span className="text-color-low"> - </span>
                  <span className="testimonial-item-link">
                      <a href="#0" style={{ color: generalTheme.palette.common.red }}>Co-Founder and Co-President</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;