import React, { useState, useEffect, useRef } from "react";
// import sections
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Hero from "../../components/sections/Hero";
import FeaturesTiles from "../../components/sections/FeaturesTiles";
import FeaturesSplit from "../../components/sections/FeaturesSplit";
import Testimonial from "../../components/sections/Testimonial";
import Cta from "../../components/sections/Cta";
import Typography from "@material-ui/core/Typography";
import "./Home.css";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Modal from "@material-ui/core/Modal";
import generalTheme from "../../utils/theme";
import { TextField } from "@material-ui/core";
import { sendEmail, testRequest, register, loginFunc, testAuth } from "../../axios/backend";
import axios from "axios";
import Whitepaper from "../../components/sections/Whitepaper";

const navSteps = ["Our Company", "Technology Insights", "Our Solution", "Features", "Our Leadership", "Whitepaper"];
const navContent = ["Insights Content", "Section 2 Content", "Industry Insights Content", "Our Solution Content", "Our Leadership Content"];

const Home = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [isMobile, setIsMobile] = useState(false);

	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];

	const trackScrolling = () => {
		let i;
		let currStep = 0;
		for (i = 0; i < navSteps.length; i++) {
			let wrappedElement = document.getElementById(navSteps[i]);
			if (topReached(wrappedElement)) {
				currStep = i;
			}
		}
		setActiveStep(currStep);
	};

	const resize = () => {
		if (window.innerWidth <= 980) setIsMobile(true);
		else setIsMobile(false);
	};

	useEffect(() => {
		resize();
		document.addEventListener("scroll", trackScrolling);
		window.addEventListener("resize", resize);
		return () => {
			document.removeEventListener("scroll", trackScrolling);
			window.removeEventListener("resize", resize);
		};
	}, []);

	const topReached = (element) => {
		return element.getBoundingClientRect().top <= 100;
	};

	return (
		<div>
			<div
				className="home-wrapper flex-row"
				style={{ position: "fixed", fontSize: "2vh", color: "white", alignItems: "center", height: "100vh", zIndex: 10 }}
			>
				{false && !isMobile && (
					<Stepper activeStep={6} orientation="vertical">
						{navSteps.map((label, index) => (
							<Step key={label} style={{ cursor: "pointer" }}>
								<StepLabel
									style={{
										padding: ".75vh",
										borderRadius: 5,
										opacity: 0.9,
									}}
									className={`${index === activeStep ? "active-step" : ""}`}
									StepIconComponent={index === activeStep ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon}
									onClick={() => {
										let element = document.getElementById(navSteps[index]);
										window.scrollBy({
											top: element.getBoundingClientRect().top - (index === 0 ? +500 : 0),
											behavior: "smooth",
										});
									}}
								>
									{label}
								</StepLabel>
								{/*<StepContent>
								{navContent[index]}
							</StepContent>*/}
							</Step>
						))}
					</Stepper>
				)}
			</div>
			<Hero className="illustration-section-01" />
			<FeaturesTiles id={navSteps[0]} section="companySummary" />
			<FeaturesTiles id={navSteps[1]} section="techInsight" />
			<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" id={navSteps[2]} />
			<FeaturesTiles id={navSteps[3]} section="aboutUs" />
			<Testimonial topDivider id={navSteps[4]} />
			<Whitepaper topDivider id={navSteps[5]} />
		</div>
	);
};

export default Home;
