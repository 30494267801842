import React, { Fragment } from "react";
import * as OP from "../../../utils/Templates/OperationalMetricsTemplate";
import TextField from "@material-ui/core/TextField";
import {
	set,
	initializeFormObj,
	get,
	capitalize,
	objToTree,
	prettifyInputName,
	getTypeForInputName,
	DollarFormatCustom,
	RatioFormatCustom,
	IntFormatCustom,
	strip,
} from "../../../utils/Functions";
import Typography from "@material-ui/core/Typography";

import Form from "./Form";
import generalTheme from "../../../utils/theme";

const themeVariant = "dark";
const theme = generalTheme.palette[themeVariant];

class OperationalMetrics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: [],
			collapses: [true, false, false],
			expanded: false,
			content: "",
			tree: objToTree(OP.JSONtemplate, "operational_metrics", null),
			autofill: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.generateFormContent = this.generateFormContent.bind(this);
		this.storeFormValues = this.storeFormValues.bind(this);
		this.renderTextField = this.renderTextField.bind(this);
	}

	renderTextField = (current) => {
		let inputProps = {};
		let type = getTypeForInputName(current.fullPath);

		if (type === "dollars") {
			inputProps["inputComponent"] = DollarFormatCustom;
		} else if (type === "ratio") {
			inputProps["inputComponent"] = RatioFormatCustom;
		} else {
			inputProps["inputComponent"] = IntFormatCustom;
		}
		return (
			<TextField
				InputProps={inputProps}
				variant="outlined"
				inputRef={(input) => (this[current.fullPath] = input)}
				label={prettifyInputName(current.path)}
				style={{ margin: "1vh" }}
				error={current.errors > 0}
				helperText={current.errors > 0 ? "Cannot be blank." : ""}
				onKeyDown={(e) => {
					if (e.keyCode === 9 && current.fullPath === "operational_metrics.disaster_recovery.recovery_point_objective") e.preventDefault();
				}}
			/>
		);
	};

	handleChange = (panel) => (event, isExpanded) => {
		this.setState({ expanded: isExpanded ? panel : false });
	};

	storeFormValues = (obj, tree) => {
		if (!tree.children) {
			if (this.state.autofill) {
				set(obj, tree.fullPath, tree.autofillVal);
			} else {
				set(obj, tree.fullPath, this[tree.fullPath].value);
			}
			return;
		}

		tree.children.map((child) => {
			this.storeFormValues(obj, child);
		});
	};

	handleSubmit = () => {
		let formObj = {};
		this.calculateErrors(this.state.tree);
		if (this.state.tree.errors > 0 && !this.state.autofill) return;

		this.storeFormValues(formObj, this.state.tree);

		setTimeout(() => {
			console.log(formObj);
			this.props.submitFormDataToParent(formObj);
		}, 100);
	};

	calculateErrors = (tree) => {
		if (!tree.children) {
			tree.errors = this[tree.fullPath].value === "" ? 1 : 0;
			return this[tree.fullPath].value === "" ? 1 : 0;
		}

		let errorSum = 0;

		tree.children.map((children) => {
			errorSum += this.calculateErrors(children);
		});
		tree.errors = errorSum;
		this.setState({ tree: tree });
		return errorSum;
	};

	calculateCircleColor = (errors) => {
		if (errors === -1) return theme.primaryDark;
		else if (errors === 0) return "green";
		else return "red";
	};

	generateFormContent = (tree) => {
		console.log(tree);

		return tree.children.map((depthOneChild) => (
			<div style={{ color: theme.contrastText }}>
				<Typography
					className="flex-row"
					style={{ alignItems: "center", fontWeight: "bold", fontSize: "1.75vh" }}
					ref={(input) => (this[depthOneChild.fullPath] = input)}
				>
					<div
						className="filled-circle"
						style={{
							backgroundColor: this.calculateCircleColor(depthOneChild.errors),
							border: `1px solid ${this.calculateCircleColor(depthOneChild.errors)}`,
						}}
					/>
					{prettifyInputName(depthOneChild.path)}
				</Typography>
				<div className="form-content-parent" style={{ backgroundColor: theme.primary }}>
					{depthOneChild.children.map((depthTwoChild, index) =>
						depthTwoChild.children ? (
							<div className="flex-column" style={{ flex: 1 }}>
								<Typography
									ref={(input) => (this[depthTwoChild.fullPath] = input)}
									className="flex-row"
									style={{
										alignItems: "center",
										fontWeight: "bold",
										fontSize: "1.5vh",
										marginTop: "1vh",
										marginLeft: "1vw",
									}}
								>
									<div className="empty-circle" />
									<div
										className="filled-circle"
										style={{
											backgroundColor: this.calculateCircleColor(depthTwoChild.errors),
											border: `1px solid ${this.calculateCircleColor(depthTwoChild.errors)}`,
										}}
									/>
									{prettifyInputName(depthTwoChild.path)}
								</Typography>
								<div
									style={{
										margin: "2vh",
									}}
									className="flex-row"
								>
									{depthTwoChild.children.map((depthThreeChild) => this.renderTextField(depthThreeChild))}
								</div>
							</div>
						) : (
							index % 2 === 0 && (
								<div className="flex-column" style={{ flex: 1 }}>
									{this.renderTextField(depthTwoChild)}
									{index + 1 < depthOneChild.children.length && this.renderTextField(depthOneChild.children[index + 1])}
								</div>
							)
						)
					)}
				</div>
			</div>
		));
	};

	render() {
		return (
			<Form
				tree={this.state.tree}
				defaultExpanded={["operational_metrics"]}
				handleSubmit={this.handleSubmit}
				handleBack={this.props.handleBack}
				generateFormContent={this.generateFormContent}
				scrollCallback={(fullPath) => {
					this[fullPath].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
				}}
				toggleAutoFill={() => {
					this.setState((prevState) => ({
						...prevState,
						autofill: !prevState.autofill,
					}));
				}}
				autofill={this.state.autofill}
			/>
		);
	}
}

export default OperationalMetrics;
