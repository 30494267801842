import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { capitalize } from "../../utils/Functions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import generalTheme from "../../utils/theme"
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

const themeVariant = "dark";
const theme = generalTheme.palette[themeVariant];

function MinusSquare(props) {
	return (
		<SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
		</SvgIcon>
	);
}

function PlusSquare(props) {
	return (
		<SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
		</SvgIcon>
	);
}

function TransitionComponent(props) {
	const style = useSpring({
		from: { opacity: 0, transform: "translate3d(20px,0,0)" },
		to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

TransitionComponent.propTypes = {
	/**
	 * Show the component; triggers the enter or exit states
	 */
	in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
	iconContainer: {
		"& .close": {
			opacity: 0.9,
		},
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
	},
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
	root: {
		height: 264,
		color: "#000000",
	},
});

// Generates a tree component using a tree object
export const generateNavTree = (tree, callBack) => {
	// Recursive base case. Leaf node hit.
	if (!tree.children) {
		return null;
	}

	//if children are leaves then this will be an empty array
	let childrenComponents = tree.children.map((child) => generateNavTree(child, callBack)).filter((x) => x);

	let leaf = childrenComponents.length === 0;

	if (leaf) {
		return (
			<StyledTreeItem
				label={
					<div
						className="flex-row"
						style={{
							cursor: "pointer",
							alignItems: "center",
							fontSize: "1.5vh",
							color: theme.contrastText
						}}
						onClick={() => {
							callBack(tree.fullPath);
						}}
					>
						{capitalize(tree.path)}
						{tree.errors !== -1 ? (
							tree.errors === 0 ? (
								<CheckCircleIcon style={{ fontSize: "1.7vh", marginLeft: 4, color: "green" }} />
							) : (
									<ErrorIcon style={{ fontSize: "1.7vh", marginLeft: 4, color: "red" }} />
								)
						) : (
								<div />
							)}
					</div>
				}
				nodeId={tree.fullPath}
			/>
		);
	} else {
		return (
			<StyledTreeItem
				label={
					<div className="flex-row" style={{ cursor: "pointer", alignItems: "center", color: theme.contrastText }}>
						<div style={{ fontSize: "1.6vh" }}>{capitalize(tree.path)}</div>
						{tree.errors !== -1 ? (
							tree.errors === 0 ? (
								<CheckCircleIcon style={{ fontSize: "1.7vh", marginLeft: 4, color: "green" }} />
							) : (
									<ErrorIcon style={{ fontSize: "1.7vh", marginLeft: 4, color: "red" }} />
								)
						) : (
								<div />
							)}
					</div>
				}
				nodeId={tree.fullPath}
			>
				{childrenComponents}
			</StyledTreeItem>
		);
	}
};

const NavTree = ({ tree, callBack, defaultExpanded }) => {
	const classes = useStyles();

	return (
		<TreeView
			className={classes.root}
			defaultExpanded={defaultExpanded}
			defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon style={{ color: theme.contrastText }} />}
			defaultExpandIcon={<AddBoxOutlinedIcon style={{ color: theme.contrastText }} />}
			defaultEndIcon={<div style={{ height: 10, width: 10, borderRadius: "2vh", border: "1px solid grey" }} />}
		>
			{generateNavTree(tree, callBack)}
		</TreeView>
	);
};

export default NavTree;
