import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import generalTheme from "../../utils/theme";
import { register } from "../../axios/backend";

const registrationFields = ["Company Name", "Email", "Password", "Confirm Password"];

const Registration = (props) => {
	useEffect(() => {
		//if no token in url just go back to home
		if (props.location.search.length === 0) {
			props.history.push("/");
		}
		console.log(props.location.search.split("?reg_token=")[1]);
	}, []);

	const [registrationDetails, setRegistrationDetails] = useState(
		registrationFields.map((field) => ({
			displayName: field,
			value: "",
			errorText: "",
		}))
	);

	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];

	const registerOnClick = () => {
		let i,
			error = false;
		let stateObj = [...registrationDetails];
		for (i = 0; i < registrationDetails.length; i++) {
			let field = registrationDetails[i];
			if (field.value === "") {
				error = true;
				stateObj[i].errorText = "Field cannot be blank.";
			}
		}
		if (registrationDetails[2].value !== registrationDetails[3].value) {
			error = true;
			stateObj[3].errorText = "Passwords do not match.";
		}
		if (error) {
			setRegistrationDetails(stateObj);
			return;
		}
		register({
			name: registrationDetails[0].value,
			email: registrationDetails[1].value,
			password: registrationDetails[2].value,
			reg_token: props.location.search.split("?reg_token=")[1],
		});
	};

	return (
		<div className="flex-column registration" style={{ width: "100vw", height: "91vh", padding: "5vh 10vh 5vh 10vh", color: "white" }}>
			<div style={{ fontSize: "4vh", marginBottom: 10 }}>Registration</div>
			<i style={{ marginBottom: "10vh", fontSize: "1.75vh", color: "#d3d3d3" }}>
				Please fill out your desired account credential information in the form below to create your account.
			</i>
			<div className="flex-column" style={{ alignItems: "center" }}>
				{registrationDetails.map((field, ind) => {
					return (
						<div className="flex-column" style={{ marginBottom: "4vh" }}>
							<TextField
								error={registrationDetails[ind].errorText !== ""}
								helperText={registrationDetails[ind].errorText}
								value={registrationDetails[ind].value}
								onChange={(e) => {
									let stateObj = [...registrationDetails];
									stateObj[ind].value = e.target.value;
									stateObj[ind].errorText = "";
									setRegistrationDetails(stateObj);
								}}
								type={ind > 1 ? "password" : "text"}
								label={field.displayName}
								id="outlined"
								variant="outlined"
								style={{
									width: "40vw",
									color: theme.contrastText,
									borderRadius: 5,
								}}
							/>
						</div>
					);
				})}
				<div className="flex-column" style={{ width: "40vw", alignItems: "flex-end", marginTop: "5vh" }}>
					<div
						style={{
							fontSize: "1.75vh",
							border: `1px solid ${theme.contrastText}`,
							padding: "10px 15px 10px 15px",
							borderRadius: 5,
							cursor: "pointer",
						}}
						onClick={() => {
							registerOnClick();
						}}
					>
						Register
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Registration);
