import React, { useState, useEffect } from "react";
import { tabs } from "../../utils/Constants";
import { prettifyOutputNames, numberWithComma, determineColor, typePrettify, capitalize } from "../../utils/Functions";
import dummyData from "./DummyData";
import TextField from "@material-ui/core/TextField";
import DoneIcon from "@material-ui/icons/Done";
import Modal from "@material-ui/core/Modal";
import ReactApexChart from "react-apexcharts";
import "./Dashboard.css";
import { TransitionGroup, CSSTransition } from "react-transition-group"; // ES6
import TargetStrikes from "./TargetStrikes";
import generalTheme from "../../utils/theme";
import HomeIcon from "@material-ui/icons/Home";
import { withRouter } from "react-router-dom";
import TooltipComponent from "../../components/elements/TooltipComponent";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssessmentIcon from "@material-ui/icons/Assessment";
import "./Dashboard.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import { getDataSets, createDataSet } from "../../axios/backend";
import axios from "axios";
import Fade from "@material-ui/core/Fade";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";

const tabIcons = [
	<AttachMoneyIcon style={{ fontSize: "3vh", margin: 12 }} />,
	<AccountBalanceWalletIcon style={{ fontSize: "3vh", margin: 12 }} />,
	<AssessmentIcon style={{ fontSize: "3vh", margin: 12 }} />,
];
const targets = ["All", "Very Off", "Slightly Off", "On Target"];
const labels = ["Metric", "Value", "Target"];
const targetMap = {
	"Very Off": "very off",
	"Slightly Off": "slightly off",
	"On Target": "on target",
};

const Dashboard = (props) => {
	const [open, setOpen] = useState(true);
	const [currTab, setCurrTab] = useState("Financial Info");
	const [targetFilters, setTargetFilters] = useState(
		targets.map((target, index) => ({
			checked: index === 0,
			label: target,
		}))
	);
	const [page, setPage] = useState(0);
	const [targetCounts, setTargetCounts] = useState({
		veryOff: 0,
		slightlyOff: 0,
		onTarget: 0,
	}); //Counts for each target (off, slightly off, very off)
	const [searchVal, setSearchVal] = useState(""); //Searchbar val
	const [metricData, setMetricData] = useState([]);
	const [searchFilteredData, setSearchFilteredData] = useState([]); //List of output filtered by search value
	const [targetFilteredData, setTargetFilteredData] = useState([]); //List of outputs filtered by selected target
	const [targetStrikeData, setTargetStrikeData] = useState(null); //Currently selected output
	const [outputs, setOutputs] = useState([]); //Outputs data for currDataSet
	const [dataSets, setDataSets] = useState([]); //List of names of datasets for a company
	const [filterModal, setFilterModal] = useState(false); //Controls whether the filter modal is open
	const [newDataSet, setNewDataSet] = useState(""); //Value for the generate new data set textfield
	const [duplicateDS, setDuplicateDS] = useState(null);
	const [deleteDS, setDeleteDS] = useState(null);
	const [company, setCompany] = useState(null);

	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];

	let currDataSet = props.match.params.dataset;

	if (currDataSet && dataSets.length > 0 && !company && !dataSets.map((ds) => ds.name).includes(currDataSet)) {
		props.history.push("/datasets");
	}

	const authHeader = axios.defaults.headers.common["Authorization"]

	useEffect(() => {
		if (props.location.state?.company) {
			console.log(props.location.state);
			setCompany(props.location.state.company);
		}
		//TODO get request for actual metric data
		//TODO integrate data sets logic into this or something
		if (authHeader === undefined) {return}
		axios
			.get(`/company/dataset`)
			.then((res) => {
				console.log(`GOT RES`, res)
				const sortedDataSets = res?.data.sort((x, y) => (x.name === "Original" ? -1 : y.name === "Original" ? 1 : 0));
				setDataSets(sortedDataSets);
			})
			.catch((err) => {
				console.log(err);
			});

		//TODO Get list of data sets
	}, [authHeader]);

	//.get(`/company/dataset/output/output1)
	/**
	 * {
	 * 	inputs:{
	 * 		input1:
	 * 		input2:
	 * 	}
	 * }
	 *
	 */

	//.post(`/company/dataset/output/output1) {dataset, {input1:}, output}
	/**
	 *
	 *
	 */

	useEffect(() => {
		//If a regular user is trying to see their own dataset
		if (currDataSet === undefined || authHeader === undefined) {return}
		let endpoint =
			company && currDataSet ? `/company/dataset_admin/${currDataSet}/${company}/outputs` : `/company/dataset/${currDataSet}/outputs`;
		axios
			.get(endpoint)
			.then((res) => {
				console.log(res);
				setMetricData(prettifyOutputNames(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}, [currDataSet, company, authHeader]);

	useEffect(() => {
		if (targetFilters[0].checked) {
			setTargetFilteredData(metricData);
		} else {
			setTargetFilteredData(
				metricData.filter((data) =>
					targetFilters
						.map((target) => (target.checked ? targetMap[target.label] : null))
						.filter((x) => x)
						.includes(data.target)
				)
			);
		}

		let veryOff = 0,
			slightlyOff = 0,
			onTarget = 0,
			uncategorized = 0;

		for (let i = 0; i < metricData.length; i++) {
			let target = metricData[i].target;
			//TODO needs to be changed to actual vals
			target === "very off" ? veryOff++ : target === "slightly off" ? slightlyOff++ : target === "on target" ? onTarget++ : uncategorized++;
			let total = veryOff + slightlyOff + onTarget;
			setTargetCounts({
				veryOff: Math.round((veryOff / total) * 100),
				slightlyOff: Math.round((slightlyOff / total) * 100),
				onTarget: Math.round((onTarget / total) * 100),
			});
		}
	}, [targetFilters, metricData]);

	useEffect(() => {
		setSearchFilteredData(targetFilteredData.filter((data) => data.prettyName?.toLowerCase().includes(searchVal.toLowerCase())));
	}, [searchVal, targetFilteredData]);

	const renderRadialGraphic = (color, label, value) => {
		return (
			<ReactApexChart
				options={{
					chart: {
						type: "radialBar",
						animations: {
							enabled: true,
							speed: 1000,
							animateGradually: {
								enabled: true,
								delay: 1000,
							},
						},
					},
					plotOptions: {
						radialBar: {
							dataLabels: {
								enabled: true,
								value: {
									fontWeight: "bold",
									color: color,
									fontSize: "5vh",
								},
								name: {
									offsetY: -2 * (window.innerHeight / 100),
									fontSize: "1.75vh",
								},
							},
							track: {
								background: theme.contrastText,
								strokeWidth: "50%",
								opacity: 0.1,
							},
						},
					},
					colors: [color],
					labels: [label],
				}}
				series={[value]}
				type={"radialBar"}
				height={35 * (window.innerHeight / 100)}
			/>
		);
	};

	const SpendingGraphic = () => {
		let series = [];
		let labels = [];
		metricData.map((data) => {
			series.push(parseInt(data.value));
			labels.push(data.name);
		});

		console.log(series);
		console.log(labels);

		return (
			<div>
				<ReactApexChart
					options={{
						labels: labels,
						series: series,
					}}
					type={"pie"}
					series={series}
				/>
			</div>
		);
	};

	const targetStrikeDataContent = () => {
		return targetStrikeData ? (
			<TargetStrikes
				data={targetStrikeData}
				determineColor={determineColor}
				disabled={currDataSet === "Originals"}
				dataSet={currDataSet}
				onChangeSubmit={() => {
					window.location.reload();
				}}
				company={company}
			/>
		) : (
			<div />
		);
	};

	const deleteDSBody = (open) => {
		return deleteDS ? (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column"
					style={{
						width: "30vw",
						height: "fit-content",
						backgroundColor: theme.primary,
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: "30vh",
						margin: "auto",
						outline: "none",
						borderRadius: 5,
						color: theme.contrastText,
						paddingBottom: "5vh",
					}}
				>
					<div
						className="flex-column"
						style={{
							textAlign: "center",
							padding: "4vh",
							fontSize: "4vh",
							backgroundColor: theme.primary,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							marginBottom: "4vh",
						}}
					>
						<b>Are you sure?</b>
					</div>
					<div className="flex-column" style={{ alignItems: "center" }}>
						<div className="flex-row">
							<div
								style={{
									border: `1px solid ${theme.contrastText}`,
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginRight: "2vw",
								}}
								onClick={() => {
									setDeleteDS(false);
								}}
							>
								Cancel
							</div>
							<div
								style={{
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginLeft: "2vw",
									backgroundColor: theme.red,
									color: theme.contrastText,
								}}
								onClick={() => {
									axios
										.delete(`/company/dataset/${deleteDS.name}`)
										.then((res) => {
											console.log(res);
											window.location.reload();
										})
										.catch((err) => {
											console.log(err);
										});
								}}
							>
								Delete
							</div>
						</div>
					</div>
				</div>
			</Fade>
		) : (
			<div />
		);
	};

	const duplicateDSBody = (open) => {
		return duplicateDS ? (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column filter-modal"
					style={{
						width: "50vw",
						height: "40vh",
						backgroundColor: "white",
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: "30vh",
						margin: "auto",
						outline: "none",
						borderRadius: 5,
					}}
				>
					<div
						style={{
							textAlign: "center",
							padding: "2vh",
							fontSize: "2.25vh",
							backgroundColor: theme.secondaryDark,
							borderTopLeftRadius: 5,
							borderTopRightRadius: 5,
							color: theme.contrastText,
							height: "7.5vh",
						}}
					>
						<b>Duplicate Data Set</b>
					</div>
					<div
						className="flex-column"
						style={{
							padding: "7vh",
							backgroundColor: theme.primary,
							height: "100%",
							color: theme.contrastText,
							borderBottomRightRadius: 5,
							borderBottomLeftRadius: 5,
							alignItems: "center",
						}}
					>
						<div className="flex-row" style={{ paddingLeft: "2vw", width: "100%", marginBottom: "5vh", alignItems: "center" }}>
							<div style={{ fontSize: "1.75vh", color: "#808080" }}>Data set to be duplicated: &nbsp;</div>
							<div style={{ fontSize: "2.4vh", color: theme.contrastText, fontWeight: "bold" }}>{duplicateDS.name}</div>
						</div>
						<div style={{ width: "95%", borderBottom: `1px solid #808080`, marginBottom: "5vh" }} />
						<div className="flex-row" style={{ paddingLeft: "2vw", width: "100%", alignItems: "center", marginBottom: "5vh" }}>
							<div style={{ fontSize: "1.75vh", color: "#808080", marginRight: 10 }}>New data set name: &nbsp;</div>
							<TextField
								onChange={(e) => {
									setNewDataSet(e.target.value);
								}}
								id="outlined-basic"
								variant="outlined"
								style={{
									width: "70%",
									backgroundColor: theme.secondary,
									color: theme.contrastText,
									borderRadius: 5,
									height: "5vh",
								}}
							/>
						</div>
						<div className="flex-column" style={{ width: "100%", alignItems: "center" }}>
							<div
								style={{
									padding: "1vh 2vh 1vh 2vh",
									borderRadius: 5,
									backgroundColor: "green",
									color: theme.contrastText,
									cursor: "pointer",
									boxShadow: `2px 2px 5px ${theme.shadow}`,
								}}
								onClick={() => {
									if (newDataSet !== "") {
										axios
											.post(`/company/dataset/duplicate`, {
												original_dataset_name: duplicateDS.name,
												new_dataset_name: newDataSet,
											})
											.then((res) => {
												window.location.reload();
											})
											.catch((err) => {
												console.log(err);
											});
									}
								}}
							>
								Duplicate
							</div>
						</div>
					</div>
				</div>
			</Fade>
		) : (
			<div />
		);
	};

	const filterModalBody = (open) => {
		return (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column filter-modal"
					style={{
						width: "50vw",
						height: "64vh",
						backgroundColor: "white",
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
						outline: "none",
						borderRadius: 5,
					}}
				>
					<div
						style={{
							textAlign: "center",
							padding: "2vh",
							fontSize: "2.25vh",
							backgroundColor: theme.secondaryDark,
							borderTopLeftRadius: 5,
							borderTopRightRadius: 5,
							color: theme.contrastText,
							height: "7.5vh",
						}}
					>
						<b>Data Filters</b>
					</div>
					<div
						className="flex-row"
						style={{
							padding: "7vh",
							backgroundColor: theme.primary,
							height: "100%",
							color: theme.contrastText,
							borderBottomRightRadius: 5,
							borderBottomLeftRadius: 5,
						}}
					>
						<div className="flex-column" style={{ alignItems: "center", flex: 1, paddingLeft: "2vw" }}>
							<div style={{ fontSize: "2.4vh", fontWeight: "bold", marginBottom: 10 }}>Target Accuracy? Idk</div>
							<i style={{ fontSize: "1.5vh", lineHeight: "1.7vh", textAlign: "center", marginBottom: 40 }}>
								Select what type of output metrics to show based on how far off they are to our benchmarks.
							</i>
							<div className="flex-column" style={{ alignItems: "flex-start", width: "85%" }}>
								{targetFilters.map((targetFilter, index) => {
									let checked = targetFilter.checked;
									return (
										<div key={targetFilter.label} className="flex-column" style={{ marginLeft: "1vw", alignItems: "center", flex: 1 }}>
											<div
												className="flex-row"
												style={{
													marginTop: "1.5vh",
													alignItems: "center",
													fontSize: "1.5vh",
													cursor: "pointer",
													marginBottom: "1.5vh",
												}}
												onClick={() => {
													let returnState = targetFilters.slice();
													returnState[index] = {
														...targetFilters[index],
														checked: !checked,
													};
													if (index === 0 && returnState[0].checked) {
														for (let i = 1; i < returnState.length; i++) {
															returnState[i].checked = false;
														}
													} else {
														let allChecked = true;
														for (let i = 1; i < returnState.length; i++) {
															if (returnState[i].checked) allChecked = false;
														}
														returnState[0].checked = allChecked;
													}
													setTargetFilters(returnState);
												}}
											>
												<div
													className="flex-row flex-center"
													style={{
														width: "3vh",
														height: "3vh",
														borderRadius: 5,
														backgroundColor: theme.contrastText,
														marginRight: 10,
														boxShadow: `2px 2px 5px ${theme.shadow}`,
													}}
												>
													{targetFilter.checked ? <DoneIcon style={{ color: "#000", fontSize: "2.5vh" }} /> : ""}
												</div>
												<div style={{ fontWeight: targetFilter.checked ? "bold" : "normal" }}>{targetFilter.label}</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		);
	};

	const calculateTotalSpending = () => {
		let totalSpend = metricData?.find((x) => x.name === "total_it_expense");
		if (totalSpend) {
			return typePrettify("dollars", totalSpend.value);
		} else return "$0";
	};

	const renderOutput = (category) => {
		let outputs = searchFilteredData.filter((output) => output.category === category).filter((x) => x.name !== "total_it_expense");
		// .filter((x) => x.name !== "total_it_expense" && x.name !== "total_comp");
		return (
			<>
				{outputs.length > 0 && (
					<>
						<div style={{ fontSize: "1.75vh", color: "#808080" }}>{category}</div>
						{outputs.map((row, index) => {
							return (
								<CSSTransition key={index} timeout={500} classNames="data-card">
									<div className={`flex-row active-data-card`} style={{ width: "100%" }}>
										{[0, 1, 2].map((ind) => {
											let data = outputs[index + ind];
											if (index % 3 === 0 && index + ind < outputs.length)
												return (
													<div
														key={ind}
														style={{
															backgroundColor: theme.secondary,
															boxShadow: `5px 5px 10px ${theme.shadow}`,
															cursor: "pointer",
															height: "9vh",
														}}
														className="flex-row data-card"
														onClick={() => {
															let endpoint = company
																? `/company/dataset_admin/${currDataSet}/${company}/outputDependencies`
																: `/company/dataset/${currDataSet}/outputDependencies`;
															//setTargetStrikeData(data);
															axios
																.get(endpoint, {
																	params: { output: data.name },
																})
																.then((res) => {
																	setTargetStrikeData({
																		inputs: { ...res.data },
																		...data,
																	});
																})
																.catch((err) => {
																	console.log(err);
																});
														}}
													>
														<div
															style={{
																width: 15,
																backgroundColor: determineColor(data.target),
															}}
														/>
														<div className="flex-column" style={{ padding: "1.25vh", flex: 1 }}>
															<div style={{ fontSize: "1vh", lineHeight: 1.5, marginBottom: 10 }}>Name</div>
															<Tooltip title={data.prettyName} placement={"top"}>
																<div
																	className="two-line-text"
																	style={{
																		fontSize: "1.6vh",
																		fontWeight: "bold",
																		lineHeight: "1.7vh",
																	}}
																>
																	{data.prettyName}
																</div>
															</Tooltip>
														</div>
														<div style={{ borderRight: "1px solid #d3d3d3", margin: "1vh", opacity: 0.4 }} />
														<div className="flex-column" style={{ padding: "1.25vh", flex: 1 }}>
															<div style={{ fontSize: "1vh", lineHeight: 1.5 }}>Value</div>
															<div style={{ fontSize: "1.6vh", fontWeight: "bold" }}>
																{typePrettify(data.type, numberWithComma(parseFloat(data.value).toFixed(2)))}
															</div>
														</div>
													</div>
												);
											else if (index * 3 > outputs.length - 2) {
												return <div style={{ flex: 1, marginRight: "3vh" }} />;
											}
										})}
									</div>
								</CSSTransition>
							);
						})}
					</>
				)}
			</>
		);
	};

	const targetComparator = (output1, output2) => {
		if (output1.target === "n/a") {
			return 1;
		} else if (output1.target === "very off") {
			if (output2.target !== "very off") return -1;
			else return 1;
		} else if (output1.target === "slightly off") {
			if (output2.target === "very off") return 1;
			else return -1;
		} else if (output1.target === "on target") {
			if (output2.target === "n/a") return -1;
			else return 1;
		} else return 1;
	};

	return currDataSet ? (
		<div className="flex-row dashboard-container" style={{ flex: 1, width: "100vw", height: "92vh" }}>
			<Modal
				open={!!targetStrikeData}
				onClose={() => {
					setTargetStrikeData(null);
				}}
			>
				{targetStrikeDataContent()}
			</Modal>
			<Modal
				open={!!filterModal}
				onClose={() => {
					setFilterModal(false);
				}}
			>
				{filterModalBody(filterModal)}
			</Modal>

			<div className="flex-column dashboard-content" style={{ flex: 5, width: "100%", backgroundColor: theme.primary }}>
				<div
					className="flex-column"
					style={{
						flex: 1,
						backgroundColor: theme.secondaryDark,
						padding: "5vh",
						paddingTop: "2vh",
						color: theme.contrastText,
					}}
				>
					<div
						className="flex-row"
						style={{ alignItems: "center", cursor: "pointer" }}
						onClick={() => {
							props.history.push("/datasets");
						}}
					>
						<ArrowBackIcon style={{ fontSize: "3vh" }} />
						<div style={{ marginLeft: 5, fontSize: "1.75vh" }}>Back</div>
					</div>

					<div className="flex-row" style={{ flex: 5, alignItems: "center", height: "30vh" }}>
						<div className="flex-column" style={{ flex: 1, marginRight: "5vh", height: "70%" }}>
							<div style={{ fontSize: "2vh", color: "#808080" }}>Data Set Name: </div>
							<div className="reveal-from-left" style={{ fontWeight: "bold", fontSize: "4vh", marginTop: "1vh", marginBottom: "3vh" }}>
								{currDataSet}
							</div>
							<div style={{ fontSize: "2vh", color: "#808080" }}>Total Spending: </div>
							<div className="reveal-from-left" style={{ fontWeight: "bold", fontSize: "5vh", marginTop: "1vh" }}>
								{calculateTotalSpending()}
							</div>
						</div>
						<div style={{ height: "75%", borderRight: "2px solid #808080" }} />
						<div className="flex-row" style={{ flex: 3, position: "relative", height: "100%", alignItems: "center" }}>
							<div className="reveal-from-top" style={{ flex: 1, height: "100%" }}>
								{renderRadialGraphic("#F76C50", "Off Target", targetCounts.veryOff)}
							</div>
							<div style={{ height: "50%", borderRight: "2px solid #808080" }} />
							<div className="reveal-from-top" style={{ flex: 1, height: "100%" }}>
								{renderRadialGraphic("#FFB133", "Slightly Off", targetCounts.slightlyOff)}
							</div>
							<div style={{ height: "50%", borderRight: "2px solid #808080" }} />
							<div className="reveal-from-top" style={{ flex: 1, height: "100%" }}>
								{renderRadialGraphic("#7DBE74", "On Target", targetCounts.onTarget)}
							</div>
						</div>
					</div>
					<div className="flex-row" style={{ fontSize: "2.5vh", fontWeight: "bold", alignItems: "center" }}>
						<div style={{ marginRight: 10, color: "#d3d3d3" }}>{"Output Metrics"}</div>
						<div style={{ flex: 1 }}>
							<TooltipComponent
								tip={
									"Output Metrics are data outputs that are calculated from the inputs you have provided. They are then compared to our benchmarks and are further categorized based off how close they match our benchmark."
								}
								color={theme.contractText}
							/>
						</div>
					</div>
					<div className="flex-column" style={{ flex: 10, marginTop: "1.5vh" }}>
						<div className="flex-row" style={{ alignItems: "center", marginBottom: "2vh" }}>
							<TextField
								onChange={(e) => {
									setSearchVal(e.target.value);
								}}
								id="outlined-basic"
								placeholder="Search..."
								variant="outlined"
								style={{
									width: "40%",
									backgroundColor: theme.secondary,
									color: theme.contrastText,
									borderRadius: 5,
									height: "5vh",
									boxShadow: `2px 2px 5px ${theme.shadow}`,
								}}
							/>
							<div style={{ flex: 1 }} />
							<div
								className="flex-row"
								style={{
									fontSize: "1.8vh",
									alignItems: "center",
									padding: "1.5vh 2vh 1.5vh 2vh",
									borderRadius: 5,
									fontWeight: "normal",
									cursor: "pointer",
									marginRight: "5vh",
									backgroundColor: theme.secondary,
									boxShadow: `4px 4px 10px ${theme.shadow}`,
								}}
								onClick={() => {
									setFilterModal(true);
								}}
							>
								<FilterListIcon style={{ fontSize: "2.5vh", marginRight: ".5vw" }} />
								<div>Filters</div>
							</div>
						</div>

						<div
							className="flex-column data-container  reveal-from-bottom"
							style={{
								paddingLeft: "2vh",
								marginLeft: "-2vh",
								paddingRight: "2vh",
								width: "100%",
								height: "42vh",
								overflowY: "scroll",
							}}
						>
							<TransitionGroup>
								{renderOutput("Compensation")}
								{renderOutput("Headcount")}
								{renderOutput("Software Expense")}
								{renderOutput("Professional Fees")}
								{renderOutput("Balance Sheet")}
								{renderOutput("Hardware Rental Leases")}
								{renderOutput("Facilities Occupancy")}
								{renderOutput("Hardware Maintenance/Depreciation")}
								{renderOutput("Communications")}
								{renderOutput("Travel & Entertainment")}
								{renderOutput("Insurance")}
								{renderOutput("Legal")}
								{renderOutput("Miscellaneous")}
							</TransitionGroup>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="flex-column" style={{ width: "100vw", height: "91vh", padding: "5vh 10vh 5vh 10vh", color: theme.contrastText }}>
			<Modal
				open={!!duplicateDS}
				onClose={() => {
					setDuplicateDS(null);
				}}
			>
				{duplicateDSBody(duplicateDS !== null)}
			</Modal>
			<Modal
				open={!!deleteDS}
				onClose={() => {
					setDeleteDS(null);
				}}
			>
				{deleteDSBody(deleteDS !== null)}
			</Modal>
			{dataSets.length > 0 && (
				<>
					<div style={{ fontSize: "4vh", marginBottom: 10 }}>Data Sets</div>
					<i style={{ marginBottom: "5vh", fontSize: "1.75vh", color: "#d3d3d3" }}>
						Click on a dataset to views its outputs. The original data set's inputs are read only and cannot be modified.
					</i>
				</>
			)}

			<div className="flex-column reveal-from-bottom">
				{dataSets.map((ds) => {
					let createdDate = new Date(Date.parse(ds.created));
					let lastUpdated = new Date(Date.parse(ds.last_updated));
					return (
						<div
							key={ds.name}
							className="flex-row data-set-card"
							style={{
								width: "95%",
								boxShadow: `10px 10px 25px ${theme.shadow}`,
								backgroundColor: theme.primary,
								marginBottom: "2vh",
								borderRadius: 5,
								padding: "2vh 3vh 2vh 3vh",
								cursor: "pointer",
								alignItems: "center",
							}}
						>
							<div
								className="flex-column"
								style={{ flex: 1 }}
								onClick={() => {
									console.log(`/datasets/${ds.name}`);
									props.history.push(`/datasets/${ds.name}`);
								}}
							>
								<div style={{ fontSize: "2.5vh", fontWeight: "bold", marginBottom: 10 }}>{ds.name}</div>
								<div className="flex-row" style={{ fontSize: "1.5vh", lineHeight: 1.4 }}>
									<div style={{ color: "#808080" }}>Created:&nbsp;</div>
									<div>{createdDate.toString()}</div>
								</div>
								<div className="flex-row" style={{ fontSize: "1.5vh", lineHeight: 1.4 }}>
									<div style={{ color: "#808080" }}>Last Updated:&nbsp;</div>
									<div>{ds.last_updated ? lastUpdated.toString() : createdDate.toString()}</div>
								</div>
							</div>
							{ds.name !== "Original" && ds.name !== "TechFinIQ Recommendation" && (
								<Tooltip title={"Compare Data Set"} placement={"top"}>
									<CompareArrowsIcon
										style={{ fontSize: "4vh", color: theme.contrastText, marginRight: 10, zIndex: 10 }}
										onClick={() => {
											props.history.push({
												pathname: "/datasets/comparison",
												state: { dataSet: ds.name },
											});
										}}
									/>
								</Tooltip>
							)}

							<Tooltip title={"Duplicate data set"} placement={"top"}>
								<AddCircleIcon
									style={{ fontSize: "4vh", color: theme.contrastText, marginRight: 10, zIndex: 10 }}
									onClick={() => {
										setDuplicateDS(ds);
									}}
								/>
							</Tooltip>
							<Tooltip title={"Delete data set"} placement={"top"}>
								<DeleteIcon
									style={{ fontSize: "4vh", color: theme.red, zIndex: 10 }}
									onClick={() => {
										setDeleteDS(ds);
									}}
								/>
							</Tooltip>
						</div>
					);
				})}
			</div>
			{dataSets.length === 0 && (
				<Fade in={true} timeout={500}>
					<div className="flex-column flex-center" style={{ width: "100%", height: "75%" }}>
						<div style={{ fontSize: "3.5vh", marginBottom: "7vh" }}>
							You have <b style={{ color: theme.red }}>0</b> data sets currently
						</div>
						<div
							style={{
								padding: "1.5vh 2vh 1.5vh 2vh",
								borderRadius: 5,
								border: `1px solid ${theme.contrastText}`,
								cursor: "pointer",
								fonySize: "2vh",
							}}
							onClick={() => {
								props.history.push("/form");
							}}
						>
							Create Original Data Set
						</div>
					</div>
				</Fade>
			)}
		</div>
	);
};

export default withRouter(Dashboard);
