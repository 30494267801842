import React, { Fragment } from "react";
import * as Balance from "../../../utils/Templates/BalanceSheetTemplate";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import { TextField, FormControl, RadioGroup, Radio, Tooltip, Button } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {
	set,
	capitalize,
	objToTree,
	prettifyInputName,
	getTypeForInputName,
	DollarFormatCustom,
	RatioFormatCustom,
	IntFormatCustom,
	strip,
} from "../../../utils/Functions";
import Form from "./Form";
import CheckIcon from "@material-ui/icons/Check";
import generalTheme from "../../../utils/theme";

const themeVariant = "dark";
const theme = generalTheme.palette[themeVariant];

const variableMetrics = ["sw_capital_spend", "hw_capital_spend"];

// Style for button used to click yearly data implementation
const buttonStyle = {
	background: "#801919",
	color: "white",
	size: "Large",
};

class BalanceSheet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			tree: objToTree(Balance.JSONtemplate, "balance_sheet", null),
			yearly: [false, false],
			autofill: false,
		};
		this.handleNext = this.handleNext.bind(this);
		this.generateFormContent = this.generateFormContent.bind(this);
		this.storeFormValues = this.storeFormValues.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.renderTextField = this.renderTextField.bind(this);
	}

	renderTextField = (current) => {
		let inputProps = {};
		let type = getTypeForInputName(current.fullPath);

		if (type === "dollars") {
			inputProps["inputComponent"] = DollarFormatCustom;
		} else if (type === "ratio") {
			inputProps["inputComponent"] = RatioFormatCustom;
		} else {
			inputProps["inputComponent"] = IntFormatCustom;
		}
		return (
			<TextField
				InputProps={inputProps}
				variant="outlined"
				inputRef={(input) => (this[current.fullPath] = input)}
				label={prettifyInputName(current.path)}
				style={{ margin: "1vh" }}
				error={current.errors > 0}
				helperText={current.errors > 0 ? "Cannot be blank." : ""}
				onKeyDown={(e) => {
					if (e.keyCode === 9 && current.fullPath === "balance_sheet.miscellaneous.capital_plan_hardware") e.preventDefault();
				}}
			/>
		);
	};

	storeFormValues = (obj, tree) => {
		if (!tree.children) {
			if (this.state.autofill) {
				set(obj, tree.fullPath, tree.autofillVal);
			} else {
				set(obj, tree.fullPath, strip(this[tree.fullPath].value, getTypeForInputName(tree.fullPath)));
			}
			return;
		}

		tree.children.map((child) => {
			this.storeFormValues(obj, child);
		});
	};

	handleNext = () => {
		let formObj = {};
		this.calculateErrors(this.state.tree);
		if (this.state.tree.errors > 0 && !this.state.autofill) return;

		this.storeFormValues(formObj, this.state.tree);

		setTimeout(() => {
			this.props.submitFormDataToParent(formObj);
		}, 100);
	};

	calculateErrors = (tree) => {
		if (!tree.children) {
			tree.errors = this[tree.fullPath].value === "" ? 1 : 0;
			return this[tree.fullPath].value === "" ? 1 : 0;
		}

		let errorSum = 0;

		tree.children.map((children) => {
			errorSum += this.calculateErrors(children);
		});
		tree.errors = errorSum;
		this.setState({ tree: tree });
		return errorSum;
	};

	calculateCircleColor = (errors) => {
		if (errors === -1) return theme.primaryDark;
		else if (errors === 0) return "green";
		else return "red";
	};

	handleClick(props) {
		const index = props === "sw_capital_spend" ? 0 : 1;
		let returnState = this.state.yearly.slice();
		returnState[index] = !returnState[index];
		this.setState({ yearly: returnState });
	}

	generateFormContent = (tree) => {
		return tree.children.map((depthOneChild, index) => (
			<div style={{ color: theme.contrastText }}>
				<div className="flex-row" style={{ alignItems: "center", fontWeight: "bold", fontSize: "1.75vh" }}>
					<div
						className="filled-circle"
						style={{
							backgroundColor: this.calculateCircleColor(depthOneChild.errors),
							border: `1px solid ${this.calculateCircleColor(depthOneChild.errors)}`,
						}}
					/>
					<Typography style={{ flex: 1 }}>{prettifyInputName(depthOneChild.path)}</Typography>
				</div>
				<div className="form-content-parent flex-column" style={{ backgroundColor: theme.primary }}>
					{variableMetrics.includes(depthOneChild.path) && (
						<div className="flex-row" style={{ alignItems: "center", marginBottom: "1vh", marginLeft: "1vh" }}>
							<div style={{ marginRight: "1vw", fontWeight: "bold" }}>Data Input :</div>
							<FormControl component="fieldset">
								<Tooltip
									interactive
									arrow="top"
									id="yearlyInputButtonTip"
									title={
										<span style={{ fontSize: "15px" }}>
											Don't know monthly values? No worries! Click this button and we will gather the data from other sections
										</span>
									}
								>
									<Button
										variant="contained"
										style={buttonStyle}
										onClick={() => {
											this.handleClick(depthOneChild.path);
										}}
										value="Input"
										endIcon={<DateRangeIcon />}
									>
										Input Yearly
									</Button>
								</Tooltip>
							</FormControl>
						</div>
					)}
					<div className="flex-row">
						{/**TODO dont hardcode this */}
						{(variableMetrics.includes(depthOneChild.path) &&
							// if you change this check to "this.state.wasButtonClicked == true" and then in the constructor, move
							// wasButtonClicked out of inputTypes so it is it's own thing, it works (somewhat, it updates both button fields)
							depthOneChild.path === "sw_capital_spend" &&
							this.state.yearly[0]) ||
						(depthOneChild.path === "hw_capital_spend" && this.state.yearly[1]) ? (
							<div className="flex-column" style={{ flex: 1, marginLeft: "1vh" }}>
								<CheckIcon style={{ color: "green" }} />
							</div>
						) : (
							depthOneChild.children.map(
								(depthTwoChild, index) =>
									index % 2 === 0 && (
										<div className="flex-column" style={{ flex: 1 }} ref={(input) => (this[depthOneChild.fullPath] = input)}>
											{this.renderTextField(depthTwoChild)}

											{index + 1 < depthOneChild.children.length && this.renderTextField(depthOneChild.children[index + 1])}
										</div>
									)
							)
						)}
					</div>
				</div>
			</div>
		));
	};

	render() {
		return (
			<Form
				tree={this.state.tree}
				defaultExpanded={["balance_sheet"]}
				handleNext={this.handleNext}
				generateFormContent={this.generateFormContent}
				scrollCallback={(fullPath) => {
					this[fullPath].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
				}}
				handleBack={this.props.handleBack}
				toggleAutoFill={() => {
					this.setState((prevState) => ({
						...prevState,
						autofill: !prevState.autofill,
					}));
				}}
				autofill={this.state.autofill}
			/>
		);
	}
}

export default BalanceSheet;
