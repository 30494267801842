export const JSONtemplate = {
	change_control: {
		monthly_change_control_meeting_utilization: "70.0000",
		change_requests_reverted: 5,
		failed_changes_per_month: 3,
		change_requests_per_month: 20,
	},
	cloud: {
		web_application_firewall_utilization: "0.6500",
		data_upload_rate: "2.0 gigabyte / second",
		data_download_rate: "2.0 gigabyte / second",
		private_cloud_to_public_cloud_ratio: "0.3400",
		server_utilization: "0.5500",
	},
	it_customer_support: {
		issues_reported_by_phone: "0.1000",
		resolution_time: "1 hour",
		issues_reported_by_portal: "0.6000",
		resolutions_per_first_call: "0.7000",
		issues_reported_by_email: "0.3000",
		response_time: "5 hours",
		resolution_hit_rate_per_customer_service_rep: "0.6000",
	},
	server: {
		database_release_technical_debt: "2 years",
		technical_debt_of_server_hardware: "2 years",
		reboot_frequency: "1 week",
		cpu_utilization: "0.6500",
		storage_utilization: "0.5500",
		memory_utilization: "0.8500",
		patch_install_success_ratio: ".8000",
	},
	antivirus: {
		antivirus_utilization: "0.9000",
		network_scan_frequency_anomolies: "0.8500",
		servere_threat_detection: "100.0000",
		server_scan_utilization: "0.9500",
		spam_hit_rate: "0.1500",
	},
	network: {
		network_utilization: "0.8000",
		network_capacity: "2.0 gigabyte / second",
		network_latency: "1 millisecond",
		firewall_rule_age_debt: "1 month",
		technical_debt_of_network_hardware: "1 year",
		patch_install_success_ratio: "0.6000",
	},
	yearly_technology_spend: {
		yearly_ml_spend: "1.00",
		yearly_disaster_recovery_spend: "1.00",
		yearly_cybersecurity_spend: "1.00",
		yearly_cloud_spend: "1.00",
		yearly_server_spend: "1.00",
		yearly_ai_spend: "1.00",
	},
	disaster_recovery: {
		backup_frequency: "1 day",
		recovery_time_objective: "6 hours",
		recovery_point_objective: "4 hours",
	},
};
