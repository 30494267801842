import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import generalTheme from "../utils/theme";
import { Typography } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import { TextField } from "@material-ui/core";
import { register, testAuth, testRequest, sendEmail } from "../axios/backend";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import { useAuth0 } from "@auth0/auth0-react";


const loginFieldsConst = ["Email", "Password"];
const tabs = ["Home", "Input Form", "Data Sets", "FAQ"];
const paths = ["/", "/form", "/datasets", "/faq"];
const contactFields = ["Name/Company", "Email", "Message"];

//TODO change this shit

const isAdmin = localStorage.isAdmin;
if (isAdmin) {
	tabs.push("Admin");
	paths.push("/admin");
}

const LayoutDefault = ({ children, history, authenticated }) => {
	const token = localStorage.AuthToken;
	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];

	//TODO change in production
	let currentPagePath = window.location.href.split("https://techfiniq.com")[1];

	const { loginWithRedirect, logout } = useAuth0();
	const [loginError, setLoginError] = useState(null);
	const [loginModal, setLoginModal] = useState(false);
	const [logoutModal, setLogoutModal] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [loginDetails, setLoginDetails] = useState(
		loginFieldsConst.map((field) => ({
			label: field,
			value: "",
			error: "",
		}))
	);
	const [contactModal, setContactModal] = useState(false);
	const [contactDetails, setContactDetails] = useState(
		contactFields.map((field) => ({
			label: field,
			value: "",
			error: "",
		}))
	);

	const onSendClick = () => {
		//TODO send email through email endpoint
		if (validateFields(contactDetails, setContactDetails)) {
			setContactModal(false);
			console.log(contactDetails);
			let emailData = {
				name: contactDetails[0].value,
				email: contactDetails[1].value,
				message: contactDetails[2].value,
			};
			testRequest();
			sendEmail(emailData);
		}
	};

	const contactUsModal = (open) => {
		return (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column"
					style={{
						width: isMobile ? "90vw" : "60vw",
						height: "fit-content",
						backgroundColor: theme.primary,
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
						outline: "none",
						borderRadius: 5,
						color: theme.contrastText,
						paddingBottom: "5vh",
					}}
				>
					<div
						className="flex-column"
						style={{
							textAlign: "center",
							padding: "4vh",
							fontSize: "4vh",
							backgroundColor: theme.primary,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							marginBottom: "4vh",
						}}
					>
						<b>Contact Us</b>
						<i style={{ fontSize: "1.75vh", marginTop: "2vh" }}>
							Interested in doing business, or just have some questions to ask us? Feel free to reach out!
						</i>
					</div>
					<div className="flex-column" style={{}}>
						{contactDetails.map((detail, index) => {
							return (
								<div key={contactDetails[index].value ?? index} className="flex-column" style={{ alignItems: "center", marginBottom: "3vh", flex: 1 }}>
									<TextField
										value={contactDetails[index].value}
										error={detail.error.length > 0}
										helperText={detail.error}
										multiline={detail.label === "Message"}
										rows={5}
										placeholder={detail.label}
										variant="outlined"
										style={{ width: "80%", height: "fit-content" }}
										onChange={(e) => {
											let returnObj = contactDetails.slice();
											returnObj[index] = {
												...returnObj[index],
												value: e.target.value,
											};
											setContactDetails(returnObj);
										}}
									/>
								</div>
							);
						})}
					</div>
					<div className="flex-column" style={{ alignItems: "center" }}>
						<div
							style={{ border: `1px solid ${theme.contrastText}`, padding: "10px 15px 10px 15px", borderRadius: 10, cursor: "pointer" }}
							onClick={onSendClick}
						>
							Send
						</div>
					</div>
				</div>
			</Fade>
		);
	};

	const validateFields = (detailState, setDetailState) => {
		let valid = true;
		let returnObj = detailState.slice();
		detailState.map((detail, index) => {
			if (detail.value.replace(" ", "").length === 0) {
				returnObj[index].error = "Field cannot be blank.";
				valid = false;
			} else {
				returnObj[index].error = "";
			}
		});
		setDetailState(returnObj);
		return valid;
	};

	const setAuthHeader = (token) => {
		const AuthToken = `Bearer ${token}`;
		localStorage.setItem("AuthToken", AuthToken);
		// axios.defaults.headers.common["Authorization"] = AuthToken;
	};

	const onLoginClick = () => {
		if (validateFields(loginDetails, setLoginDetails)) {
			console.log("Logging In");
			let loginDetailsBody = {
				email: loginDetails[0].value,
				password: loginDetails[1].value,
			};
			axios
				.post(`/auth/login`, loginDetailsBody)
				.then((res) => {
					console.log(res);
					if (res.status === 200) {
						setAuthHeader(res.data.access_token);
						localStorage.setItem("isAdmin", res.data.isAdmin);
						setLoginModal(false);
						history.push(currentPagePath);
						if (res.data.isAdmin) {
							window.location.reload(false);
						}
					} else {
						setLoginError(res.data);
					}
				})
				.catch((err) => {
					console.log(err);
					setLoginError("There was an error logging in.");
				});
		}
		if (loginDetails[0].value === "Register") {
			register({
				name: "Adam",
				email: "achang314@gmail.com",
				password: "password",
			});
		}
		{
			if (loginDetails[0].value === "Test") {
				testAuth();
			}
		}
	};

	const resize = () => {
		if (window.innerWidth <= 980) setIsMobile(true);
		else setIsMobile(false);
	};

	useEffect(() => {
		resize();
		window.addEventListener("resize", resize);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, []);

	const logoutModalBody = (open) => {
		return (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column"
					style={{
						width: isMobile ? "70vw" : "30vw",
						height: "fit-content",
						backgroundColor: theme.primary,
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
						outline: "none",
						borderRadius: 5,
						color: theme.contrastText,
						paddingBottom: "5vh",
					}}
				>
					<div
						className="flex-column"
						style={{
							textAlign: "center",
							padding: "4vh",
							fontSize: "4vh",
							backgroundColor: theme.primary,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							marginBottom: "4vh",
						}}
					>
						<b>Are you sure?</b>
					</div>
					<div className="flex-column" style={{ alignItems: "center" }}>
						<div className="flex-row">
							<div
								style={{
									border: `1px solid ${theme.contrastText}`,
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginRight: "2vw",
								}}
								onClick={() => {
									setLogoutModal(false);
								}}
							>
								Cancel
							</div>
							<div
								style={{
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginLeft: "2vw",
									backgroundColor: theme.red,
									color: theme.contrastText,
								}}
								onClick={() => {
									localStorage.removeItem("isAdmin");
									localStorage.removeItem("AuthToken");
									setLogoutModal(false);
									logout({ returnTo: window.location.origin })
									window.location.reload(false);
								}}
							>
								Logout
							</div>
						</div>
					</div>
				</div>
			</Fade>
		);
	};

	const loginModalBody = (open) => {
		return (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column"
					style={{
						width: isMobile ? "80vw" : "40vw",
						height: "fit-content",
						backgroundColor: theme.primary,
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
						outline: "none",
						borderRadius: 5,
						color: theme.contrastText,
						paddingBottom: "5vh",
					}}
				>
					<div
						className="flex-column"
						style={{
							textAlign: "center",
							padding: "4vh",
							fontSize: "4vh",
							backgroundColor: theme.primary,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							marginBottom: "4vh",
						}}
					>
						<b>Login</b>
					</div>
					<div className="flex-column" style={{}}>
						{loginDetails.map((detail, index) => {
							return (
								<div key={loginDetails[index].value ?? index} className="flex-column" style={{ alignItems: "center", marginBottom: "3vh", flex: 1 }}>
									<TextField
										value={loginDetails[index].value}
										error={detail.error.length > 0}
										helperText={detail.error}
										rows={5}
										placeholder={detail.label}
										variant="outlined"
										type={detail.label === "Password" ? "password" : "text"}
										style={{ width: "80%", height: "fit-content" }}
										onChange={(e) => {
											let returnObj = loginDetails.slice();
											returnObj[index] = {
												...returnObj[index],
												value: e.target.value,
											};
											setLoginDetails(returnObj);
										}}
									/>
								</div>
							);
						})}
					</div>
					{loginError && (
						<div style={{ textAlign: "center", width: "100%", color: "red", fontWeight: "bold", fontSize: "1.75vh" }}>
							<i>{loginError}</i>
						</div>
					)}
					<div className="flex-column" style={{ alignItems: "center" }}>
						<div
							style={{
								border: `1px solid ${theme.contrastText}`,
								padding: "10px 15px 10px 15px",
								borderRadius: 5,
								cursor: "pointer",
								marginTop: "5vh",
							}}
							onClick={onLoginClick}
						>
							Login
						</div>
					</div>
				</div>
			</Fade>
		);
	};

	return (
		<>
			<Modal
				open={loginModal}
				onClose={() => {
					setLoginModal(false);
				}}
			>
				{loginModalBody(loginModal)}
			</Modal>
			<Modal
				open={contactModal}
				onClose={() => {
					setContactModal(false);
				}}
			>
				{contactUsModal(contactModal)}
			</Modal>
			<Modal
				open={logoutModal}
				onClose={() => {
					setLogoutModal(false);
				}}
			>
				{logoutModalBody(logoutModal)}
			</Modal>
			{/*TODO turn navbar into component */}
			<div
				className="flex-row"
				style={{
					alignItems: "center",
					width: "100vw",
					//backgroundColor: theme.primary,
					//boxShadow: `2px 5px 20px ${theme.shadow}`,
					padding: "2vh 5vh 1vh 3vh",
					zIndex: 100,
				}}
			>
				{currentPagePath === "/" ? (
					<div className="reveal-from-left" style={{ flex: 1 }}>
						<Typography
							style={{
								padding: "1vh 2vh 1vh 2vh",
								border: `1px solid ${theme.red}`,
								borderRadius: 5,
								cursor: "pointer",
								fontSize: "1.75vh",
								fontWeight: "600",
								color: theme.contrastText,
								backgroundColor: "transparent",
								width: "fit-content",
							}}
							onClick={() => {
								setContactModal(true);
							}}
						>
							{"Contact Us"}
						</Typography>
					</div>
				) : (
					<div style={{ flex: 1 }} />
				)}

				<div className="flex-row" style={{ flex: 1 }}>
					{tabs.map((tab, ind) => (
						<div
							key={ind}
							className="flex-column"
							style={{ alignItems: "center", cursor: "pointer" }}
							onClick={() => {
								history.push(paths[ind]);
							}}
						>
							<Typography
								className="flex-column"
								style={{
									alignItems: "center",
									marginLeft: "2.5vw",
									marginRight: "2.5vw",
									fontSize: "1.8vh",
									fontWeight: "600",
									width: "100%",
									fontWeight: currentPagePath === paths[ind] ? "bold" : "normal",
									color: currentPagePath === paths[ind] ? theme.contrastText : "#808080",
								}}
							>
								{tab}
							</Typography>
							{currentPagePath === paths[ind] && (
								<div
									className="reveal-from-center"
									style={{
										width: "55%",
										backgroundColor: theme.red,
										height: 2,
										opacity: 0.6,
										marginTop: 10,
										boxShadow: `4px 4px 10px black`,
									}}
								/>
							)}
						</div>
					))}
				</div>
				<div className="flex-column" style={{ flex: 1, alignItems: "flex-end" }}>
					<Typography
						style={{
							padding: "1vh 2vh 1vh 2vh",
							border: `1px solid ${authenticated ? theme.red : theme.contrastText}`,
							borderRadius: 5,
							cursor: "pointer",
							fontSize: "1.75vh",
							fontWeight: "600",
							color: theme.contrastText,
							backgroundColor: authenticated ? theme.red : theme.secondaryDark,
							width: "fit-content",
							boxShadow: `5px 5px 10px ${theme.shadow}`,
						}}
						onClick={() => {
							authenticated ? logout({ returnTo: window.location.origin }) : loginWithRedirect()
							localStorage.removeItem("isAdmin");
							// authenticated ? setLogoutModal(true) : setLoginModal(true);
						}}
					>
						{authenticated ? "Logout" : "Login"}
					</Typography>
				</div>
			</div>
			<main className="site-content">{children}</main>

			{/*<Footer />*/}
		</>
	);
};

export default withRouter(LayoutDefault);
