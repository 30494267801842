import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import generalTheme from "../../utils/theme";

const propTypes = {
	...SectionTilesProps.types,
};

const defaultProps = {
	...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	section,
	ref,
	...props
}) => {
	const outerClasses = classNames(
		"features-tiles section",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames(
		"features-tiles-inner section-inner pt-0",
		topDivider && "has-top-divider",
		bottomDivider && "has-bottom-divider"
	);

	const tilesClasses = classNames("tiles-wrap center-content", pushLeft && "push-left");

	const sectionHeader = {
		title: "Optimize Enterprise Technology Investments",
	};
	const techInsight = {
		title: "Technology Insights",
		paragraph:
			"As firms navigate through the implementation of their Digital Transformation strategy, TechFinIQ serves as a partner in providing valuable insight and services that results in deployment of investment in the most optimal economic manner. Organizations will execute customized initiatives and action plans generated through our RoIT proprietary application, framework and consultation.",
	};
	const industryInsight = {
		title: "Industry Insights",
		paragraph: "Empty for now",
	};

	const aboutUs = {
		title: "Features",
	};

	var chartLink = (
		<a
			href="https://www.flexera.com/blog/elo/2020-state-of-tech-spend-it-spending-benchmarks-and-trends/"
			style={{ textDecorationLine: "underline", color: generalTheme.palette.common.red, textDecorationColor: generalTheme.palette.common.red }}
		>
			{" "}
			Flexera IT Spending Benchmarks Survey
		</a>
	);

	return (
		<div {...props} className={outerClasses} ref={ref}>
			{
				section === "companySummary" ? (
					<div className="container">
						<div className={innerClasses} style={{ paddingBottom: 0, paddingTop: "8vh", borderTop: "1px solid #33363A" }}>
							<SectionHeader data={sectionHeader} className="center-content" />
							<div className="container-xs">
									<p className="center-content" style={{ marginTop: "5px"}}>
									TechFinIQ enables Technology and Finance functions to identify targeted areas of cost realignment, redeployment and reduction utilizing our proprietary RoIT methodology and application. 
									</p>

									<p className="center-content" style={{ marginTop: "5px"}}>
									RoIT can generate up to 20% cost efficiencies while mitigating risk to the enterprise. We provide options that enable firms to select optimal solutions that align to firm Technology strategy.
									</p>

									<p className="center-content" style={{ marginTop: "5px"}}>
									We serve as a partner in providing valuable guidance and insight allowing leadership to execute and optimize firm Digital Platform Strategy through “intelligent data" driven analytics.
									</p>
								</div>

						</div>

						<div style={{ paddingBottom: "50px" }} />
					</div>
			) : section === "techInsight" ? (
				<div className="container testimonial">
					<div className={innerClasses} style={{ paddingBottom: 0, paddingTop: "8vh", borderTop: "1px solid #33363A" }}>
						<SectionHeader data={techInsight} className="center-content" />
						<div className="container-xs">
							<p className="container-xs" style={{ marginTop: "5px", display:"inline" }}>
								This chart from the {" "}{chartLink}{" "}highlights the biggest issues participants face in the industry. TechFinIQ's solutions help technology leaders to identify, 
								track and address these issues, allowing them to provide more control, limit their risks and reduce costs
							</p>

							<p className="center-content" style={{ marginTop: "50px"}}>
							Below you can find some comments from analysts and industry leaders about the need for optimizing IT spend
							</p>
						</div>
								

						<div className="tiles-wrap" style={{ marginBottom: "10vh", marginTop: "5vh" }}>
							<div className="tiles-item reveal-from-right" data-reveal-delay="200">
								<div className="tiles-item-inner">
									<div className="testimonial-item-content">
										<p className="text-sm mb-0">
											"In the 25 years that Gartner has been forecasting IT spending, never has there been a market with this
											much volatility. "
										</p>
									</div>
									<div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
										<span className="testimonial-item-name text-color-high">John-David Lovelock | Gartner</span>
										<span className="text-color-low"> / </span>
										<span className="testimonial-item-link">
											<a
												href="https://www.gartner.com/en/newsroom/press-releases/2020-10-20-gartner-says-worldwide-it-spending-to-grow-4-percent-in-2021"
												style={{ color: generalTheme.palette.common.red }}
											>
												Source
											</a>
										</span>
									</div>
								</div>
							</div>
							<div className="tiles-item reveal-from-bottom">
								<div className="tiles-item-inner">
									<div className="testimonial-item-content">
										<p className="text-sm mb-0">
											"[...] the CIO and CFO must find ways to limit the risk of technology investments. [...] Working together,
											they can find ways to retain agility throughout the budget year to alter plans as the company’s changing
											needs require."
										</p>
									</div>
									<div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
										<span className="testimonial-item-name text-color-high">Mark Schwartz | AWS </span>
										<span className="text-color-low"> / </span>
										<span className="testimonial-item-link">
											<a
												href="https://aws.amazon.com/blogs/enterprise-strategy/the-cio-and-cfo-conversation/"
												style={{ color: generalTheme.palette.common.red }}
											>
												Source
											</a>
										</span>
									</div>
								</div>
							</div>
							<div className="tiles-item reveal-from-right" data-reveal-delay="200">
								<div className="tiles-item-inner">
									<div className="testimonial-item-content">
										<p className="text-sm mb-0">
											"CIOs must establish a culture of proactive IT cost optimization to strengthen their position with their
											CEO and stakeholders."
										</p>
									</div>
									<div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
										<span className="testimonial-item-name text-color-high">Laurence Goasduff | Gartner</span>
										<span className="text-color-low"> / </span>
										<span className="testimonial-item-link">
											<a
												href="https://www.gartner.com/smarterwithgartner/how-cios-can-optimize-it-costs/"
												style={{ color: generalTheme.palette.common.red }}
											>
												Source
											</a>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : section === "aboutUs" ? (
				<div className="container">
					<div className={innerClasses} style={{ paddingBottom: "8vh", paddingTop: "8vh", borderTop: "1px solid #33363A" }}>
						<SectionHeader data={aboutUs} className="center-content" />
						<div className={tilesClasses}>
							<div className="tiles-item reveal-from-bottom">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16" style={{ backgroundColor: generalTheme.palette.common.red }}>
											<i className="fas fa-chart-line fa-2x" style={{ padding: "15px", color: "white" }}></i>
										</div>
									</div>

									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Data Driven</h4>
										<p className="m-0 text-sm">Our predictive models provide optimal real time solutions</p>
									</div>
								</div>
							</div>

							<div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16" style={{ backgroundColor: generalTheme.palette.common.red }}>
											<i className="fas fa-check fa-2x" style={{ padding: "15px", color: "white" }}></i>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Easy to Use</h4>
										<p className="m-0 text-sm">
											RoIT (Return on Information Technology) is an intuitive web based application that is flexible, insightful
											and provides a future view of the impact of decisions that are made today. Organizations will have ability
											to generate annual rolling budgets and forecasts
										</p>
									</div>
								</div>
							</div>

							<div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16" style={{ backgroundColor: generalTheme.palette.common.red }}>
											<i className="fas fa-globe fa-2x" style={{ padding: "15px", color: "white" }}></i>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Diverse Customer Utility</h4>
										<p className="m-0 text-sm">
											Our application and methodology will generate significant benefits to organizations of any size
										</p>
									</div>
								</div>
							</div>

							<div className="tiles-item reveal-from-bottom">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16" style={{ backgroundColor: generalTheme.palette.common.red }}>
											<i className="far fa-chart-bar fa-2x" style={{ padding: "15px", color: "white" }}></i>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Flexibility</h4>
										<p className="m-0 text-sm">
											Our "what if" scenario models enable leadership to gain real time transparency and operational impact of
											changes
										</p>
									</div>
								</div>
							</div>

							<div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16" style={{ backgroundColor: generalTheme.palette.common.red }}>
											<i className="fas fa-comments fa-2x" style={{ padding: "15px", color: "white" }}></i>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Consultative Strategies</h4>
										<p className="m-0 text-sm">
											We collaborate with leadership teams to identify risks, opportunities and create actionable result driven
											plans
										</p>
									</div>
								</div>
							</div>

							{/*
						<div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16" style={{backgroundColor:generalTheme.palette.primary.light}}>
										<Image
											src={require("./../../assets/images/feature-tile-icon-06.svg")}
											alt="Features tile icon 06"
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">Robust Workflow</h4>
									<p className="m-0 text-sm">
										Our leadership will be working with you every step of the way
									</p>
								</div>
							</div>
						</div>
						*/}
						</div>
					</div>
				</div>
			) : (
				<div className="container">
					<div className={innerClasses} style={{ paddingBottom: 0, paddingTop: "8vh", borderTop: "1px solid #33363A" }}>
						<SectionHeader data={industryInsight} className="center-content" />
					</div>
				</div>
			)}
		</div>
	);
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
