import React, { useEffect, useState } from "react";
import generalTheme from "../../utils/theme";
import axios from "axios";
import {
	prettifyOutputNames,
	prettifyTargetStrikeInputName,
	numberWithComma,
	determineColor,
	typePrettify,
	getTypeForInputName,
} from "../../utils/Functions";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./Comparison.css";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import RemoveIcon from "@material-ui/icons/Remove";
import flatten from "flat";
import Tooltip from "@material-ui/core/Tooltip";

async function getDatasetForComparison(dsName) {
	const rawOutputs = await axios.get(`/company/dataset/${dsName}/outputs`)
	const outputs = prettifyOutputNames(rawOutputs.data);

	const rawInputs = await axios.get(`/company/dataset/${dsName}`)
	const flattenedData = flatten(rawInputs.data);
	const inputs = [];
	for (const [key, value] of Object.entries(flattenedData)) {
		if (key.startsWith("financial_info") || key.startsWith("operational_metrics") || key.startsWith("balance_sheet")) {
			inputs.push({
				fullPath: key,
				value,
			});
		}
	}

	return {name: dsName, inputs, outputs}
}

const Comparison = (props) => {
	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];
	const datasetSelectedToCompare = props.location.state?.dataSet

	const [comparisonType, setComparisonType] = useState("Outputs")
	const [datasetsSelected, setDatasetsSelected] = useState([datasetSelectedToCompare, undefined])
	const [fetchedFullDatasets, setFetchedFullDatasets] = useState({})
	const [listedDatasets, setListedDatasets] = useState([])

	const authHeader = axios.defaults.headers.common["Authorization"]

	// Fetch a list of all available datasets.
	useEffect(() => {
		if(authHeader === undefined) {return}
		async function listDatasets() {
			// Load the list of dataset names.
			let res
			try {
				res = await axios.get(`/company/dataset`)
			} catch (e) {
				console.log(e)
			}
			const dataSets = res.data ?? []
			setListedDatasets(dataSets)
		}
		listDatasets()
	}, [authHeader]);

	// Fetch any datasets if they have been selected and we have not fetched them earlier.
	useEffect(() => {
		if(authHeader === undefined) {return}

		const filteredDatasets = Array.from(new Set(['Original', ...datasetsSelected])).filter(v => v !== undefined)
		const datasetsToFetch = []
		for (const selectedDatasetName of filteredDatasets) {
			if (!(selectedDatasetName in fetchedFullDatasets)) {
				datasetsToFetch.push(selectedDatasetName)
			}
		}
		
		if (datasetsToFetch.length === 0) {
			return
		}
		async function fetchDatasets() {
			const fetchedDatasets = await Promise.all(datasetsToFetch.map(dsName => getDatasetForComparison(dsName)))
			setFetchedFullDatasets(original => {
				const originalCopy = {...original}
				for (const fetchedDs of fetchedDatasets) {
					originalCopy[fetchedDs.name] = fetchedDs
				}
				return originalCopy
			})
		}
		fetchDatasets()
	}, [authHeader, datasetsSelected, fetchedFullDatasets])
	

	const fullDatasetsSelectedSorted = []
	fullDatasetsSelectedSorted.push(fetchedFullDatasets['Original'])
	for (const selectedDs of datasetsSelected) {
		fullDatasetsSelectedSorted.push(fetchedFullDatasets[selectedDs])
	}

	return (
		<div className="flex-column" style={{ width: "100vw", height: "91vh", padding: "5vh 10vh 5vh 10vh", color: theme.contrastText }}>
			<div style={{ fontSize: "4vh", marginBottom: 10 }}>Data Set Comparison</div>
			<i style={{ marginBottom: "5vh", fontSize: "1.75vh", color: "#d3d3d3" }}>
				Use this page to compare input and output values for two selected data sets.
			</i>
			<div className="flex-row" style={{ alignItems: "center" }}>
				<ComparisonTypeSelector comparisonType={comparisonType} setComparisonType={setComparisonType} />
				<div className="flex-column" style={{ flex: 1 }}>
					<div className="original-label " style={{ fontSize: "2vh" }}>
						Original
					</div>
				</div>
				{datasetsSelected.map((ds, i) => (
					<DatasetSelectorDropdown
						key={i}
						curValue={ds}
						datasets={listedDatasets.map(d => d.name)}
						onChange={newVal => {
							setDatasetsSelected(prev => {
								const newDatasets = [...prev]
								newDatasets[i] = newVal
								return newDatasets
							})
						}}
					/>
				))}
			</div>
			<div className="flex-column" style={{ height: "70vh", overflowY: "scroll", marginTop: "2vh", position: "relative" }}>
				<ListComparison comparisonType={comparisonType} datasets={fullDatasetsSelectedSorted} />
			</div>
		</div>
	);
};

const ComparisonTypeSelector = ({comparisonType, setComparisonType}) => {
	return (
		<div className="flex-row" style={{ flex: 1, alignItems: "center" }}>
			<FormControl className="reveal-from-top" variant="outlined" style={{ width: "65%" }}>
				<Select
					value={comparisonType}
					onChange={(e) => {
						setComparisonType(e.target.value);
					}}
				>
					<MenuItem value={"Outputs"}>Outputs</MenuItem>
					<MenuItem value={"Inputs"}>Inputs</MenuItem>
				</Select>
			</FormControl>
		</div>
	)
}

const DatasetSelectorDropdown = ({datasets, onChange, curValue}) => {
	return (
		<div className="flex-column" style={{ flex: 1 }}>
			<FormControl className="reveal-from-top" variant="outlined" style={{ width: "65%" }}>
				<Select
					displayEmpty
					value={curValue}
					renderValue={(val) => {
						if (val === undefined) {
							return "N/A"
						}
						return val
					}}
					onChange={(e) => {
						onChange(e.target.value)
					}}
				>
					{datasets?.map((ds) => (
						<MenuItem key={ds} value={ds}>{ds}</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	)
}

const ListComparison = ({datasets, comparisonType}) => {
	const original = datasets.find(d => d?.name === 'Original')
	if (original === undefined) {
		return (
			<div style={{ position: "absolute", top: 0, left: 0, marginTop: "10vh", width: "100%", textAlign: "center" }}>
				Original Data Set not found. Please create an original dataset before using the comparison view.
			</div>
		)
	}
	const otherDatasets = datasets.filter(d => d?.name !== 'Original')

	if (comparisonType === 'Inputs') {
		return (
			<>
			{original.inputs.map((input, inputIdx) => {
				const label = prettifyTargetStrikeInputName(input.fullPath)
				const type = getTypeForInputName(input.fullPath)
				return (
					<div
						className="flex-row"
						style={{ alignItems: "center", fontSize: "1.8vh", padding: "1.75vh", borderBottom: "1px solid #808080" }}
					>
						<div style={{ flex: 1, paddingRight: "2vw" }}>{label}</div>
						{datasets.map((ds, dsIndex) => {
							if (ds === undefined) {
								return (
									<div className="flex-column" style={{ flex: 1, textAlign: "center" }}>
										---
									</div>
								)
							}

							const curInput = ds.inputs[inputIdx]
							const nextDs = datasets[dsIndex + 1]
							let change = 0
							if (nextDs !== undefined) {
								const nextDsInput = nextDs.inputs[inputIdx]
								change = parseFloat(nextDsInput.value).toFixed(2) - parseFloat(input.value).toFixed(2)
							}

							const curVal = parseFloat(curInput.value).toFixed(2)
							const originalVal =  parseFloat(input.value).toFixed(2)
							if (label === 'Medical Benefits Spend') {
								console.log(`curVal (${ds.name})`, curVal, 'originalVal', originalVal)
							}

							return (
								<div className="flex-row" style={{ alignItems: "center", flex: 1 }}>
									<RenderInputValue input={curInput} change={originalVal - curVal === 0} />
									{nextDs === undefined
										? <div style={{ flex: 1 }} />
										: <RenderChange change={change} type={type} />}
								</div>
							)
						})}
					</div>
				)
			})}
		</>
		)
	}

	return (
		<>
			{original.outputs.map((output, outputIdx) => {
				const label = output.prettyName
				return (
					<div
						className="flex-row"
						style={{ alignItems: "center", fontSize: "1.8vh", padding: "1.75vh", borderBottom: "1px solid #808080" }}
					>
						<div style={{ flex: 1, paddingRight: "2vw" }}>{label}</div>
						{datasets.map((ds, dsIndex) => {
							if (ds === undefined) {
								return (
									<div className="flex-column" style={{ flex: 1, textAlign: "center" }}>
										---
									</div>
								)
							}
							
							const curOutput = ds.outputs[outputIdx]

							const nextDs = datasets[dsIndex + 1]
							let change = 0
							if (nextDs !== undefined) {
								const nextOutput = nextDs.outputs[outputIdx]
								change = nextOutput.value - output.value
							}

							return (
								<div className="flex-row" style={{ alignItems: "center", flex: 1 }}>
									<RenderOutputValue {...curOutput} />
									{nextDs === undefined
										? <div style={{ flex: 1 }} />
										: <RenderChange change={change} type={output.type} />}
								</div>
							)
						})}
					</div>
				)
			})}
		</>
	)
};

const RenderOutputValue = (props) => {
	return (
		<Tooltip title={numberWithComma(parseFloat(props.value).toFixed(props.type === "ratio" ? 4 : props.type === "dollars" ? 2 : 0))}>
			<div style={{ flex: 1, textAlign: "center", color: determineColor(props.target) }}>
				{typePrettify(props.type, parseFloat(props.value))}
			</div>
		</Tooltip>
	);
};

const RenderInputValue = ({input, change}) => {
	let type = getTypeForInputName(input.fullPath);
	return (
		<Tooltip title={numberWithComma(parseFloat(input.value).toFixed(type === "ratio" ? 4 : type === "dollars" ? 2 : 0))}>
			<div style={{ flex: 1, textAlign: "center", color: change ? "#808080" : "inherit" }}>
				{typePrettify(type, parseFloat(input.value))}
			</div>
		</Tooltip>
	);
};

const RenderChange = ({change, type}) => {
	return (
		<div className="flex-column" style={{ marginLeft: "1vw", flex: 1, textAlign: "center", alignItems: "center" }}>
			<div className="flex-row" style={{ alignItems: "center" }}>
				<LongArrowIcon color={change === 0 ? "#808080" : "inherit"} />
			</div>
			<div
				style={{
					lineHeight: "1.5vh",
					fontSize: "1.4vh",
					textAlign: "center",
					color: change === 0 ? "#808080" : "inherit",
				}}
			>{`${change >= 0 ? "+" : "-"}${typePrettify(type, parseFloat(Math.abs(change)).toFixed(type === "ratio" ? 4 : 2))}`}</div>
		</div>
	);
};

const LongArrowIcon = ({ color }) => (
	<div className="flex-row long-arrow" style={{ alignItems: "center", color: color }}>
		<RemoveIcon className="move-right-4" />
		<RemoveIcon className="move-right-3" />
		<RemoveIcon className="move-right-2" />
		<RemoveIcon className="move-right" />
		<ArrowRightAltIcon />
	</div>
);

export default Comparison;
