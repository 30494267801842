import axios from "axios";
//import { data } from "./dummyDataSetData";

export const sendEmail = (emailData) => {
	axios
		.post(`/contact`, emailData)
		.then((res) => {
			console.log(res);
			console.log("Email successfully sent");
		})
		.catch((err) => {
			console.log(err);
		});
};

export const testRequest = () => {
	axios
		.get(`/`)
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			console.log(err);
		});
};


export const register = (registerDetails) => {
	axios
		.post(`/auth/register`, registerDetails)
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			console.log(err);
		});
};

export const testAuth = () => {
	axios
		.get(`/auth/test_locked`)
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			console.log(err);
		});
};

export const createDataSet = (data) => {
	axios
		.post(`/company/dataset`, data)
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			console.log(err);
		});
};


