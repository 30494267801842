import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import generalTheme from "../../utils/theme";
import { centeredPlugin } from "@brainhubeu/react-carousel";

const propTypes = {
	...SectionSplitProps.types,
};

const defaultProps = {
	...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	ref,
	...props
}) => {
	const outerClasses = classNames(
		"features-split section",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames("features-split-inner section-inner", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

	const splitClasses = classNames("split-wrap", invertMobile && "invert-mobile", invertDesktop && "invert-desktop", alignTop && "align-top");

	const sectionHeader = {
		title: "Our Solution",
		paragraph: "We provide a customized plan specifically catered to your organization. Our dashboard approach identifies target strikes within operations that will allow your firm to deploy limited funding effectively, in order to execute technology strategy in the most optimal manner while minimizing risk. In addition, the RoIT software generates real time modeling capability creating forward looking views of rolling forecasts, budgets and scenario analysis.",
	};

	return (
		<section {...props} className={outerClasses} ref={ref}>
			<div className="container">
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className="center-content" />
					<div className={splitClasses}>
						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{ color: generalTheme.palette.common.red }}>Step One</div>
								<h3 className="mt-0 mb-12">Interview</h3>
								<p className="m-0">
								Discuss strategic objectives with Technology and Finance leaders to define optimization objectives
								</p>
							</div>
							<div
								className={classNames(
									"split-item-image center-content-mobile reveal-from-bottom",
									imageFill && "split-item-image-fill"
								)}
								data-reveal-container=".split-item"
							>
								<Image
									src={require("./../../assets/images/interviewPic.jpg")}
									alt="Features tile icon 01"
									width={528}
									height={396}
								/>
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{ color: generalTheme.palette.common.red }}>Step Two</div>
								<h3 className="mt-0 mb-12">Data Gathering</h3>
								<p className="m-0">
									Obtain information from the appropriate functional teams
								</p>
							</div>
							<div
								className={classNames(
									"split-item-image center-content-mobile reveal-from-bottom",
									imageFill && "split-item-image-fill"
								)}
								data-reveal-container=".split-item"
							>
								<Image
									src={require("./../../assets/images/data-gathering.jpg")}
									alt="Features tile icon 01"
									width={528}
									height={396}
								/>
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{ color: generalTheme.palette.common.red }}>Step Three</div>
								<h3 className="mt-0 mb-12">Analyze</h3>
								<p className="m-0">
									Generate "Intelligent Data" utilizing our proprietary software to generate actionable metrics and compare benchmarks
								</p>
							</div>
							<div
								className={classNames(
									"split-item-image center-content-mobile reveal-from-bottom",
									imageFill && "split-item-image-fill"
								)}
								data-reveal-container=".split-item"
							>
								<Image
									src={require("./../../assets/images/analyze.jpeg")}
									alt="Features tile icon 01"
									width={528}
									height={396}
								/>
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{ color: generalTheme.palette.common.red }}>Step Four</div>
								<h3 className="mt-0 mb-12">Recommend</h3>
								<p className="m-0">
								A Cockpit and Target Strike report generates relational metrics and benchmark performance comparisons. This provides possible areas of opportunity for optimization
								</p>
							</div>
							<div
								className={classNames(
									"split-item-image center-content-mobile reveal-from-bottom",
									imageFill && "split-item-image-fill"
								)}
								data-reveal-container=".split-item"
							>
								<Image
									src={require("./../../assets/images/recommend.jpg")}
									alt="Features tile icon 01"
									width={528}
									height={396}
								/>
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{ color: generalTheme.palette.common.red }}>Step Five</div>
								<h3 className="mt-0 mb-12">Create Action Plans</h3>
								<p className="m-0">
								Clear, concise action plans are prepared that identify optimization target areas. This valuable output is available 
								for your management to implement, or you can choose to engage TechFinIQ consulting to assist with the execution of the strategy
								</p>
							</div>
							<div
								className={classNames(
									"split-item-image center-content-mobile reveal-from-bottom",
									imageFill && "split-item-image-fill"
								)}
								data-reveal-container=".split-item"
							>
								<Image
									src={require("./../../assets/images/action-plan.jpg")}
									alt="Features tile icon 01"
									width={528}
									height={396}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
