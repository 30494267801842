import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "@material-ui/core/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import generalTheme from "../../utils/theme";
import { Typography } from "@material-ui/core";

const propTypes = {
	...SectionProps.types,
};

const defaultProps = {
	...SectionProps.defaults,
};

const Hero = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, openContactModal, ...props }) => {
	const [videoModalActive, setVideomodalactive] = useState(false);

	const openModal = (e) => {
		e.preventDefault();
		setVideomodalactive(true);
	};

	const closeModal = (e) => {
		e.preventDefault();
		setVideomodalactive(false);
	};

	const outerClasses = classNames(
		"hero section center-content",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames("hero-inner section-inner", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

	return (
		<section {...props} className={outerClasses}>
			<div className="container-sm">
				<div className={innerClasses}>
					<div className="hero-content">
						<div style={{ marginBottom: 10 }}>
							<h1
								className="mt-0 mb-16 reveal-from-bottom"
								data-reveal-delay="200"
								style={{ color: generalTheme.palette.common.white, fontSize: 70, paddingBottom: "50px", display: "inline" }}
							>
								//
							</h1>
							<h1
								className="mt-0 mb-16 reveal-from-bottom"
								data-reveal-delay="200"
								style={{
									color: generalTheme.palette.common.red,
									fontSize: 70,
									paddingBottom: "50px",
									display: "inline",
									marginLeft: "-5px",
								}}
							>
								/
							</h1>
						</div>
						<h1
							className="mt-0 mb-16 reveal-from-bottom"
							data-reveal-delay="200"
							style={{ color: generalTheme.palette.common.white, display: "inline", fontSize: 70 }}
						>
							TechFin
						</h1>
						<h1
							className="mt-0 mb-16 reveal-from-bottom"
							data-reveal-delay="200"
							style={{ color: generalTheme.palette.common.red, display: "inline", fontSize: 70 }}
						>
							IQ
						</h1>

						<div className="container-xs">
							<p
								className="m-0 mb-32 reveal-from-bottom"
								data-reveal-delay="400"
								style={{ fontSize: "2.5vh", paddingTop: "1vh", fontStyle: "italic" }}
							>
								{"Intelligent Data"}
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
