import { createMuiTheme } from "@material-ui/core/styles";

const generalTheme = createMuiTheme({
	palette: {
		common: {
			black: "#000",
			white: "#fff",
			red: "#b20000",
			darkRed: "#6f0808",
		},
		background: {
			paper: "rgba(255, 255, 255, 1)",
			default: "#fafafa",
			dark: "#1c313a",
		},
		primary: {
			light: "#d48c67",
			main: "#f88a51",
			dark: "#943200",
			contrastText: "#fff",
		},
		secondary: {
			light: "#ffb199",
			main: "#ff9e80",
			dark: "rgb(22,25,41)",
			contrastText: "#fff",
		},
		error: {
			light: "#ffda99",
			main: "#a15757",
			dark: "#b29259",
			contrastText: "#fff",
		},
		text: {
			primary: "rgba(0, 0, 0, 0.87)",
			secondary: "rgba(0, 0, 0, 0.54)",
			disabled: "rgba(0, 0, 0, 0.38)",
			hint: "rgba(0, 0, 0, 0.38)",
		},
		dark: {
			primary: "rgb(43, 47, 51)",
			secondary: "rgb(43, 47, 51)",
			secondaryDark: "rgb(21,23,25)",
			contrastText: "#ffffff",
			shadow: "#000000",
			red: "#E71D36",
			green: "#7DBE74",
		},
		light: {
			primary: "#c7fcfc",
			primaryDark: "#c7fcfc",
			secondary: "#ffffff",
			secondaryDark: "#e8ffff",
			contrastText: "#000000",
			shadow: "#d3d3d3",
			red: "#E71D36",
			green: "#7DBE74",
		},
	},
	typography: {
		fontSize: 12,
		fontFamily: ["BlinkMacSystemFont"].join(","),
	},
});

export default generalTheme;
