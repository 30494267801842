import React, { Fragment } from "react";
import * as PL from "../../../utils/Templates/P&LTemplate";
import TextField from "@material-ui/core/TextField";
import {
	set,
	capitalize,
	objToTree,
	prettifyInputName,
	getTypeForInputName,
	DollarFormatCustom,
	RatioFormatCustom,
	IntFormatCustom,
	strip,
} from "../../../utils/Functions";
import Form from "./Form";
import generalTheme from "../../../utils/theme";
import Typography from "@material-ui/core/Typography";

const themeVariant = "dark";
const theme = generalTheme.palette[themeVariant];

class PLSheet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			content: "",
			tree: objToTree(PL.JSONtemplate, "financial_info", null), // turns json for P&L from template into a tree obj. Refer to utils/Functions.js for the structure of a tree,
			autofill: false,
		};
		this.handleNext = this.handleNext.bind(this);
		this.generateFormContent = this.generateFormContent.bind(this);
		this.storeFormValues = this.storeFormValues.bind(this);
		this.split = this.split.bind(this);
		this.renderTextField = this.renderTextField.bind(this);
	}

	//Function called to store the all textfield inputs into an object
	storeFormValues = (obj, tree) => {
		//Recursive base case.
		//If node has no children then it is leaf node, I.E it is a textfield, so grab its value and store it in the obj

		if (!tree.children) {
			if (this.state.autofill) {
				set(obj, tree.fullPath, tree.autofillVal);
			} else {
				set(obj, tree.fullPath, strip(this[tree.fullPath].value, getTypeForInputName(tree.fullPath)));
			}
			return;
		}

		//Not a leaf node, call function on children
		tree.children.map((child) => {
			this.storeFormValues(obj, child);
		});
	};

	//Function to calculate errors (if a textfield is left blank)
	calculateErrors = (tree) => {
		//Recursive base case.
		//Leaf node reached, get its value and if it is "", then it is blank. Set its error and send up its value to function calling parent
		if (!tree.children) {
			tree.errors = this[tree.fullPath].value === "" ? 1 : 0;
			return this[tree.fullPath].value === "" ? 1 : 0;
		}

		//The error of a parent node is the sum of the errors of its children.
		let errorSum = 0;
		tree.children.map((children) => {
			errorSum += this.calculateErrors(children);
		});
		tree.errors = errorSum;

		//Update tree state with possible errors
		this.setState({ tree: tree });
		return errorSum;
	};

	//Function called when user clicks on next button
	handleNext = () => {
		let formObj = {};

		// Call calculate error. If the tree has any errors,
		// prevent user from going to the next step(commented out for dev purposes currently)

		this.calculateErrors(this.state.tree);
		if (this.state.tree.errors > 0 && !this.state.autofill) return;

		// If every single textfield is filled in, then call storeFormValues to state values in formObj to send to parent
		this.storeFormValues(formObj, this.state.tree);

		// Call submitFormDataToParent to pass formObj to parent and go to next step
		setTimeout(() => {
			this.props.submitFormDataToParent(formObj);
		}, 100);
	};

	calculateCircleColor = (errors) => {
		if (errors === -1) return theme.primaryDark;
		else if (errors === 0) return "green";
		else return "red";
	};

	renderTextField = (current) => {
		let inputProps = {};
		let type = getTypeForInputName(current.fullPath);

		if (type === "dollars") {
			inputProps["inputComponent"] = DollarFormatCustom;
		} else if (type === "ratio") {
			inputProps["inputComponent"] = RatioFormatCustom;
		} else {
			inputProps["inputComponent"] = IntFormatCustom;
		}
		return (
			<TextField
				InputProps={inputProps}
				variant="outlined"
				inputRef={(input) => (this[current.fullPath] = input)}
				label={prettifyInputName(current.path)}
				style={{ margin: "1vh" }}
				error={current.errors > 0}
				helperText={current.errors > 0 ? "Cannot be blank." : ""}
				onKeyDown={(e) => {
					if (e.keyCode === 9 && current.fullPath === "financial_info.nonpersonnel.miscellaneous.software_amortization") e.preventDefault();
				}}
			/>
		);
	};

	split = (parent, depth, rows, border) => {
		let depthArr = Array.from(Array(depth));
		let rowArr = Array.from(Array(rows));
		return (
			<div className="flex-column">
				<Typography
					// create a ref using unique fullPath string
					ref={(input) => (this[parent.fullPath] = input)}
					className="flex-row"
					style={{
						alignItems: "center",
						fontWeight: "bold",
						fontSize: "1.75vh",
						marginTop: "1vh",
						marginLeft: "1vw",
						height: "3vh",
						marginBottom: "2vh",
					}}
				>
					{depthArr.map((d) => {
						return <div className="empty-circle" />;
					})}
					<div
						className="filled-circle"
						style={{
							backgroundColor: this.calculateCircleColor(parent.errors),
							border: `1px solid ${this.calculateCircleColor(parent.errors)}`,
						}}
					/>

					{capitalize(parent.path)}
				</Typography>
				{parent.children.map(
					(c, index) =>
						index % rows === 0 && (
							<div className="flex-column" style={{ borderRight: border ? "1px solid #d3d3d3" : "none" }}>
								{rowArr.map((row, ind) => index + ind < parent.children.length && this.renderTextField(parent.children[index + ind]))}
							</div>
						)
				)}
			</div>
		);
	};

	// Function to generate the form itself
	generateFormContent = (tree) => {
		return tree.children.map((depthOneChild) => (
			<div style={{ color: theme.contrastText }}>
				<Typography className="flex-row" style={{ alignItems: "center", fontWeight: "bold", fontSize: "1.75vh" }}>
					<div
						className="filled-circle"
						style={{
							backgroundColor: this.calculateCircleColor(depthOneChild.errors),
							border: `1px solid ${this.calculateCircleColor(depthOneChild.errors)}`,
						}}
					/>
					{prettifyInputName(depthOneChild.path)}
				</Typography>
				<div className="form-content-parent" style={{ backgroundColor: theme.primary }}>
					{depthOneChild.children.map((depthTwoChild) => (
						<div ref={(input) => (this[depthTwoChild.fullPath] = input)}>
							<Typography
								className="flex-row"
								style={{ alignItems: "center", fontWeight: "bold", fontSize: "1.75vh", marginBottom: "1vh" }}
							>
								<div className="empty-circle" />
								<div
									className="filled-circle"
									style={{
										backgroundColor: this.calculateCircleColor(depthTwoChild.errors),
										border: `1px solid ${this.calculateCircleColor(depthTwoChild.errors)}`,
									}}
								/>
								{prettifyInputName(depthTwoChild.path)}
							</Typography>
							<div
								style={{ padding: "1vh", marginBottom: "1vh", columnCount: 2 }}
								className={`${depthTwoChild.children?.[0].children?.[0].children ? "flex-column" : "flex-row"}`}
							>
								{depthTwoChild?.children?.map((depthThreeChild, index) =>
									depthThreeChild.children ? (
										<div className="flex-column" style={{ flex: 1 }}>
											{depthTwoChild.children.length > 3 && (
												<Typography
													className="flex-column"
													style={{
														alignItems: "center",
														fontWeight: "bold",
														fontSize: "1.75vh",
														marginTop: "1vh",
														marginLeft: "1vw",
														lineHeight: "1.7vh",
														height: "3vh",
													}}
												>
													{prettifyInputName(depthThreeChild.path)}
												</Typography>
											)}
											<div className="flex-column" style={{ alignItems: "center" }}>
												<Typography
													// create a ref using unique fullPath string
													ref={(input) => (this[depthThreeChild.fullPath] = input)}
													className="flex-row"
													style={{
														alignItems: "center",
														fontWeight: "bold",
														fontSize: "1.7vh",
														marginTop: "1vh",
														marginLeft: "1vw",
													}}
												>
													<div className="empty-circle" />
													<div className="empty-circle" />
													<div
														className="filled-circle"
														style={{
															backgroundColor: this.calculateCircleColor(depthThreeChild.errors),
															border: `1px solid ${this.calculateCircleColor(depthThreeChild.errors)}`,
														}}
													/>
													{depthTwoChild.children.length < 4 && prettifyInputName(depthThreeChild.path)}
												</Typography>
											</div>

											<div
												className={`${depthThreeChild.children?.[0].children ? "flex-row" : "flex-column"}`}
												style={{
													alignItems: "center",
													margin: "2vh",
													marginRight: 0,
													paddingRight: "2vh",
													height: "100%",
													borderRight: depthThreeChild.children?.[0].children
														? "none"
														: index != depthTwoChild.children.length - 1
														? "1px solid #d3d3d3"
														: "none",
												}}
											>
												{depthThreeChild.children.map((depthFourChild, ind) =>
													depthFourChild.children ? (
														<div className="flex-column" style={{ flex: 1 }}>
															{this.split(depthFourChild, 3, 3, ind !== depthThreeChild.children.length - 1)}
														</div>
													) : depthTwoChild.children.length < 4 ? (
														ind % 2 === 0 && (
															<div className="flex-row">
																{this.renderTextField(depthFourChild)}

																{ind + 1 < depthThreeChild.children.length &&
																	this.renderTextField(depthThreeChild.children[ind + 1])}
															</div>
														)
													) : (
														this.renderTextField(depthFourChild)
													)
												)}
											</div>
										</div>
									) : (
										index % 2 === 0 && (
											<div className="flex-column" style={{ flex: 1 }}>
												{/*TODO change tab prevention */}
												{this.renderTextField(depthThreeChild)}
												{index + 1 < depthTwoChild.children.length && this.renderTextField(depthTwoChild.children[index + 1])}
											</div>
										)
									)
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		));
	};

	render() {
		return (
			//Generalized component for a form
			<Form
				tree={this.state.tree}
				defaultExpanded={["financial_info"]}
				handleNext={this.handleNext}
				generateFormContent={this.generateFormContent}
				scrollCallback={(fullPath) => {
					//function called when something on the form navigation is clicked.
					this[fullPath].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
				}}
				toggleAutoFill={() => {
					this.setState((prevState) => ({
						...prevState,
						autofill: !prevState.autofill,
					}));
				}}
				autofill={this.state.autofill}
			/>
		);
	}
}

export default PLSheet;

//Financial_Info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k.headcount;
//Financial_Info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k
//Financial_Info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k
