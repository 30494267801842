import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import generalTheme from "../../utils/theme";

const themeVariant = "light";
const theme = generalTheme.palette[themeVariant];

export const TooltipComponent = ({ tip, color, radius }) => {
	return (
		<div className="flex-center flex-row" style={{ cursor: "pointer", width: "fit-content", height: "fit-content" }}>
			<Tooltip title={tip}>
				<InfoIcon style={{ color: color ? color : "#fff", fontSize: radius }} />
			</Tooltip>
		</div>
	);
};

export default TooltipComponent;
