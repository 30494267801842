export const JSONtemplate = {
	depreciation_projected: {
		january: 4000000,
		february: 4000000,
		march: 4000000,
		april: 3500000,
		may: 3500000,
		june: 3500000,
		july: 3000000,
		august: 3000000,
		september: 3000000,
		october: 3000000,
		november: 3000000,
		december: 3000000,
	},
	amortization_projected: {
		january: 1000000,
		february: 1000000,
		march: 1000000,
		april: 750000,
		may: 750000,
		june: 750000,
		july: 500000,
		august: 500000,
		september: 500000,
		october: 500000,
		november: 400000,
		december: 400000,
	},
	miscellaneous: {
		capital_plan_software: 24000000,
		capital_plan_hardware: 50000000,
	},
};
