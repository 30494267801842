import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import ReactApexChart from "react-apexcharts";
import generalTheme from "../../utils/theme";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { numberWithComma, DollarFormatCustom, IntFormatCustom, RatioFormatCustom } from "../../utils/Functions";
import { TextField, Tooltip } from "@material-ui/core";
import TooltipComponent from "../../components/elements/TooltipComponent";
import axios from "axios";
import { set, typePrettify, prettifyTargetStrikeInputName, determineColor } from "../../utils/Functions";
import InputAdornment from "@material-ui/core/InputAdornment";

const projections = [-25, -10, 10, 25];

const TargetStrikes = ({ data, disabled, dataSet, onChangeSubmit, company }) => {
	let inputs = { ...data.inputs };
	Object.keys(inputs).map((input) => {
		if (inputs[input].type === "ratio") {
			inputs[input] = inputs[input].value * 100;
		} else {
			inputs[input] = inputs[input].value;
		}
	});
	const [sliderVals, setSliderVals] = useState(inputs);
	const [outputVal, setOutputVal] = useState(data.value);
	const [maxInput, setMaxInput] = useState(0);
	const [inputError, setInputError] = useState(false);
	const [textfieldChange, setTextfieldChange] = useState(false);
	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];
	const [target, setTarget] = data.target;

	const benchmark = data.value * 1.2;

	const calculateOutput = () => {
		let endpoint = company ? `/company/dataset_admin/${dataSet}/${company}/whatIf` : `/company/dataset/${dataSet}/whatIf`;
		let inputVals = { ...sliderVals };
		console.log("before", inputVals);
		let i;
		let keys = Object.keys(inputVals);
		for (i = 0; i < keys.length; i++) {
			let inputValKey = keys[i];
			if (data.inputs[inputValKey]?.type === "ratio") {
				inputVals[inputValKey] = inputVals[inputValKey] / 100;
			}
		} /*
		Object.keys(inputVals).map((inputValKey) => {
			if (data.inputs[inputValKey]?.type === "ratio") {
				console.log("value before", inputVals[inputValKey]);
				inputVals[inputValKey] = inputVals[inputValKey] / 100;
				console.log("value after", inputVals[inputValKey]);
			}
		});*/
		console.log("after", inputVals);
		axios
			.post(endpoint, { output: data.name, inputs: { ...inputVals } })
			.then((res) => {
				console.log(res);
				setOutputVal(Object.values(res.data)[0]);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const calculatePercentDiff = (num1, num2) => {
		return (Math.abs(num1 - num2) / ((num1 + num2) / 2)) * 100;
	};

	const authHeader = axios.defaults.headers.common["Authorization"]
	useEffect(() => {
		if (authHeader === undefined) {return}
		calculateOutput();
	}, [textfieldChange, authHeader]);

	useEffect(() => {
		setMaxInput(Object.values(sliderVals).reduce((max, curr) => Math.max(max, curr)));
	}, [sliderVals]);

	const strip = (string) => {
		if (string === "" || string === "0" || string === "00") return "0";
		return string.replace(/,/g, "").replace(/^0+/, "");
	};

	const equationAPICall = (inputVals) => {
		return "placeholder"; //Math.round((strip(inputVals["Input1"].toString()) / strip(inputVals["Input2"].toString())) * strip(inputVals["Input3"].toString()));
	};

	const calcMoneySaved = () => {
		//TODO replace with actual API call
		return Math.round(outputVal.value * 345.765);
	};

	const renderTableRow = (projection) => {
		return (
			<div className="flex-row" style={{ padding: "1vh", alignItems: "center", border: "1px solid #fff", marginBottom: 10, fontSize: "1.5vh" }}>
				<div style={{ flex: 1, color: theme.contrastText, marginLeft: "3vw" }}>{`${projection > 0 ? "+" : ""}${projection}%`}</div>
				{/**<div style={{ flex: 1, color: theme.contrastText, marginLeft: "2vh" }}>{` ${numberWithComma(
					Math.round(parseInt(data.value) + parseInt(data.value) * (projection.target / 100))
				)} (${projection.target > 0 ? "+" : ""}${projection.target}%)`}</div> */}

				<div style={{ flex: 1, textAlign: "center", color: theme.contrastText }}>{`${numberWithComma(
					typePrettify(data.type, parseFloat(data.value) + parseFloat(data.value) * (projection / 100))
				)}`}</div>
			</div>
		);
	};

	const renderProjectionTable = () => {
		return (
			<div className="flex-column" style={{ width: "20vw", marginTop: "2vh" }}>
				<div className="flex-row" style={{ padding: "1vh", alignItems: "center", marginBottom: 10 }}>
					<div style={{ flex: 1, color: "#fff", fontWeight: "bold", marginLeft: "2vh", fontSize: "1.7vh" }}>Projection Change</div>
					<div style={{ flex: 1, textAlign: "center", color: "#fff", fontWeight: "bold", fontSize: "1.7vh" }}>Output Value</div>
				</div>
				{projections.map((projection) => (<React.Fragment key={projection}>{renderTableRow(projection)}</React.Fragment>))}
			</div>
		);
	};

	const nFormatter = (num, digits) => {
		var si = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "k" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "G" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" },
		];
		var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var i;
		for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
				break;
			}
		}
		return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
	};

	const renderSlider = (input) => {
		let stringVal = sliderVals[input].toString();
		let ogInputVal = data.inputs[input].value;
		let type = data.inputs[input].type;
		let max = Math.round(ogInputVal * 2) < 1 ? 1 : Math.round(ogInputVal * 2);
		let inputComponent = type === "dollars" ? DollarFormatCustom : type === "ratio" ? RatioFormatCustom : IntFormatCustom;
		return (
			<div className="flex-row" style={{ alignItems: "center", flex: 1, fontSize: "1.1vh", width: "100%", marginBottom: "1vh" }}>
				<div className="flex-row" style={{ alignItems: "center", flex: 1 }}>
					<div style={{ marginRight: "1vw", lineHeight: 0, fontSize: "1.4vh", width: "max-content", flex: 3 }}>
						<div>{prettifyTargetStrikeInputName(input)}</div>
					</div>
					<div
						className="flex-column"
						style={{
							fontSize: "2.5vh",
							marginRight: "1vw",
							fontWeight: "bold",
							color: theme.contrastText,
							flex: 1,
						}}
					>
						<TextField
							onChange={(e) => {
								let val;
								let stripped = strip(e.target.value);
								if (isNaN(stripped) || parseInt(stripped) < 0) {
									setInputError(true);
									return;
								}
								val = parseInt(stripped);
								let obj = {};
								let returnObj = Object.assign(obj, sliderVals);
								returnObj[input] = val;
								setInputError(false);
								setSliderVals(returnObj);
								setTextfieldChange(!textfieldChange);
							}}
							InputProps={{
								inputComponent: inputComponent,
							}}
							value={numberWithComma(strip(stringVal))}
							id="outlined-basic"
							variant="outlined"
							style={{
								backgroundColor: theme.secondary,
								color: theme.contrastText,
								borderRadius: 5,
								height: "5vh",
								width: `${4 + maxInput.toString().length * 1.3}vh`,
							}}
							disabled={disabled}
						/>
					</div>
				</div>

				<div style={{ width: "90%", paddingTop: 10, flex: 1 }}>
					<Slider
						disabled={disabled}
						min={0}
						max={max}
						step={max / 100}
						onChangeCommitted={(e, newVal) => {
							let obj = {};
							let returnObj = Object.assign(obj, sliderVals);
							returnObj[input] = newVal;
							setSliderVals(returnObj);
							calculateOutput();
						}}
						onChange={(e, newVal) => {
							let obj = {};
							let returnObj = Object.assign(obj, sliderVals);
							returnObj[input] = newVal;
							setSliderVals(returnObj);
						}}
						value={parseFloat(strip(sliderVals[input].toString()))}
						defaultValue={sliderVals[input]}
						valueLabelFormat={(x) => nFormatter(parseFloat(strip(sliderVals[input].toString())), 1)}
						valueLabelDisplay="auto"
						aria-labelledby="continuous-slider"
					/>
				</div>
			</div>
		);
	};

	return (
		<div
			className="flex-column target-strike-container"
			style={{
				width: "70vw",
				height: "70vh",
				backgroundColor: "white",
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				margin: "auto",
				outline: "none",
				borderRadius: 5,
			}}
		>
			<div
				style={{
					textAlign: "center",
					padding: "2vh",
					fontSize: "2.25vh",
					backgroundColor: theme.secondaryDark,
					borderTopLeftRadius: 5,
					borderTopRightRadius: 5,
					color: theme.contrastText,
					height: "7.5vh",
				}}
			>
				<b>Target Strike: </b>
				{data.prettyName}
			</div>
			<div
				className="flex-column"
				style={{
					padding: "7vh",
					backgroundColor: theme.primary,
					height: "100%",
					color: "black",
					overflowY: "scroll",
					borderBottomRightRadius: 5,
					borderBottomLeftRadius: 5,
				}}
			>
				<div className="flex-row" style={{ flex: 1 }}>
					<div className="flex-column flex-center" style={{ flex: 1 }}>
						{data.target_value ? (
							<ReactApexChart
								options={{
									grid: {
										borderColor: theme.contrastText,
									},
									colors: [determineColor(outputVal.target), theme.contrastText],
									legend: {
										labels: {
											colors: [theme.contrastText],
										},
									},
									chart: {
										type: "bar",
										height: 350,
									},
									plotOptions: {
										bar: {
											columnWidth: "50%",
										},
									},
									dataLabels: {
										enabled: false,
									},
									stroke: {
										show: true,
										width: 2,
										colors: ["transparent"],
									},
									xaxis: {
										categories: [""],
									},
									yaxis: {
										max: Math.round(Math.max(benchmark, outputVal.value) * 130) / 100,
										labels: {
											style: {
												colors: [theme.contrastText],
											},
											formatter: (value) => {
												return typePrettify(data.type, value.toFixed(2));
											},
										},
										title: {
											text: data.prettyName,
											style: {
												color: theme.contrastText,
											},
										},
									},
									fill: {
										opacity: 1,
									},
									tooltip: {
										y: {
											formatter: function (val) {
												return typePrettify(data.type, val.toFixed(2));
											},
										},
									},
								}}
								height={400}
								width={500}
								type={"bar"}
								series={[
									{
										name: "Your Metric",
										data: [outputVal.value ? outputVal.value : 0],
									},
									{
										name: "Our Benchmark",
										data: [data.target_value],
									},
								]}
							/>
						) : (
							<div style={{ color: theme.contrastText }}>No benchmark</div>
						)}
					</div>

					<div className="flex-column" style={{ flex: 1, alignItems: "center", color: theme.contrastText }}>
						{renderProjectionTable()}
					</div>
				</div>
				{inputError && (
					<div style={{ color: "red", fontSize: "1.5vh", marginTop: "1.5vh" }}>
						<i>Please enter positive numbers only.</i>
					</div>
				)}
				<div className="flex-row" style={{ alignItems: "center", color: theme.contrastText }}>
					<div className="flex-column" style={{ width: "100%", flex: 5, alignItems: "center", marginTop: ".5vh" }}>
						{Object.keys(data.inputs).map((input) => (<React.Fragment key={input.toString()}>{renderSlider(input)}</React.Fragment>))}
					</div>
					<DoubleArrowIcon style={{ margin: "1.5vh", fontSize: "7vh", color: theme.contrastText }} />
					<div className="flex-column" style={{ flex: 1, alignItems: "center" }}>
						<div
							style={{
								fontSize: "1.5vh",
								lineHeight: 1.2,
								color: determineColor(outputVal.target),
							}}
						>
							<i>{"Output Value"}</i>
						</div>
						<div
							style={{
								fontSize: "5vh",
								fontWeight: "bold",
								lineHeight: "normal",
								color: determineColor(outputVal.target),
							}}
						>
							{outputVal.value && typePrettify(outputVal.type, numberWithComma(parseFloat(outputVal.value).toFixed(2)))}
						</div>
						<div style={{ marginBottom: "2vh" }} />
					</div>
				</div>
				{!disabled && (
					<div className="flex-column" style={{ width: "100%", marginTop: "3.5vh", marginRight: "2vw", alignItems: "center" }}>
						<div
							style={{
								padding: "1vh 2vh 1vh 2vh",
								borderRadius: 5,
								backgroundColor: theme.green,
								color: theme.contrastText,
								cursor: "pointer",
							}}
							onClick={() => {
								let inputChangeObj = {};
								let i;
								let keys = Object.keys(sliderVals);
								for (i = 0; i < keys.length; i++) {
									let key = keys[i];
									if (data.inputs[key].type === "ratio") {
										set(inputChangeObj, key, sliderVals[key] / 100);
									} else {
										set(inputChangeObj, key, sliderVals[key]);
									}
								}

								let endpoint = company ? `/company/dataset_admin/${dataSet}/${company}` : `/company/dataset/${dataSet}`;
								axios
									.put(endpoint, inputChangeObj)
									.then((res) => {
										//console.log(res);
										onChangeSubmit();
									})
									.catch((err) => {
										console.log(err);
									});
							}}
						>
							Submit Changes
						</div>
					</div>
				)}
				{disabled && (
					<div style={{ width: "100%", alignItems: "center" }} className="flex-column">
						<div style={{ textAlign: "center", alignItems: "center" }} className="flex-row">
							<i style={{ color: "red", fontSize: "1.5vh", marginRight: 10 }}>This is an original read-only dataset</i>
							<TooltipComponent
								radius={"2vh"}
								tip={
									"Original datasets are read-only. To change input values to see their overall effects on budget, please generate a new data set."
								}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TargetStrikes;
