import React, { useEffect, useState } from "react";
import axios from "axios";
import generalTheme from "../../utils/theme";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { parseDate, splitArr } from "../../utils/Functions";
import { TextField } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";

const themeVariant = "dark";
const theme = generalTheme.palette[themeVariant];

const testDataSets = [
	{ name: "Original", last_updated: "2021-08-25 21:33:31.575585+00:00", created: "2021-08-25 21:33:31.575585+00:00" },
	{ name: "Compensation Optimization", last_updated: "2021-08-25 21:33:31.575585+00:00", created: "2021-08-25 21:33:31.575585+00:00" },
	{ name: "2020 Q4 Financials", last_updated: "2021-08-25 21:33:31.575585+00:00", created: "2021-08-25 21:33:31.575585+00:00" },
	{ name: "Aggresive Offshore", last_updated: "2021-08-25 21:33:31.575585+00:00", created: "2021-08-25 21:33:31.575585+00:00" },
	{
		name: "Techfin Recommendation",
		is_recommendation: true,
		last_updated: "2021-08-25 21:33:31.575585+00:00",
		created: "2021-08-25 21:33:31.575585+00:00",
	},
];

const Admin = (props) => {
	const isAdmin = localStorage.isAdmin;
	if (!isAdmin) {
		props.history.push("/");
	}

	const [recommendationName, setRecommendationName] = useState("");
	const [companies, setCompanies] = useState([]);
	const [currCompany, setCurrCompany] = useState(null);
	const [recommendationModal, setRecommendationModal] = useState(null);
	const [datasets, setDatasets] = useState([]);
	const [deleteModal, setDeleteModal] = useState(null);
	
	const authHeader = axios.defaults.headers.common["Authorization"]
	useEffect(() => {
		if(authHeader === undefined) {return}
		axios
			.get(`/company/dataset_admin/datasets`)
			.then((res) => {
				console.log("Company Datasets: ", res);
				setCompanies(res.data);
				setCurrCompany(res.data[0].company_name);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [authHeader]);

	useEffect(() => {
		if(authHeader === undefined || !currCompany) {return}
		axios
			.get(`/company/dataset_admin/${currCompany}/all_datasets`)
			.then((res) => {
				setDatasets(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [currCompany, authHeader]);

	const deleteModalBody = (open) => {
		return deleteModal ? (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column"
					style={{
						width: "fit-content",
						height: "fit-content",
						backgroundColor: theme.primary,
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: "30vh",
						margin: "auto",
						outline: "none",
						borderRadius: 5,
						color: theme.contrastText,
						paddingBottom: "5vh",
						alignItems: "center",
						paddingRight: "2vw",
						paddingLeft: "2vw",
					}}
				>
					<div
						className="flex-column"
						style={{
							textAlign: "center",
							padding: "4vh",
							fontSize: "3vh",
							backgroundColor: theme.primary,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							marginBottom: "4vh",
							fontWeight: "200",
						}}
					>
						<div className="flex-row" style={{ alignItems: "center" }}>
							{`Delete Recommendation : ${deleteModal}?`}
						</div>
					</div>

					<div className="flex-column" style={{ alignItems: "center" }}>
						<div className="flex-row">
							<div
								style={{
									border: `1px solid ${theme.contrastText}`,
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginRight: "2vw",
								}}
								onClick={() => {
									setCurrCompany(null);
								}}
							>
								Cancel
							</div>
							<div
								style={{
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginLeft: "2vw",
									backgroundColor: theme.red,
									color: theme.contrastText,
								}}
								onClick={() => {
									axios
										.delete(`/company/dataset_admin/${deleteModal}/${currCompany}`)
										.then((res) => {
											setDeleteModal(null);
											window.location.reload();
										})
										.catch((err) => {
											console.log(err);
										});
								}}
							>
								Delete Recommendation
							</div>
						</div>
					</div>
				</div>
			</Fade>
		) : (
			<div />
		);
	};

	const createRecModal = (open) => {
		return recommendationModal ? (
			<Fade in={open} timeout={400}>
				<div
					className="flex-column"
					style={{
						width: "fit-content",
						height: "fit-content",
						backgroundColor: theme.primary,
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: "30vh",
						margin: "auto",
						outline: "none",
						borderRadius: 5,
						color: theme.contrastText,
						paddingBottom: "5vh",
						alignItems: "center",
						paddingRight: "2vw",
						paddingLeft: "2vw",
					}}
				>
					<div
						className="flex-column"
						style={{
							textAlign: "center",
							padding: "4vh",
							fontSize: "3vh",
							backgroundColor: theme.primary,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							marginBottom: "4vh",
							fontWeight: "200",
						}}
					>
						<div className="flex-row" style={{ alignItems: "center" }}>
							{`Create Recommendation for: ${currCompany}?`}
						</div>
					</div>
					<div className="flex-row" style={{ paddingLeft: "2vw", width: "100%", alignItems: "center", marginBottom: "10vh" }}>
						<div style={{ fontSize: "1.75vh", marginRight: "2vw", whiteSpace: "nowrap" }}>Recommendation Name</div>
						<TextField
							onChange={(e) => {
								setRecommendationName(e.target.value);
							}}
							id="outlined-basic"
							variant="outlined"
							style={{
								width: "100%",
								backgroundColor: theme.secondary,
								color: theme.contrastText,
								borderRadius: 5,
								height: "5vh",
							}}
						/>
					</div>
					<div className="flex-column" style={{ alignItems: "center" }}>
						<div className="flex-row">
							<div
								style={{
									border: `1px solid ${theme.contrastText}`,
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginRight: "2vw",
								}}
								onClick={() => {
									setCurrCompany(null);
								}}
							>
								Cancel
							</div>
							<div
								style={{
									padding: "10px 15px 10px 15px",
									borderRadius: 5,
									cursor: "pointer",
									marginLeft: "2vw",
									backgroundColor: theme.green,
									color: theme.contrastText,
								}}
								onClick={() => {
									axios
										.post(`/company/dataset_admin/duplicate`, {
											dataset_name: "Original",
											company_name: currCompany,
											recommendation_name: recommendationName,
										})
										.then((res) => {
											props.history.push({
												pathname: `/datasets/TechFinIQ%20Recommendation%20(${recommendationName})`,
												state: { company: currCompany },
											});
										})
										.catch((err) => {
											if (err.response.data === "Cannot duplicate dataset to already existing dataset.") {
												props.history.push({
													pathname: `/datasets/TechFinIQ%20Recommendation%20(${recommendationName})`,
													state: { company: currCompany },
												});
											}
										});
								}}
							>
								Create Recommendation
							</div>
						</div>
					</div>
				</div>
			</Fade>
		) : (
			<div />
		);
	};

	const spacing = "15px";

	return (
		<div className="flex-column" style={{ width: "100vw", height: "91vh", padding: "5vh 10vh 5vh 10vh", color: theme.contrastText }}>
			<Modal
				open={deleteModal}
				onClose={() => {
					setDeleteModal(null);
				}}
			>
				{deleteModalBody(deleteModal !== null)}
			</Modal>
			<Modal
				open={recommendationModal}
				onClose={() => {
					setRecommendationModal(null);
					setRecommendationName("");
				}}
			>
				{createRecModal(recommendationModal !== null)}
			</Modal>
			<div style={{ fontSize: "4vh", marginBottom: 10 }}>Recommendation Creation</div>
			<i style={{ marginBottom: "5vh", fontSize: "1.75vh", color: "#d3d3d3" }}>Choose a company to make/edit a Techfin Recommendation for.</i>
			<div className="flex-row">
				<div className="flex-column reveal-from-left" style={{ flex: 1, height: "70vh", marginRight: spacing }}>
					{companies?.map((company) => {
						return (
							<div
								key={company.company_name}
								className="flex-row data-set-card"
								style={{
									width: "100%",
									backgroundColor: theme.primary,
									padding: "2vh 3vh 2vh 3vh",
									boxShadow: `5px 5px	5px ${theme.shadow}`,
									alignItems: "center",
									cursor: "pointer",
									marginBottom: spacing,
									border: currCompany === company.company_name ? "2px solid #ffffff" : `none`,
								}}
								onClick={() => {
									setCurrCompany(company.company_name);
								}}
							>
								<div
									className="flex-column"
									style={{ flex: 1 }}
									onClick={() => {
										console.log(`/datasets/${company.company_name}`);
										//props.history.push(`/datasets/${ccompany_name}`);
									}}
								>
									<div style={{ fontSize: "2.5vh", fontWeight: "bold", marginBottom: 10 }}>{company.company_name}</div>
									<div className="flex-row" style={{ fontSize: "1.5vh", lineHeight: 1.4 }}>
										<div style={{ color: "#808080" }}>Created:&nbsp;</div>
										<div>{parseDate(company.created)}</div>
									</div>
									<div className="flex-row" style={{ fontSize: "1.5vh", lineHeight: 1.4 }}>
										<div style={{ color: "#808080" }}>Last Updated:&nbsp;</div>
										<div>{company.last_updated ? parseDate(company.last_updated) : parseDate(company.created)}</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div
					style={{
						flex: 2,
						height: "70vh",
						backgroundColor: theme.primary,
						borderRadius: "0px 5px 5px 0px",
						padding: "4vh",
						zIndex: 5,
						boxShadow: `10px 10px 25px ${theme.shadow}`,
						alignItems: "center",
					}}
					className="reveal-from-right flex-column"
				>
					<div
						style={{
							fontSize: "2.5vh",
							fontWeight: "bold",
							textAlign: "center",
							width: "100%",
							marginBottom: "5vh",
						}}
					>{`${currCompany}`}</div>
					<div style={{ overflowY: "scroll", height: "60vh", width: "100%" }}>
						{splitArr(datasets, 2).map((row, index) => {
							return (
								<div className="flex-row" key={index}>
									{row.map((dataset) => (
										<div
											key={dataset.name}
											className="flex-row"
											style={{
												width: "calc(50% - 2vh)",
												padding: "1.25vh",
												border: "1px solid #d3d3d3",
												marginBottom: "1.5vh",
												alignItems: "center",
												margin: "1vh",
												borderRadius: 5,
											}}
										>
											<div style={{ flex: 1 }}>
												<div style={{ fontSize: "2vh", marginBottom: "1vh" }}>{dataset.name}</div>
												<div className="flex-row" style={{ fontSize: "1.5vh", color: "#808080", lineHeight: 1.2 }}>
													<div>Created:&nbsp;</div>
													<div style={{ color: "#fff" }}>{`${parseDate(dataset.created)}`}</div>
												</div>
												<div className="flex-row" style={{ fontSize: "1.5vh", color: "#808080", lineHeight: 1.2 }}>
													<div>Last Updated:&nbsp;</div>
													<div style={{ color: "#fff" }}>{`${
														dataset.last_updated ? parseDate(dataset.last_updated) : parseDate(dataset.created)
													}`}</div>
												</div>
											</div>
											<div className="flex-column" style={{ height: "100%" }}>
												{dataset.name === "Original" && (
													<Tooltip title={"Create New Recommendation"} placement={"top"}>
														<AddCircleOutlineIcon
															style={{ cursor: "pointer", flex: 1 }}
															onClick={() => {
																setRecommendationModal(currCompany);
															}}
														/>
													</Tooltip>
												)}
												{dataset.is_recommendation ? (
													<Tooltip title={"Edit Recommendation"} placement={"top"}>
														<EditIcon
															style={{ cursor: "pointer", flex: 1 }}
															onClick={() => {
																props.history.push({
																	pathname: `/datasets/${dataset.name}`,
																	state: { company: currCompany },
																});
															}}
														/>
													</Tooltip>
												) : (
													<Tooltip title={"View Dataset"} placement={"top"}>
														<VisibilityIcon
															style={{ cursor: "pointer", flex: 1 }}
															onClick={() => {
																props.history.push({
																	pathname: `/datasets/${dataset.name}`,
																	state: { company: currCompany },
																});
															}}
														/>
													</Tooltip>
												)}
												{dataset.is_recommendation && (
													<Tooltip title={"Delete Recommendation"} placement={"top"}>
														<DeleteIcon
															style={{ cursor: "pointer", flex: 1 }}
															onClick={() => {
																setDeleteModal(dataset.name);
															}}
														/>
													</Tooltip>
												)}
											</div>
										</div>
									))}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Admin);
