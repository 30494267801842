import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./FormPage.css";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Modal from "@material-ui/core/Modal";
import { tabs } from "../../utils/Constants";
import { styled } from "@material-ui/core/styles";
import { createDataSet } from "../../axios/backend";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";

//Steps
import PLSheet from "./Steps/PLSheet";
import BalanceSheet from "./Steps/BalanceSheet";
import OperationalMetrics from "./Steps/OperationalMetrics";

import generalTheme from "../../utils/theme";
import axios from "axios";

const steps = tabs;

const FormPage = (props) => {
	const [stepIndex, setStepIndex] = useState(0);
	const [formsData, setFormsData] = useState({});
	const [exitModal, setExitModal] = useState(false);

	const authHeader = axios.defaults.headers.common["Authorization"]
	useEffect(() => {
		if (authHeader === undefined) {return}
		if (formsData["operational_metrics"]) {
			console.log(formsData);

			axios
				.post(`/company/dataset`, {
					name: "Original",
					...formsData,
				})
				.then((res) => {
					setStepIndex(4);
					//props.history.push("/datasets")
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [formsData, authHeader]);

	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];

	const exitModalContent = () => {
		return (
			<div
				className="flex-column"
				style={{
					width: 400,
					height: 400,
					backgroundColor: "white",
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					margin: "auto",
					outline: "none",
					borderRadius: 5,
				}}
			>
				<Typography
					style={{
						textAlign: "center",
						padding: 15,
						fontWeight: "bold",
						fontSize: 20,
						backgroundColor: "rgb(164, 57, 52)",
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
						color: "white",
					}}
				>
					Return To Homepage?
				</Typography>
				<Typography
					className="flex-row flex-center"
					style={{ textAlign: "center", padding: 20, paddingRight: 60, paddingLeft: 60, flex: 1, fontSize: 17 }}
				>
					<i>If you exit now, the data that you have entered will be lost.</i>
				</Typography>
				<div className="flex-row" style={{ marginBottom: 50, marginTop: 10 }}>
					<Typography
						onClick={() => {
							setExitModal(false);
						}}
						style={{
							padding: 10,
							paddingLeft: 20,
							paddingRight: 20,
							textAlign: "center",
							margin: "auto",
							color: "rgb(164, 57, 52)",
							borderRadius: 5,
							fontWeight: "bold",
							cursor: "pointer",
							border: "1px solid rgb(164, 57, 52)",
						}}
					>
						Cancel
					</Typography>
					<Typography
						onClick={() => {
							props.history.push("/");
						}}
						style={{
							padding: 10,
							paddingLeft: 20,
							paddingRight: 20,
							textAlign: "center",
							margin: "auto",
							color: "rgb(164, 57, 52)",
							borderRadius: 5,
							fontWeight: "bold",
							cursor: "pointer",
							border: "1px solid rgb(164, 57, 52)",
						}}
					>
						Exit
					</Typography>
				</div>
			</div>
		);
	};

	return (
		<div className="form-page-container flex-column" style={{ backgroundColor: theme.secondaryDark }}>
			<Modal
				open={exitModal}
				onClose={() => {
					setExitModal(false);
				}}
			>
				{exitModalContent()}
			</Modal>

			<Carousel draggable={false} plugins={["arrows"]} value={stepIndex} animationSpeed={1000}>
				<div className="flex-column" style={{ color: theme.contrastText, height: "50vh", width: "50vw", alignItems: "center" }}>
					<div className="flex-row" style={{ width: "100%", height: "100%" }}>
						<div classsName="flex-column" style={{ alignItems: "center", flex: 1, lineHeight: 1.3, fontSize: "1.75vh" }}>
							<div style={{ fontSize: "4vh", marginBottom: "4vh" }}>Input Form</div>
							<div className="reveal-from-left" style={{ marginBottom: "1vh", fontStyle: "italic" }}>
								Welcome to the input form, where you will enter financial information regarding your company's tech infrastructure.
							</div>
							<div className="reveal-from-left" style={{ marginBottom: "1vh", fontStyle: "italic" }}>
								There will be 3 steps to the form: financial info, balance sheet, and operational metrics.
							</div>
							<div className="reveal-from-left" style={{ marginBottom: "1vh", fontStyle: "italic" }}>
								After you have completed the form, your data will be saved as a dataset which can be viewed on the data sets page. The
								data set will be run through our algorithm to create a series of output metrics that will reveal possible areas of
								guidance.
							</div>
							<div className="reveal-from-left" style={{ marginBottom: "1vh", fontStyle: "italic" }}>
								If at any point on the form you do not know a value, please do not leave it blank, but enter{" "}
								<b style={{ color: theme.red }}>N/A</b> instead.
							</div>
						</div>
						<div className="flex-column" style={{ alignItems: "flex-start", flex: 1, justifyContent: "center" }}>
							<div className="flex-row">
								<div style={{ height: "35vh", borderRight: "2px solid #808080", marginRight: "5vw", marginLeft: "5vw" }} />
								<div
									className="flex-row reveal-from-right"
									style={{ fontSize: "3vh", cursor: "pointer", alignItems: "center", paddingBottom: "5vh", marginTop: "25vh" }}
									onClick={() => {
										setStepIndex(1);
									}}
								>
									<div style={{ marginRight: 10, fontStyle: "italic" }}>Start</div>
									<ArrowForwardIosIcon style={{ fontSize: "4vh", color: theme.red }} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<PLSheet
					setStepIndex={setStepIndex}
					submitFormDataToParent={(formData) => {
						setFormsData({
							...formsData,
							...formData,
						});
						setStepIndex(2);
					}}
				/>
				<BalanceSheet
					handleBack={() => {
						setStepIndex(1);
					}}
					submitFormDataToParent={(formData) => {
						setFormsData({
							...formsData,
							...formData,
						});
						setStepIndex(3);
					}}
				/>
				<OperationalMetrics
					handleBack={() => {
						setStepIndex(2);
					}}
					submitFormDataToParent={(formData) => {
						setFormsData({
							...formsData,
							...formData,
						});
					}}
				/>
				<div
					className="flex-column"
					style={{
						paddingLeft: "5vw",
						height: "100%",
						backgroundColor: theme.secondaryDark,
						color: theme.contrastText,
						alignItems: "flex-end",
					}}
				>
					<div className="flex-row" style={{ fontSize: "3vh", marginTop: "30vh" }}>
						Successfully Created an <div style={{ color: theme.red }}>&nbsp;Original&nbsp;</div> Data Set!
					</div>
					<i
						className="flex-row"
						style={{ marginTop: "10vh", alignItems: "center", cursor: "pointer" }}
						onClick={() => {
							props.history.push("/datasets");
						}}
					>
						View Data Set <ArrowForwardIosIcon style={{ fontSize: "3vh", color: theme.red, marginLeft: 10 }} />
					</i>
				</div>
			</Carousel>
		</div>
	);
};

export default withRouter(FormPage);
