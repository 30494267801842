import React, { Fragment } from "react";
import NavTree from "../../../components/elements/NavTree";
import generalTheme from "../../../utils/theme";

const Form = ({ tree, defaultExpanded, handleNext, generateFormContent, scrollCallback, handleBack, handleSubmit, toggleAutoFill, autofill }) => {
	const themeVariant = "dark";
	const theme = generalTheme.palette[themeVariant];

	const autofillConst = true;

	return (
		<div className="flex-column" style={{ paddingLeft: "5vw", height: "100%", backgroundColor: theme.secondaryDark }}>
			{autofillConst && (
				<div className="flex-column" style={{ alignItems: "center", color: "white", marginTop: "2vh", marginBottom: "2vh" }}>
					<div
						style={{
							padding: "1.5vh 2vh 1.5vh 2vh",
							border: "1px solid white",
							borderRadius: 5,
							cursor: "pointer",
							fontSize: "1.4vh",
							backgroundColor: autofill ? "green" : "transparent",
						}}
						onClick={toggleAutoFill}
					>
						AUTOFILL
					</div>
				</div>
			)}
			<div className="flex-row" style={{ width: "100vw", height: "100%" }}>
				<div
					className="flex-column"
					style={{
						flex: 2,
						textAlign: "left",
						backgroundColor: theme.secondaryDark,
						margin: "1vh",
						marginTop: "5vh",
						lineHeight: 1.3,
					}}
				>
					<div style={{ marginBottom: "3vh" }}>
						<div className="flex-row" style={{ fontSize: "1.75vh", fontWeight: "bold", alignItems: "center", color: theme.contrastText }}>
							<div className="empty-circle" />
							Form Navigation
						</div>
						{tree.errors > 0 && (
							<div style={{ marginTop: "1vh", fontSize: "1.25vh", color: "red", width: "85%" }}>
								<i>
									Note: If you are unsure about a metric field, do <b>not</b> leave it blank. Enter <b>N/A</b> instead.
								</i>
							</div>
						)}
					</div>
					<NavTree tree={tree} callBack={scrollCallback} defaultExpanded={defaultExpanded} />
				</div>
				<div
					style={{
						flex: 8,
						textAlign: "left",
						height: autofillConst ? "80vh" : "92vh",
						marginRight: "5vw",
						overflowY: "scroll",
						padding: "2vh",
						paddingTop: "5vh",
					}}
				>
					{generateFormContent(tree)}
					<div className="flex-row">
						{handleBack ? (
							<div style={{ flex: 1 }}>
								<div
									style={{
										textAlign: "center",
										padding: "1.5vh 2.5vh 1.5vh 2.5vh",
										width: "fit-content",
										borderRadius: 5,
										cursor: "pointer",
										marginRight: "1vh",
										color: "#fff",
										backgroundColor: "rgb(164, 57, 52)",
										fontWeight: "bold",
										fontSize: "1.75vh",
										boxShadow: "4px 4px 10px #000000",
										flex: 1,
									}}
									onClick={handleBack}
								>
									Back
								</div>
							</div>
						) : (
							<div style={{ flex: 1 }} />
						)}
						<div
							style={{
								textAlign: "center",
								padding: "1.5vh 2.5vh 1.5vh 2.5vh",
								width: "fit-content",
								borderRadius: 5,
								cursor: "pointer",
								marginRight: "1vh",
								color: "#fff",
								backgroundColor: "rgb(164, 57, 52)",
								fontWeight: "bold",
								fontSize: "1.75vh",
								boxShadow: "4px 4px 10px #000000",
							}}
							onClick={handleSubmit ? handleSubmit : handleNext}
						>
							{handleSubmit ? "Submit" : "Next"}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form;
