import React from "react";

const FAQ = () => {
	return (
		<div className="flex-column" style={{ width: "100vw", height: "91vh", padding: "5vh 10vh 5vh 10vh", color: "white" }}>
			<div style={{ fontSize: "4vh", marginBottom: 10 }}>FAQ</div>
			<i style={{ marginBottom: "5vh", fontSize: "1.75vh", color: "#d3d3d3" }}>
				Frequently asked questions. If you have a quesetion that's not listed, feel free to contact us directly through the homepage.
			</i>
		</div>
	);
};

export default FAQ;
