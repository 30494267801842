import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import NumberFormat from "react-number-format";

/* 	Inserts a value into an obj using its flattened path string
	Example:
	exampleObj = {
		clothes:{
			shirts:{
				VNecks:{
					red:4,
					blue:6,
				}
			}
		}
	}
	set( exampleObj, "clothes.shirts.vNecks.orange" , 8)
	exampleObj = {
		clothes:{
			shirts:{
				VNecks:{
					red:4,
					blue:6,
					orange: 8
				}
			}
		}
	}
*/

export const set = (obj, path, value) => {
	let returnObj = obj;
	let tempObj = obj;
	let tempArr = path.split(".");
	let length = tempArr.length;
	for (let i = 0; i < length - 1; i++) {
		let elem = tempArr[i];
		if (!tempObj[elem]) tempObj[elem] = {};
		tempObj = tempObj[elem];
	}
	tempObj[tempArr[length - 1]] = value;
	return returnObj;
};

export const parseDate = (date) => {
	let dateObj = new Date(date);
	return `${dateObj.getMonth() + 1}/${dateObj.getDay() + 1}/${dateObj.getFullYear()}`;
};

export const splitArr = (arr, rows) => {
	let arrays = [];

	for (let i = 0; i < arr.length; i += rows) arrays.push(arr.slice(i, i + rows));
	return arrays;
};

export const typePrettify = (type, value, truncationLen) => {
	if (!truncationLen) {
		truncationLen = 0;
	}

	let prefix = "";
	let formattedValue = value;
	let suffix = "";

	if (type === "dollars") {
		prefix = "$";

		// Some input may already be a formatted string (for some reason). Only care about formatting numbers.
		if (typeof value === "number") {
			formattedValue = numberWithComma(parseFloat(value).toFixed(2))
		}
	}
	if (type === "ratio") {
		formattedValue = numberWithComma((parseFloat(value) * 100).toFixed(truncationLen));
		suffix = "%";
	}
	if (type === "int" || type === "uint") {
		if (typeof value === "string") {
			value = value.replace(',', '')
		}

		formattedValue = numberWithComma(parseFloat(value).toFixed(truncationLen))
	}

	// If formattedValue was rounded down to 0 then add a ~ prefix to explain "near zero".
	if (formattedValue === "0" && value !== 0) {
		prefix = `~${prefix}`;
	}

	return `${prefix}${formattedValue}${suffix}`;
};

export const determineColor = (target) => {
	return target === "very off" ? "#F76C50" : target === "slightly off" ? "#FFB133" : target === "on target" ? "#7DBE74" : "#808080";
};

export const capitalize = (string) => {
	let returnString = "";
	let stringArr = string.split(/[ _|\\-]+/);
	stringArr.map((str, index) => {
		let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
		returnString = returnString.concat(capitalizedStr);
		if (index !== stringArr.length - 1) {
			returnString = returnString.concat(" ");
		}
	});
	return returnString;
};

//	Converts a json into a tree object
export const objToTree = (obj, path, parent) => {
	if (typeof obj === "string" || typeof obj === "number") return obj;

	let fullPath = `${parent ? `${parent}.` : ""}${path}`;
	return {
		path: path,
		fullPath: `${parent ? `${parent}.` : ""}${path}`,
		children: Object.keys(obj).map((key) =>
			typeof objToTree(obj[key], key, fullPath) !== "string" && typeof objToTree(obj[key], key, fullPath) !== "number"
				? objToTree(obj[key], key, fullPath)
				: {
						fullPath: `${fullPath}.${key}`,
						path: key,
						autofillVal: objToTree(obj[key], key, fullPath),
				  }
		),
		errors: -1,
	};
};
/* --------------------------------EXAMPLE-----------------------------
	--------JSON input---------
	clothes:{
		shirts:{
			vNecks:{
				black:1, <-- string/number represents textfields where we are getting input from user
				blue:"two",
				orange:3
			},
		},
		pants:{
			nike:{
				jeans:4,
				sweats:2,
				joggers:1
			},
		}
	}
	----------Tree output--------
	
	{
		path: "clothes",
		fullPath: "clothes",
		errors:-1,
		children: [
			{
				path:"shirts",
				fullPath:"clothes.shirts",
				errors:-1,
				children:[
					{
						path:"vNecks",
						fullPath:"clothes.shirts.vNecks",
						errors:-1,
						children:[
							{
								path:"black",
								fullPath:"clothers.shirts.vNecks.black", <--- Full path is a unique string . This is used to create refs for text fields when trying to retrieve their value
																			  or to scroll to headers when they are clicked in the form navigation
								errors:-1
											<---- No children, aka a leaf node, meaning this is a textfield where we should be getting input from user
							},
							{
								path:"blue",
								fullPath:"clothers.shirts.vNecks.black",
								errors:-1
							},
							{
								path:"orange",
								fullPath:"clothers.shirts.vNecks.black",
								errors:-1
							}
						]
					}
				]
			},
			{
				path:"pants",
				fullPath:"clothes.pants",
				errors:-1,
				children:[
					{
						path:"nike",
						fullPath:"clothers.pants.nike"
						errors:-1,
						children:[
							{
								path:"jeans",
								fullPath:"clothers.pants.nike.jeans",
								errors:-1
							},
							{
								path:"sweats",
								fullPath:"clothers.pants.nike.sweats",
								errors:-1
							},
							{
								path:"joggers",
								fullPath:"clothers.pants.nike.joggers",
								errors:-1
							}
						]
					}
				]
			}
		]
	} */

export const prettifyOutputNames = (data) => {
	return data.map((output) => {
		const prettyOutputName = prettifyOutputName(output.name);
		return { ...output, prettyName: prettyOutputName };
	});
};

export const prettifyOutputName = (outputName) => {
	switch (outputName) {
		case "total_it_expense":
			return "Total IT Expense";
		case "total_comp":
			return "Total IT Compensation Expenses";
		case "total_benefits":
			return "Total IT Benefits Expenses";
		case "total_comp_and_benefits":
			return "Total IT Comp & Benefits Expenses";
		case "average_tech_fte_comp_benefits":
			return "Avg. Comp & Benefits per Tech FTE";
		case "percent_prof_fees_expense_to_nonpersonnel":
			return "% Professional Fees of Non-Personnel";
		case "percent_total_sw_maintenance_expense_to_total_it":
			return "% Total SW Maintenance of Total IT";
		case "percent_non_mgmt_to_total_comp":
			return "% Non-Mgmt Comp of Total Comp (Excl. Benefits)";
		case "percent_variable_comp_to_total_it":
			return "% Variable Expense of Total IT";
		case "sq_ft_offshore":
			return "Square Footage Offshore";
		case "total_vendor_expense":
			return "Total Vendor Expense";
		case "ave_mgmt_comp_per_hc":
			return "Avg. Mgmt Compensation";
		case "total_prof_fees_expense":
			return "Total Professional Fees Expense";
		case "num_onshore_consultant":
			return "# Onshore Consultants";
		case "percent_subcontractor_expense_to_nonpersonnel":
			return "% Subcontractor of Total Non-Personnel";
		case "num_offshore_consultant":
			return "# Offshore Consultants";
		case "percent_occupancy_expense_to_total_it":
			return "% Facilities of Total IT";
		case "percent_misc_expense_to_nonpersonnel":
			return "% Misc. of Non-Personnel";
		case "ratio_of_tech_to_firm_hc":
			return "% Tech Headcount of Total Firm Headcount";
		case "ave_cost_vendor":
			return "Avg. Cost per Vendor";
		case "percent_travel_entertainment_expense_to_total_it":
			return "% T&E of Total IT Expense";
		case "percent_total_comp_to_total_it":
			return "% Total Comp of Total IT Expense";
		case "ratio_benefits_to_total_it_spend":
			return "% Total Benefits of Total IT Expense";
		case "ratio_compensation_and_benefits_to_total_it_spend":
			return "% Total Comp & Benefits of Total IT";
		case "percent_onshore_consultant_expense":
			return "% Onshore Consultant Expense";
		case "percent_total_hw_maintenance_expense_to_total_it":
			return "% Total HW Maintenance of Total IT";
		case "total_firm_hc":
			return "Total Firm Headcount";
		case "total_sq_ft":
			return "Total Square Footage Onshore & Offshore";
		case "total_network_expense":
			return "Total Network Expense";
		case "total_legal_expense":
			return "Total Legal Expense";
		case "avg_cost_of_dev_hc":
			return "Avg. Cost per Developer Employee";
		case "percent_offshore_facilities_rent":
			return "% Offshore Facilities Rent to Total Rent";
		case "percent_offshore_consultant_expense":
			return "% Offshore Consultant Expense";
		case "total_hw_maintenance_expense":
			return "Total HW Maintenance Expense";
		case "balance_sheet_roll_on_depreciation":
			return "Balance Sheet Roll On Depreciation";
		case "sw_amortization_expense":
			return "SW Amortization Expense";
		case "ave_cost_offshore_consultant":
			return "Avg. Daily Cost of Offshore Consultant";
		case "total_hw_rental_expense":
			return "Total HW Rental Expense";
		case "percent_total_hw_maintenance_expense_to_nonpersonnel":
			return "% Total HW Maintenance of Non-Personnel Expense";
		case "total_consultant_expense":
			return "Total Consultant Expense";
		case "percent_prof_fees_expense_to_total_it":
			return "% Professional Fees of Total IT Expense";
		case "percent_telecom_expense_to_total_it":
			return "% Telecom of Total IT Expense";
		case "percent_hw_rental_expense_to_nonpersonnel":
			return "% Hardware Rental of Non-Personnel Expense";
		case "total_onshore_consultant_expense":
			return "Total Onshore Consultant Expense";
		case "percent_variable_comp":
			return "% Variable Comp of Total Compensation";
		case "percent_sw_amortization_expense_to_total_it":
			return "% SW Amortization of Total IT Expense";
		case "percent_travel_entertainment_expense_to_nonpersonnel":
			return "% T&E Expense of Non-Personnel Expense";
		case "percent_onshore_consultant_headcount":
			return "% Onshore Consultant Headcount";
		case "percent_infra_to_it_hc":
			return "% Infra Headcount of IT Headcount";
		case "percent_network_expense_to_total_it":
			return "% Network Expense of Total IT Expense";
		case "total_consultant_headcount":
			return "Total Consultant Headcount";
		case "total_offshore_consultant_expense":
			return "Total Offshore Consultant Expense";
		case "total_sw_expense":
			return "Total SW Expense";
		case "balance_sheet_roll_off_depreciation":
			return "Balance Sheet Roll-off Depreciation";
		case "total_subcontractor_expense":
			return "Total Subcontractor Expense";
		case "percent_hw_depreciation_expense_to_total_it":
			return "Percent HW Depreciation of Total IT Expense";
		case "percent_comp_and_bene_to_total_it":
			return "% Comp & Benefits of Total IT Expense";
		case "percent_misc_expense_to_total_it":
			return "% Misc. of Total IT Expense";
		case "percent_consultant_hc_to_total_tech_hc":
			return "% Consultant HC of Total Tech HC";
		case "percent_developer_to_it_hc":
			return "% Dev HC of IT HC";
		case "annual_attrition_rate":
			return "Annual Attrition Rate";
		case "percent_sw_expense_to_total_it":
			return "% SW Expense of Total IT expense";
		case "percent_insurance_expense_to_total_it":
			return "% Insurance Expense of Total IT Expense";
		case "balance_sheet_roll_off_amortization":
			return "Balance Sheet Roll-off Amortization";
		case "total_telecom_expense":
			return "Total Telecom Expense";
		case "total_travel_entertainment_expense":
			return "Total T&E Expense";
		case "percent_total_sw_maintenance_expense_to_nonpersonnel":
			return "% Total SW Maintenance of Non-Personnel";
		case "annual_attrition_impact":
			return "Annual Attrition Impact";
		case "percent_legal_expense_to_total_it":
			return "% Legal Expense of Total IT Expense";
		case "avg_cost_of_infra_hc":
			return "Avg. Cost per Infra Employee";
		case "total_occupancy_expense":
			return "Total Facilities Expense";
		case "total_occupancy_onshore":
			return "Total Facilities Onshore Expense";
		case "total_occupancy_offshore":
			return "Total Facilities Offshore Expense";
		case "hw_depreciation_expense":
			return "HW Depreciation Expense";
		case "percent_bene_to_total_comp_and_bene":
			return "% Benefits of Total Comp & Benefits";
		case "percent_subcontractor_expense_to_total_it":
			return "% Subcontractor Expense of Total IT";
		case "percent_consultant_expense_to_nonpersonnel":
			return "% Consultant Expense of Non-Personnel";
		case "percent_legal_expense_to_nonpersonnel":
			return "% Legal Expense of Non-Personnel";
		case "percent_onshore_facilities_rent":
			return "% Onshore Facilities Rent to Total Rent";
		case "percent_onshore_to_total_it":
			return "% Onshore Facilities of Total IT";
		case "percent_offshore_to_total_it":
			return "% Offshore Facilities of Total IT";
		case "ave_cost_desktop":
			return "Avg. Cost per Desktop";
		case "percent_dedicated_calling_hw_expense_to_total_it":
			return "% Desktop Expense of Total IT";
		case "percent_consultant_expense_to_total_it":
			return "% Consultant Expense of Total IT";
		case "percent_consultant_hc_to_total_hc":
			return "% Consultant HC to IT HC";
		case "percent_sw_expense_to_nonpersonnel":
			return "% SW Expense of Non-Personnel";
		case "dedicated_calling_hw_expense":
			return "Total Desktop Expense";
		case "balance_sheet_roll_on_amortization":
			return "Balance Sheet Roll-on Amortization";
		case "ave_non_mgmt_comp_per_hc":
			return "Avg. Non-mgmt Compensation";
		case "percent_occupancy_expense_to_nonpersonnel":
			return "% Facilities Expense of Non-Personnel";
		case "total_insurance_expense":
			return "Total Insurance Expense";
		case "percent_offshore_consultant_headcount":
			return "% Offshore Consultant HC";
		case "total_sw_maintenance_expense":
			return "Total SW Maintenance Expense";
		case "percent_infra_to_dev_comp":
			return "Ratio Infra Comp to Dev Comp";
		case "infra_cost_to_total_it_spend":
			return "% Infr Employee Comp to Total IT";
		case "dev_cost_to_total_it_spend":
			return "% Dev Employee Comp to Total IT";
		case "cost_per_sq_ft_offshore":
			return "Offshore Cost per Square Foot";
		case "total_phones_conf_expense":
			return "Total Phone Conference Expense";
		case "vendor_count_hw":
			return "Vendor Count - Hardware";
		case "vendor_count_sw":
			return "Vendor Count - Software";
		case "vendor_count_other":
			return "Vendor Count - Other";
		case "vendor_count_total":
			return "Vendor Count Total";
		case "total_it_hc":
			return "Total IT Headcount";
		case "ave_cost_onshore_consultant":
			return "Avg. Daily Cost of Onshore Consultant";
		case "cost_per_sq_ft_onshore":
			return "Onshore Cost per Square Foot";
		case "percent_mgmt_to_total_comp":
			return "% Mgmt Comp of Total Comp (Excl. Benefits)";
		case "sq_ft_onshore":
			return "Square Footage Onshore";
		case "total_misc_expense":
			return "Total Miscellaneous Expense";
		case "percent_phones_conf_expense_to_total_it":
			return "% Phone Conference of Total IT Expense";
		case "average_tech_fte_comp":
			return "Avg. Tech FTE Compensation";
		case "percent_insurance_expense_to_nonpersonnel":
			return "% Insurance of Non-Personnel Expense";
		case "percent_hw_rental_expense_to_total_it":
			return "% HW Rental Expense of Total IT Expense";
		case "ave_telecom_hardware_cost_per_employee":
			return "Avg. Telecom HW Cost per Tech Employee";
		case "ratio_amortization_to_total_it_spend":
			return "% Roll Off Amortization to Total IT Spend";
		case "ratio_depreciation_to_total_it_spend":
			return "% Roll Off Depreciation to Total IT Spend";
		case "ratio_roll_on_amortization_to_total_it_spend":
			return "% Roll On Amortization to Total IT Spend";
		case "ratio_roll_on_depreciation_to_total_it_spend":
			return "% Roll On Depreciation to Total IT Spend";
		case "ratio_compensation_to_total_it_spend":
			return "% Total Comp to Total IT Spend";
		case "ratio_hc_paid_less_than_100k_to_total_employee_hc":
			return "% Tech Employees Paid <$100k";
		case "ratio_hc_paid_100k_to_200k_to_total_employee_hc":
			return "% Tech Employees Paid $100k to $200k";
		case "ratio_hc_paid_200k_to_300k_to_total_employee_hc":
			return "% Tech Employees Paid $200k to $300k";
		case "ratio_hc_paid_300k_to_400k_to_total_employee_hc":
			return "% Tech Employees Paid $300k to $400k";
		case "ratio_hc_paid_greater_than_400k_to_total_employee_hc":
			return "% Tech Employees Paid >$400k";
		case "average_cost_per_consultant":
			return "Avg. Cost per Consultant";
		default:
			return outputName;
	}
};

export const prettifyInputNames = (data) => {
	return data.map((input) => {
		const prettyInputName = prettifyInputName(input.name);
		return { ...input, prettyName: prettyInputName };
	});
};

export const prettifyInputName = (inputName) => {
	switch (inputName) {
		case "depreciation_projected":
			return "Depreciation Projected";
		case "amortization_projected":
			return "Amortization Projected";
		case "january":
			return "January";
		case "february":
			return "February";
		case "march":
			return "March";
		case "april":
			return "April";
		case "may":
			return "May";
		case "june":
			return "June";
		case "july":
			return "July";
		case "august":
			return "August";
		case "september":
			return "September";
		case "october":
			return "October";
		case "november":
			return "November";
		case "december":
			return "December";
		case "miscellaneous":
			return "Miscellaneous";
		case "capital_plan_software":
			return "Capital Plan Software";
		case "capital_plan_hardware":
			return "Capital Plan Hardware";
		case "change_control":
			return "Change Control";
		case "monthly_change_control_meeting_utilization":
			return "Monthly Change Control Meeting Utilization";
		case "change_requests_reverted":
			return "Change Requests Reverted";
		case "failed_changes_per_month":
			return "Failed Changes per Month";
		case "change_requests_per_month":
			return "Change Requests per Month";
		case "cloud":
			return "Cloud";
		case "web_application_firewall_utilization":
			return "Web App Firewall Utilization";
		case "data_upload_rate":
			return "Data Upload Rate";
		case "data_download_rate":
			return "Data Download Rate";
		case "private_cloud_to_public_cloud_ratio":
			return "Private to Public Cloud Ratio";
		case "server_utilization":
			return "Server Utilization";
		case "it_customer_support":
			return "IT Customer Support";
		case "issues_reported_by_phone":
			return "Issues Reported by Phone";
		case "resolution_time":
			return "Resolution Time";
		case "issues_reported_by_portal":
			return "Issues Reported by Portal";
		case "resolutions_per_first_call":
			return "Resolutions per First Call";
		case "issues_reported_by_email":
			return "Issues Reported by Email";
		case "response_time":
			return "Response Time";
		case "resolution_hit_rate_per_customer_service_rep":
			return "Resolution Rate per Service Rep";
		case "server":
			return "Server";
		case "database_release_technical_debt":
			return "Database Release Technical Debt";
		case "technical_debt_of_server_hardware":
			return "Technical Debt of Server Hardware";
		case "reboot_frequency":
			return "Reboot Frequency";
		case "cpu_utilization":
			return "CPU Utilization";
		case "storage_utilization":
			return "Storage Utilization";
		case "memory_utilization":
			return "Memory Utilization";
		case "patch_install_success_ratio":
			return "Patch Install Success Ratio";
		case "antivirus":
			return "AntiVirus";
		case "antivirus_utilization":
			return "AntiVirus Utilization";
		case "network_scan_frequency_anomolies":
			return "Network Scan Frequency Anomolies";
		case "servere_threat_detection":
			return "Server Threat Detection";
		case "server_scan_utilization":
			return "Server Scan Utilization";
		case "spam_hit_rate":
			return "Spam Hit Rate";
		case "network":
			return "Network";
		case "network_utilization":
			return "Network Utilization";
		case "network_capacity":
			return "Network Capacity";
		case "network_latency":
			return "Network Latency";
		case "firewall_rule_age_debt":
			return "Firewall Rule Age Debt";
		case "technical_debt_of_network_hardware":
			return "Technical Debt of Network Hardware";
		case "patch_install_success_ratio":
			return "Patch Install Success Ratio";
		case "yearly_technology_spend":
			return "Yearly Technology Spend";
		case "yearly_ml_spend":
			return "Yearly ML Spend";
		case "yearly_disaster_recovery_spend":
			return "Yearly Disaster Recovery Spend";
		case "yearly_cybersecurity_spend":
			return "Yearly Cyber Security Spend";
		case "yearly_cloud_spend":
			return "Yearly Cloud Spend";
		case "yearly_server_spend":
			return "Yearly Server Spend";
		case "yearly_ai_spend":
			return "Yearly AI Spend";
		case "disaster_recovery":
			return "Disaster Recovery";
		case "backup_frequency":
			return "Backup Frequency";
		case "recovery_time_objective":
			return "Recovery Time Objective";
		case "recovery_point_objective":
			return "Recovery Point Objective";
		case "personnel":
			return "Personnel";
		case "employee_headcount":
			return "Employee Head Count";
		case "total_tech_developer_personnel_costs":
			return "Total Tech Developer Personnel Costs";
		case "headcount_tech_developers":
			return "Tech Developers Head Count";
		case "headcount_total":
			return "Total Head Count";
		case "headcount_management":
			return "Management Head Count";
		case "total_tech_infrastructure_personnel_costs":
			return "Total Infrastructure Personnel Costs";
		case "headcount_tech_infrastructure":
			return "Tech Infrastructure Head Count";
		case "employee_benefits":
			return "Employee Benefits";
		case "dental":
			return "Dental";
		case "spend":
			return "Spend";
		case "percentage_infrastructure":
			return "Percentage Infrastructure";
		case "percentage_fixed":
			return "Percentage Fixed";
		case "other":
			return "Other";
		case "vision":
			return "Vision";
		case "medical":
			return "Medical";
		case "consultant_headcount":
			return "Consultant Head Count";
		case "offshore_consultants":
			return "Offshore Consultants";
		case "onshore_consultants":
			return "Onshore Consultants";
		case "total_cost":
			return "Total Cost";
		case "headcount_tech_total":
			return "Total Tech Head Count";
		case "total_tech_developer_personnel_costs":
			return "Total Tech Dev Personnel Costs";
		case "headcount_tech_developers":
			return "Tech Developers Head Count";
		case "employee_compensation":
			return "Employee Compensation";
		case "compensation_split":
			return "Compensation Split";
		case "part_time":
			return "Part-time";
		case "overtime":
			return "Overtime";
		case "bonus":
			return "Bonus";
		case "full_time":
			return "Full Time";
		case "compensation_ranges":
			return "Compensation Ranges";
		case "between_300k_and_400k":
			return "Between 300k and 400k";
		case "headcount":
			return "Head Count";
		case "average_compensation":
			return "Average Compensation";
		case "greater_than_or_equal_to_400k":
			return "Greater than or Equal to 400k";
		case "less_than_100k":
			return "Less than 100k";
		case "between_100k_and_200k":
			return "Between 100k and 200k";
		case "between_200k_and_300k":
			return "Between 200k and 300k";
		case "management_compensation":
			return "Management Compensation";
		case "annual_attrition_rate_involuntary":
			return "Annual Involuntary Attrition Rate";
		case "annual_attrition_rate_voluntary":
			return "Annual Voluntary Attrition Rate";
		case "employee_tech_percent_engineers":
			return "Percentage of Engineers in Tech";
		case "nonpersonnel":
			return "Non Personnel";
		case "software_expenses":
			return "Software Expenses";
		case "legal":
			return "Legal";
		case "uncategorized_expenses":
			return "Uncategorized Expenses";
		case "insurance":
			return "Insurance";
		case "professional_services":
			return "Professional Services";
		case "vendor_costs_hw":
			return "Vendor Costs - Hardware";
		case "vendor_costs_sw":
			return "Vendor Costs - Software";
		case "vendor_costs_other":
			return "Vendor Costs - Other";
		case "vendor_count_hw":
			return "Vendor Count - Hardware";
		case "vendor_count_sw":
			return "Vendor Count - Software";
		case "vendor_count_other":
			return "Vendor Count - Other";
		case "ratio_onshore_offshore":
			return "Ratio Onshore to Offshore";
		case "average_daily_onshore_consultant_cost":
			return "Average Daily Onshore Consultant Cost";
		case "subcontractor":
			return "Subcontractors";
		case "average_daily_offshore_consultant_cost":
			return "Average Daily Offshore Consultant Cost";
		case "software_maintenance":
			return "Software Maintenance";
		case "telecom":
			return "Telecom";
		case "calling_hardware":
			return "Calling Hardware";
		case "conference_calling":
			return "Conference Calling";
		case "travel_and_entertainment":
			return "Travel and Entertainment";
		case "facilities_occupancy":
			return "Occupancy of Facilities";
		case "building_services":
			return "Building Services";
		case "facilities_equipment_rental_depreciation":
			return "Equipment Rental Depreciation";
		case "average_square_foot_offshore":
			return "Average square foot Offshore";
		case "utilities":
			return "Utilities";
		case "offshore_rent":
			return "Offshore Rent";
		case "onshore_rent":
			return "Onshore Rent";
		case "average_square_foot_onshore":
			return "Average square foot Onshore";
		case "equipment_maintenance":
			return "Equipment Maintenance";
		case "desktops_and_laptops":
			return "Desktops/Laptops";
		case "direct_access_storage_devices":
			return "DASD";
		case "storage":
			return "Storage";
		case "mainframe":
			return "Mainframe";
		case "equipment_rental_and_leases":
			return "Equipment Rental and Leases";
		case "equipment_depreciation":
			return "Equipment Depreciation";
		case "software_amortization":
			return "Software Amortization";
	}
};

export const prettifyTargetStrikeInputName = (inputName) => {
	switch (inputName) {
		case "financial_info.personnel.employee_headcount.headcount_tech_total":
			return "Total Tech Employee Head Count";
		case "financial_info.personnel.employee_headcount.total_tech_developer_personnel_costs":
			return "Total Tech Developer Personnel Costs";
		case "financial_info.personnel.employee_headcount.headcount_tech_developers":
			return "Tech Developer Head Count";
		case "financial_info.personnel.employee_headcount.headcount_total":
			return "Employee Head Count Total";
		case "financial_info.personnel.employee_headcount.headcount_management":
			return "Management Head Count";
		case "financial_info.personnel.employee_headcount.total_tech_infrastructure_personnel_costs":
			return "Total Personnel Tech Infrastructure Costs";
		case "financial_info.personnel.employee_headcount.headcount_tech_infrastructure":
			return "Tech Infrastructure Head Count";
		case "financial_info.personnel.employee_benefits.dental.spend":
			return "Dental Benefits Spend";
		case "financial_info.personnel.employee_benefits.dental.percentage_fixed":
			return "Dental Benefit Fixed Variable Ratio";
		case "financial_info.personnel.employee_benefits.other.spend":
			return "Misc. Benefits Spend";
		case "financial_info.personnel.employee_benefits.other.percentage_fixed":
			return "Misc. Benefit Fixed Variable Ratio";
		case "financial_info.personnel.employee_benefits.vision.spend":
			return "Vision Benefits Spend";
		case "financial_info.personnel.employee_benefits.vision.percentage_fixed":
			return "Vision Benefit Fixed Variable Ratio";
		case "financial_info.personnel.employee_benefits.medical.spend":
			return "Medical Benefits Spend";
		case "financial_info.personnel.employee_benefits.medical.percentage_fixed":
			return "Medical Benefit Fixed Variable Ratio";
		case "financial_info.personnel.consultant_headcount.offshore_consultants.headcount_total":
			return "Offshore Consultant Total Head Count";
		case "financial_info.personnel.consultant_headcount.offshore_consultants.total_cost":
			return "Offshore Consultant Total Cost";
		case "financial_info.personnel.consultant_headcount.onshore_consultants.headcount_total":
			return "Onshore Consultant Total Head Count";
		case "financial_info.personnel.consultant_headcount.onshore_consultants.total_cost":
			return "Onshore Consultant Total Cost";
		case "financial_info.personnel.employee_compensation.compensation_split.part_time.spend":
			return "Part Time Compensation";
		case "financial_info.personnel.employee_compensation.compensation_split.part_time.percentage_infrastructure":
			return "Part Time Infrastructure Developement Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.part_time.percentage_fixed":
			return "Part Time Fixed Variable Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.overtime.spend":
			return "Overtime Compensation";
		case "financial_info.personnel.employee_compensation.compensation_split.overtime.percentage_infrastructure":
			return "Overtime Infrastructure Development Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.overtime.percentage_fixed":
			return "Overtime Fixed Variable Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.bonus.spend":
			return "Bonus Compensation";
		case "financial_info.personnel.employee_compensation.compensation_split.bonus.percentage_infrastructure":
			return "Bonus Infrastructure Development Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.bonus.percentage_fixed":
			return "Bonus Fixed Variable Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.full_time.spend":
			return "Full Time Compensation";
		case "financial_info.personnel.employee_compensation.compensation_split.full_time.percentage_infrastructure":
			return "Full Time Infrastructure Development Ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.full_time.percentage_fixed":
			return "Full Time Fixed Variable Ratio";
		case "financial_info.personnel.employee_compensation.compensation_ranges.less_than_100k.headcount":
			return "Employees under $100,000";
		case "financial_info.personnel.employee_compensation.compensation_ranges.less_than_100k.average_compensation":
			return "Employees under $100,000 Average Compensation";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_100k_and_200k.headcount":
			return "Employees between $100,000 and $200,000";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_100k_and_200k.average_compensation":
			return "Employees between $100,000 and $200,000 Average Compensation";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k.headcount":
			return "Employees between $200,000 and $300,000";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k.average_compensation":
			return "Employees between $200,000 and $300,000 Average Compensation";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_300k_and_400k.headcount":
			return "Employees between $300,000 and $400,000";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_300k_and_400k.average_compensation":
			return "Employees between $300,000 and $400,000 Average Compensation";
		case "financial_info.personnel.employee_compensation.compensation_ranges.greater_than_or_equal_to_400k.headcount":
			return "Employees over $400,000";
		case "financial_info.personnel.employee_compensation.compensation_ranges.greater_than_or_equal_to_400k.average_compensation":
			return "Employees over $400,000 Average Compensation";
		case "financial_info.personnel.employee_compensation.management_compensation":
			return "Management Compensation";
		case "financial_info.personnel.miscellaneous.annual_attrition_rate_involuntary":
			return "Annual Involuntary Attrition Rate";
		case "financial_info.personnel.miscellaneous.annual_attrition_rate_voluntary":
			return "Annual Voluntary Attrition Rate";
		case "financial_info.personnel.miscellaneous.employee_tech_percent_engineers":
			return "Percent of Tech Engineers";
		case "financial_info.nonpersonnel.software_expenses.spend":
			return "Software Expenses";
		case "financial_info.nonpersonnel.software_expenses.percentage_fixed":
			return "Software Expenses Fixed Variable Ratio";
		case "financial_info.nonpersonnel.legal.spend":
			return "Legal Spending";
		case "financial_info.nonpersonnel.legal.percentage_fixed":
			return "Legal Fixed Variable Ratio";
		case "financial_info.nonpersonnel.uncategorized_expenses.spend":
			return "Uncategorized Expenses";
		case "financial_info.nonpersonnel.uncategorized_expenses.percentage_fixed":
			return "Uncategorized Expenses Fixed Variable Ratio";
		case "financial_info.nonpersonnel.insurance.spend":
			return "Insurance Spending";
		case "financial_info.nonpersonnel.insurance.percentage_fixed":
			return "Insurance Fixed Variable Ratio";
		case "financial_info.nonpersonnel.professional_services.vendor_costs_hw":
			return "Vendor Costs - Hardware";
		case "financial_info.nonpersonnel.professional_services.vendor_costs_sw":
			return "Vendor Costs - Software";
		case "financial_info.nonpersonnel.professional_services.vendor_costs_other":
			return "Vendor Costs - Other";
		case "financial_info.nonpersonnel.professional_services.vendor_count_hw":
			return "Vendor Count - Hardware";
		case "financial_info.nonpersonnel.professional_services.vendor_count_sw":
			return "Vendor Count - Software";
		case "financial_info.nonpersonnel.professional_services.vendor_count_other":
			return "Vendor Count - Other";
		case "financial_info.nonpersonnel.professional_services.ratio_onshore_offshore":
			return "Ratio of Onshore to Offshore";
		case "financial_info.nonpersonnel.professional_services.percentage_infrastructure":
			return "Professional Services Infrastructure Development Ratio";
		case "financial_info.nonpersonnel.professional_services.average_daily_onshore_consultant_cost":
			return "Daily Average Onshore Consultant Cost";
		case "financial_info.nonpersonnel.professional_services.subcontractor":
			return "Subcontractor Services";
		case "financial_info.nonpersonnel.professional_services.average_daily_offshore_consultant_cost":
			return "Daily Average Offshore Consultant Cost";
		case "financial_info.nonpersonnel.professional_services.percentage_fixed":
			return "Professional Services Fixed Variable Ratio";
		case "financial_info.nonpersonnel.software_maintenance.spend":
			return "Software Maintenance";
		case "financial_info.nonpersonnel.software_maintenance.percentage_fixed":
			return "Software Maintenance Fixed Variable Ratio";
		case "financial_info.nonpersonnel.telecom.network":
			return "Telecom Network Spend";
		case "financial_info.nonpersonnel.telecom.calling_hardware":
			return "Calling Hardware Spend";
		case "financial_info.nonpersonnel.telecom.percentage_fixed":
			return "Telecom Fixed Variable Ratio";
		case "financial_info.nonpersonnel.telecom.conference_calling":
			return "Conference Calling Spend";
		case "financial_info.nonpersonnel.travel_and_entertainment.spend":
			return "Travel and Entertainment Spend";
		case "financial_info.nonpersonnel.travel_and_entertainment.percentage_fixed":
			return "Travel and Entertainment Fixed Variable Ratio";
		case "financial_info.nonpersonnel.facilities_occupancy.building_services":
			return "Building Services";
		case "financial_info.nonpersonnel.facilities_occupancy.facilities_equipment_rental_depreciation":
			return "Equipment Rental Depreciation";
		case "financial_info.nonpersonnel.facilities_occupancy.average_square_foot_offshore":
			return "Average Offshore Facility square footage";
		case "financial_info.nonpersonnel.facilities_occupancy.average_square_foot_onshore":
			return "Average Onshore Facility square footage";
		case "financial_info.nonpersonnel.facilities_occupancy.utilities":
			return "Facility Utilities";
		case "financial_info.nonpersonnel.facilities_occupancy.offshore_rent":
			return "Offshore Facility Rent";
		case "financial_info.nonpersonnel.facilities_occupancy.percentage_infrastructure":
			return "Facilities Occupancy Infrastructure Development Ratio";
		case "financial_info.nonpersonnel.facilities_occupancy.onshore_rent":
			return "Onshore Facility Rent";
		case "financial_info.nonpersonnel.facilities_occupancy.percentage_fixed":
			return "Facilities Occupancy Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.desktops_and_laptops.spend":
			return "E.M. Desktop and Laptop Spending";
		case "financial_info.nonpersonnel.equipment_maintenance.desktops_and_laptops.percentage_fixed":
			return "E.M. Desktop and Laptop Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.direct_access_storage_devices.spend":
			return "E.M. DASD Spending";
		case "financial_info.nonpersonnel.equipment_maintenance.direct_access_storage_devices.percentage_fixed":
			return "E.M. DASD Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.storage.spend":
			return "E.M. Storage Spending";
		case "financial_info.nonpersonnel.equipment_maintenance.storage.percentage_fixed":
			return "E.M. Storage Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.network.spend":
			return "E.M. Network Spending";
		case "financial_info.nonpersonnel.equipment_maintenance.network.percentage_fixed":
			return "E.M. Network Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.mainframe.spend":
			return "E.M. Mainframe Spending";
		case "financial_info.nonpersonnel.equipment_maintenance.mainframe.percentage_fixed":
			return "E.M. Mainframe Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.telecom.spend":
			return "E.M. Telecom Spending";
		case "financial_info.nonpersonnel.equipment_maintenance.telecom.percentage_fixed":
			return "E.M. Telecom Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.desktops_and_laptops.spend":
			return "E.R.L. Desktop and Laptop Spending";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.desktops_and_laptops.percentage_fixed":
			return "E.R.L. Desktop and Laptop Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.direct_access_storage_devices.spend":
			return "E.R.L. DASD Spending";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.direct_access_storage_devices.percentage_fixed":
			return "E.R.L. DASD Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.storage.spend":
			return "E.R.L. Storage Spending";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.storage.percentage_fixed":
			return "E.R.L. Storage Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.network.spend":
			return "E.R.L. Network Spend";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.network.percentage_fixed":
			return "E.R.L. Network Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.mainframe.spend":
			return "E.R.L. Mainframe Spending";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.mainframe.percentage_fixed":
			return "E.R.L. Mainframe Fixed Variable Ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.telecom.spend":
			return "E.R.L. Telecom Spend";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.telecom.percentage_fixed":
			return "E.R.L. Telecom Fixed Variable Ratio";
		case "financial_info.nonpersonnel.miscellaneous.equipment_depreciation":
			return "Misc. Equpiment Depreciation";
		case "financial_info.nonpersonnel.miscellaneous.software_amortization":
			return "Misc. Software Amortization";
		case "balance_sheet.depreciation_projected.october":
			return "Depreciation Projected for October";
		case "balance_sheet.depreciation_projected.july":
			return "Depreciation Projected for July";
		case "balance_sheet.depreciation_projected.june":
			return "Depreciation Projected for June";
		case "balance_sheet.depreciation_projected.september":
			return "Depreciation Projected for September";
		case "balance_sheet.depreciation_projected.november":
			return "Depreciation Projected for November";
		case "balance_sheet.depreciation_projected.february":
			return "Depreciation Projected for February";
		case "balance_sheet.depreciation_projected.may":
			return "Depreciation Projected for May";
		case "balance_sheet.depreciation_projected.august":
			return "Depreciation Projected for August";
		case "balance_sheet.depreciation_projected.january":
			return "Depreciation Projected for January";
		case "balance_sheet.depreciation_projected.december":
			return "Depreciation Projected for December";
		case "balance_sheet.depreciation_projected.march":
			return "Depreciation Projected for March";
		case "balance_sheet.depreciation_projected.april":
			return "Depreciation Projected for April";
		case "balance_sheet.amortization_projected.october":
			return "Amortization Projected for October";
		case "balance_sheet.amortization_projected.july":
			return "Amortization Projected for July";
		case "balance_sheet.amortization_projected.june":
			return "Amortization Projected for June";
		case "balance_sheet.amortization_projected.september":
			return "Amortization Projected for September";
		case "balance_sheet.amortization_projected.november":
			return "Amortization Projected for November";
		case "balance_sheet.amortization_projected.february":
			return "Amortization Projected for Febraury";
		case "balance_sheet.amortization_projected.may":
			return "Amortization Projected for May";
		case "balance_sheet.amortization_projected.august":
			return "Amortization Projected for August";
		case "balance_sheet.amortization_projected.january":
			return "Amortization Projected for January";
		case "balance_sheet.amortization_projected.december":
			return "Amortization Projected for December";
		case "balance_sheet.amortization_projected.march":
			return "Amortization Projected for March";
		case "balance_sheet.amortization_projected.april":
			return "Amortization Projected for April";
		case "balance_sheet.miscellaneous.capital_plan_software":
			return "Capital Plan Software Spend";
		case "balance_sheet.miscellaneous.capital_plan_hardware":
			return "Capital Plan Hardware Spend";
		case "operational_metrics.change_control.monthly_change_control_meeting_utilization":
			return "Headcount of Monthly Change Control Meetings";
		case "operational_metrics.change_control.change_requests_reverted":
			return "Change Requests Reverted";
		case "operational_metrics.change_control.failed_changes_per_month":
			return "Failed Changes per Month";
		case "operational_metrics.change_control.change_requests_per_month":
			return "Change Requests per Month";
		case "operational_metrics.cloud.web_application_firewall_utilization":
			return "Firewall Utilization";
		case "operational_metrics.cloud.data_upload_rate":
			return "Data Upload Rate";
		case "operational_metrics.cloud.data_download_rate":
			return "Data Download Rate";
		case "operational_metrics.cloud.private_cloud_to_public_cloud_ratio":
			return "Private to Public Cloud Ratio";
		case "operational_metrics.cloud.server_utilization":
			return "Server Utilization";
		case "operational_metrics.it_customer_support.issues_reported_by_phone":
			return "Issues Reported by Phone";
		case "operational_metrics.it_customer_support.resolution_time":
			return "Customer Support Resolution Time";
		case "operational_metrics.it_customer_support.issues_reported_by_portal":
			return "Issues Reported by Portal";
		case "operational_metrics.it_customer_support.resolutions_per_first_call":
			return "Resolutions per First Call";
		case "operational_metrics.it_customer_support.issues_reported_by_email":
			return "Issues Reported by Email";
		case "operational_metrics.it_customer_support.response_time":
			return "Customer Support Response Time";
		case "operational_metrics.it_customer_support.resolution_hit_rate_per_customer_service_rep":
			return "Resolution Ratio per Representative";
		case "operational_metrics.server.database_release_technical_debt":
			return "Database Release Technical Debt";
		case "operational_metrics.server.technical_debt_of_server_hardware":
			return "Technical Debt of Server Hardware";
		case "operational_metrics.server.reboot_frequency":
			return "reboot frequency";
		case "operational_metrics.server.cpu_utilization":
			return "CPU Utilization";
		case "operational_metrics.server.storage_utilization":
			return "Storage Utilization";
		case "operational_metrics.server.memory_utilization":
			return "Memory Utilization";
		case "operational_metrics.server.patch_install_success_ratio":
			return "Server Patch Install Success Ratio";
		case "operational_metrics.antivirus.antivirus_utilization":
			return "Antivirus Utilization";
		case "operational_metrics.antivirus.network_scan_frequency_anomolies":
			return "Network Scan Frequency for Anomolies";
		case "operational_metrics.antivirus.servere_threat_detection":
			return "Severe Threat Detection";
		case "operational_metrics.antivirus.server_scan_utilization":
			return "Server Scan Utilization";
		case "operational_metrics.antivirus.spam_hit_rate":
			return "Antivirus Spam Hit Rate";
		case "operational_metrics.network.network_utilization":
			return "Network Utilization";
		case "operational_metrics.network.network_capacity":
			return "Network Capacity";
		case "operational_metrics.network.network_latency":
			return "Network Latency";
		case "operational_metrics.network.firewall_rule_age_debt":
			return "Firewall Rule Age Debt";
		case "operational_metrics.network.technical_debt_of_network_hardware":
			return "Technical Debt of Network Hardware";
		case "operational_metrics.network.patch_install_success_ratio":
			return "Network Patch Install Success Ratio";
		case "operational_metrics.yearly_technology_spend.yearly_ml_spend":
			return "Yearly ML Spend";
		case "operational_metrics.yearly_technology_spend.yearly_disaster_recovery_spend":
			return "Yearly Disaster Recovery Spend";
		case "operational_metrics.yearly_technology_spend.yearly_cybersecurity_spend":
			return "Yearly Cyber Security Spend";
		case "operational_metrics.yearly_technology_spend.yearly_cloud_spend":
			return "Yearly Cloud Spend";
		case "operational_metrics.yearly_technology_spend.yearly_server_spend":
			return "Yearly Server Spend";
		case "operational_metrics.yearly_technology_spend.yearly_ai_spend":
			return "Yearly AI Spend";
		case "operational_metrics.disaster_recovery.backup_frequency":
			return "Backup Frequency";
		case "operational_metrics.disaster_recovery.recovery_time_objective":
			return "Recovery Time Objective";
		case "operational_metrics.disaster_recovery.recovery_point_objective":
			return "Recovery Point Objective";
	}
};

export const getTypeForInputName = (inputName) => {
	switch (inputName) {
		case "financial_info.personnel.employee_headcount.headcount_tech_total":
			return "uint";
		case "financial_info.personnel.employee_headcount.total_tech_developer_personnel_costs":
			return "dollars";
		case "financial_info.personnel.employee_headcount.headcount_tech_developers":
			return "uint";
		case "financial_info.personnel.employee_headcount.headcount_total":
			return "uint";
		case "financial_info.personnel.employee_headcount.headcount_management":
			return "uint";
		case "financial_info.personnel.employee_headcount.total_tech_infrastructure_personnel_costs":
			return "dollars";
		case "financial_info.personnel.employee_headcount.headcount_tech_infrastructure":
			return "unit";
		case "financial_info.personnel.employee_benefits.dental.spend":
			return "dollars";
		case "financial_info.personnel.employee_benefits.dental.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_benefits.other.spend":
			return "dollars";
		case "financial_info.personnel.employee_benefits.other.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_benefits.vision.spend":
			return "dollars";
		case "financial_info.personnel.employee_benefits.vision.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_benefits.medical.spend":
			return "dollars";
		case "financial_info.personnel.employee_benefits.medical.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.consultant_headcount.offshore_consultants.headcount_total":
			return "uint";
		case "financial_info.personnel.consultant_headcount.offshore_consultants.total_cost":
			return "dollars";
		case "financial_info.personnel.consultant_headcount.onshore_consultants.headcount_total":
			return "uint";
		case "financial_info.personnel.consultant_headcount.onshore_consultants.total_cost":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_split.part_time.spend":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_split.part_time.percentage_infrastructure":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.part_time.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.overtime.spend":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_split.overtime.percentage_infrastructure":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.overtime.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.bonus.spend":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_split.bonus.percentage_infrastructure":
			return "Bratio";
		case "financial_info.personnel.employee_compensation.compensation_split.bonus.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.full_time.spend":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_split.full_time.percentage_infrastructure":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_split.full_time.percentage_fixed":
			return "ratio";
		case "financial_info.personnel.employee_compensation.compensation_ranges.less_than_100k.headcount":
			return "uint";
		case "financial_info.personnel.employee_compensation.compensation_ranges.less_than_100k.average_compensation":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_100k_and_200k.headcount":
			return "uint";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_100k_and_200k.average_compensation":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k.headcount":
			return "uint";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_200k_and_300k.average_compensation":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_300k_and_400k.headcount":
			return "uint";
		case "financial_info.personnel.employee_compensation.compensation_ranges.between_300k_and_400k.average_compensation":
			return "dollars";
		case "financial_info.personnel.employee_compensation.compensation_ranges.greater_than_or_equal_to_400k.headcount":
			return "uint";
		case "financial_info.personnel.employee_compensation.compensation_ranges.greater_than_or_equal_to_400k.average_compensation":
			return "dollars";
		case "financial_info.personnel.employee_compensation.management_compensation":
			return "dollars";
		case "financial_info.personnel.miscellaneous.annual_attrition_rate_involuntary":
			return "ratio";
		case "financial_info.personnel.miscellaneous.annual_attrition_rate_voluntary":
			return "ratio";
		case "financial_info.personnel.miscellaneous.employee_tech_percent_engineers":
			return "ratio";
		case "financial_info.nonpersonnel.software_expenses.spend":
			return "dollars";
		case "financial_info.nonpersonnel.software_expenses.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.legal.spend":
			return "dollars";
		case "financial_info.nonpersonnel.legal.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.uncategorized_expenses.spend":
			return "dollars";
		case "financial_info.nonpersonnel.uncategorized_expenses.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.insurance.spend":
			return "dollars";
		case "financial_info.nonpersonnel.insurance.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.professional_services.vendor_costs_hw":
			return "dollars";
		case "financial_info.nonpersonnel.professional_services.vendor_costs_sw":
			return "dollars";
		case "financial_info.nonpersonnel.professional_services.vendor_costs_other":
			return "dollars";
		case "financial_info.nonpersonnel.professional_services.vendor_count_hw":
			return "uint";
		case "financial_info.nonpersonnel.professional_services.vendor_count_sw":
			return "uint";
		case "financial_info.nonpersonnel.professional_services.vendor_count_other":
			return "uint";
		case "financial_info.nonpersonnel.professional_services.ratio_onshore_offshore":
			return "ratio";
		case "financial_info.nonpersonnel.professional_services.percentage_infrastructure":
			return "ratio";
		case "financial_info.nonpersonnel.professional_services.average_daily_onshore_consultant_cost":
			return "dollars";
		//case "financial_info.nonpersonnel.professional_services.subcontractor":
		//	return "Subcontractor Services";
		case "financial_info.nonpersonnel.professional_services.average_daily_offshore_consultant_cost":
			return "dollars";
		case "financial_info.nonpersonnel.professional_services.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.software_maintenance.spend":
			return "dollars";
		case "financial_info.nonpersonnel.software_maintenance.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.telecom.network":
			return "dollars";
		case "financial_info.nonpersonnel.telecom.calling_hardware":
			return "dollars";
		case "financial_info.nonpersonnel.telecom.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.telecom.conference_calling":
			return "dollars";
		case "financial_info.nonpersonnel.travel_and_entertainment.spend":
			return "dollars";
		case "financial_info.nonpersonnel.travel_and_entertainment.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.facilities_occupancy.building_services":
			return "dollars";
		case "financial_info.nonpersonnel.facilities_occupancy.facilities_equipment_rental_depreciation":
			return "dollars";
		case "financial_info.nonpersonnel.facilities_occupancy.average_square_foot_offshore":
			return "uint";
		case "financial_info.nonpersonnel.facilities_occupancy.average_square_foot_onshore":
			return "uint";
		case "financial_info.nonpersonnel.facilities_occupancy.utilities":
			return "dollars";
		case "financial_info.nonpersonnel.facilities_occupancy.offshore_rent":
			return "dollars";
		case "financial_info.nonpersonnel.facilities_occupancy.percentage_infrastructure":
			return "ratio";
		case "financial_info.nonpersonnel.facilities_occupancy.onshore_rent":
			return "dollars";
		case "financial_info.nonpersonnel.facilities_occupancy.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.desktops_and_laptops.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_maintenance.desktops_and_laptops.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.direct_access_storage_devices.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_maintenance.direct_access_storage_devices.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.storage.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_maintenance.storage.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.network.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_maintenance.network.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.mainframe.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_maintenance.mainframe.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_maintenance.telecom.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_maintenance.telecom.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.desktops_and_laptops.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.desktops_and_laptops.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.direct_access_storage_devices.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.direct_access_storage_devices.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.storage.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.storage.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.network.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.network.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.mainframe.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.mainframe.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.telecom.spend":
			return "dollars";
		case "financial_info.nonpersonnel.equipment_rental_and_leases.telecom.percentage_fixed":
			return "ratio";
		case "financial_info.nonpersonnel.miscellaneous.equipment_depreciation":
			return "dollars";
		case "financial_info.nonpersonnel.miscellaneous.software_amortization":
			return "dollars";
		case "balance_sheet.depreciation_projected.october":
			return "dollars";
		case "balance_sheet.depreciation_projected.july":
			return "dollars";
		case "balance_sheet.depreciation_projected.june":
			return "dollars";
		case "balance_sheet.depreciation_projected.september":
			return "dollars";
		case "balance_sheet.depreciation_projected.november":
			return "dollars";
		case "balance_sheet.depreciation_projected.february":
			return "dollars";
		case "balance_sheet.depreciation_projected.may":
			return "dollars";
		case "balance_sheet.depreciation_projected.august":
			return "dollars";
		case "balance_sheet.depreciation_projected.january":
			return "dollars";
		case "balance_sheet.depreciation_projected.december":
			return "dollars";
		case "balance_sheet.depreciation_projected.march":
			return "dollars";
		case "balance_sheet.depreciation_projected.april":
			return "dollars";
		case "balance_sheet.amortization_projected.october":
			return "dollars";
		case "balance_sheet.amortization_projected.july":
			return "dollars";
		case "balance_sheet.amortization_projected.june":
			return "dollars";
		case "balance_sheet.amortization_projected.september":
			return "dollars";
		case "balance_sheet.amortization_projected.november":
			return "dollars";
		case "balance_sheet.amortization_projected.february":
			return "dollars";
		case "balance_sheet.amortization_projected.may":
			return "dollars";
		case "balance_sheet.amortization_projected.august":
			return "dollars";
		case "balance_sheet.amortization_projected.january":
			return "dollars";
		case "balance_sheet.amortization_projected.december":
			return "dollars";
		case "balance_sheet.amortization_projected.march":
			return "dollars";
		case "balance_sheet.amortization_projected.april":
			return "dollars";
		case "balance_sheet.miscellaneous.capital_plan_software":
			return "dollars";
		case "balance_sheet.miscellaneous.capital_plan_hardware":
			return "dollars";
		case "operational_metrics.change_control.monthly_change_control_meeting_utilization":
			return "uint";
		case "operational_metrics.change_control.change_requests_reverted":
			return "uint";
		case "operational_metrics.change_control.failed_changes_per_month":
			return "uint";
		case "operational_metrics.change_control.change_requests_per_month":
			return "uint";
		case "operational_metrics.cloud.web_application_firewall_utilization":
			return "ratio";
		// case "operational_metrics.cloud.data_upload_rate":
		// 	return "";
		// case "operational_metrics.cloud.data_download_rate":
		// 	return "";
		case "operational_metrics.cloud.private_cloud_to_public_cloud_ratio":
			return "ratio";
		case "operational_metrics.cloud.server_utilization":
			return "ratio";
		case "operational_metrics.it_customer_support.issues_reported_by_phone":
			return "ratio";
		// case "operational_metrics.it_customer_support.resolution_time":
		// 	return "";
		case "operational_metrics.it_customer_support.issues_reported_by_portal":
			return "ratio";
		case "operational_metrics.it_customer_support.resolutions_per_first_call":
			return "ratio";
		case "operational_metrics.it_customer_support.issues_reported_by_email":
			return "ratio";
		// case "operational_metrics.it_customer_support.response_time":
		// 	return "";
		case "operational_metrics.it_customer_support.resolution_hit_rate_per_customer_service_rep":
			return "ratio";
		// case "operational_metrics.server.database_release_technical_debt":
		// 	return "";
		// case "operational_metrics.server.technical_debt_of_server_hardware":
		// 	return "";
		case "operational_metrics.server.reboot_frequency":
			return "ratio";
		case "operational_metrics.server.cpu_utilization":
			return "ratio";
		case "operational_metrics.server.storage_utilization":
			return "ratio";
		case "operational_metrics.server.memory_utilization":
			return "ratio";
		case "operational_metrics.server.patch_install_success_ratio":
			return "ratio";
		case "operational_metrics.antivirus.antivirus_utilization":
			return "ratio";
		// case "operational_metrics.antivirus.network_scan_frequency_anomolies":
		// 	return "";
		case "operational_metrics.antivirus.servere_threat_detection":
			return "ratio";
		case "operational_metrics.antivirus.server_scan_utilization":
			return "ratio";
		case "operational_metrics.antivirus.spam_hit_rate":
			return "ratio";
		case "operational_metrics.network.network_utilization":
			return "ratio";
		// case "operational_metrics.network.network_capacity":
		// 	return "";
		// case "operational_metrics.network.network_latency":
		// 	return "";
		// case "operational_metrics.network.firewall_rule_age_debt":
		// 	return "";
		// case "operational_metrics.network.technical_debt_of_network_hardware":
		// 	return "";
		case "operational_metrics.network.patch_install_success_ratio":
			return "ratio";
		case "operational_metrics.yearly_technology_spend.yearly_ml_spend":
			return "dollars";
		case "operational_metrics.yearly_technology_spend.yearly_disaster_recovery_spend":
			return "dollars";
		case "operational_metrics.yearly_technology_spend.yearly_cybersecurity_spend":
			return "dollars";
		case "operational_metrics.yearly_technology_spend.yearly_cloud_spend":
			return "dollars";
		case "operational_metrics.yearly_technology_spend.yearly_server_spend":
			return "dollars";
		case "operational_metrics.yearly_technology_spend.yearly_ai_spend":
			return "dollars";
		case "operational_metrics.disaster_recovery.backup_frequency":
			return "ratio";
		// case "operational_metrics.disaster_recovery.recovery_time_objective":
		// 	return "";
		// case "operational_metrics.disaster_recovery.recovery_point_objective":
		// 	return "";
	}
};
/* --------------------------------EXAMPLE-----------------------------
	--------JSON input---------
	clothes:{
		shirts:{
			vNecks:{
				black:"", <-- empty string represents textfields where we are getting input from user
				blue:"",
				orange:""
			},
		},
		pants:{
			nike:{
				jeans:"",
				sweats:"",
				joggers:""
			},
		}
	}
	----------Tree output--------
	
	{
		path: "clothes",
		fullPath: "clothes",
		errors:-1,
		children: [
			{
				path:"shirts",
				fullPath:"clothes.shirts",
				errors:-1,
				children:[
					{
						path:"vNecks",
						fullPath:"clothes.shirts.vNecks",
						errors:-1,
						children:[
							{
								path:"black",
								fullPath:"clothers.shirts.vNecks.black", <--- Full path is a unique string . This is used to create refs for text fields when trying to retrieve their value
																			  or to scroll to headers when they are clicked in the form navigation
								errors:-1
											<---- No children, aka a leaf node, meaning this is a textfield where we should be getting input from user
							},
							{
								path:"blue",
								fullPath:"clothers.shirts.vNecks.black",
								errors:-1
							},
							{
								path:"orange",
								fullPath:"clothers.shirts.vNecks.black",
								errors:-1
							}
						]
					}
				]
			},
			{
				path:"pants",
				fullPath:"clothes.pants",
				errors:-1,
				children:[
					{
						path:"nike",
						fullPath:"clothers.pants.nike"
						errors:-1,
						children:[
							{
								path:"jeans",
								fullPath:"clothers.pants.nike.jeans",
								errors:-1
							},
							{
								path:"sweats",
								fullPath:"clothers.pants.nike.sweats",
								errors:-1
							},
							{
								path:"joggers",
								fullPath:"clothers.pants.nike.joggers",
								errors:-1
							}
						]
					}
				]
			}
		]
	} */

export const numberWithComma = (num) => {
	var parts = num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
};

export const getEndInputAdornment = (name, theme) => {
	let type = getTypeForInputName(name);
	return type === "ratio" ? (
		<InputAdornment position={"end"} variant="outlined">
			<div style={{ color: theme.contrastText }}>%</div>
		</InputAdornment>
	) : (
		<InputAdornment>
			<div style={{ width: ".8rem" }} />
		</InputAdornment>
	);
};

export const DollarFormatCustom = (props) => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			prefix="$ "
		/>
	);
};

export const IntFormatCustom = (props) => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
		/>
	);
};

export const RatioFormatCustom = (props) => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			suffix=" %"
		/>
	);
};

export const strip = (val, type) => {
	let stripped = val.replace(/\s+|\$+|\%+|,|/g, "");
	if (type === "ratio") {
		let trueVal = parseFloat(stripped) / 100;
		return trueVal.toString();
	} else return stripped;
};
